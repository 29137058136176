import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    Checkbox,
    Dialog,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { Add as AddIcon, ArrowBack, Save as SaveIcon } from '@mui/icons-material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import SortableList, {
    ListItemType,
    SortableListOnChangeProps,
} from '~/components/SortableList/SortableList';
import {
    AdvocateTaskChecklistItemInput as ChecklistItem,
    AdvocateTaskTemplateForAdvocateTaskTemplatePageDocument,
    AdvocateTaskTemplateWhenType,
    useAdvocateTaskTemplateForAdvocateTaskTemplatePageLazyQuery,
    useCreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
    useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation,
    useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery,
    useFetchAmsAppsForAdvocateTaskTemplatePageQuery,
    useFetchTagsForAdvocateTaskTemplatePageQuery,
    useFetchTimelinesForAdvocateTaskTemplatePageQuery,
    useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
    TimeUnit,
    useFetchSlasV2ForAdvocateTaskTemplatePageQuery,
    SlaTypeEnum,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { ClientSelectionType, OffsetType } from '~/enums/enums';
import { ChecklistItemModal } from '../components';
import { toFormValuesFromAdvocateTaskTemplate } from './helpers/toFormValues';
import { toRequestInput } from './helpers/toRequestInput';
import { useStyles } from './styles';
import { ADVOCATE_TASK_TEMPLATE_SCHEMA, CREATE_NEW_TYPE } from './yupSchema';
import {
    AdvocateTaskTemplateChecklistItem,
    AdvocateTaskTemplateFormInput,
} from './types/AdvocateTaskTemplateFormInput';

const LOADING_AMS_TAGS_TEXT = 'Loading tags...';
const LOADING_AMS_APPS_TEXT = 'Loading apps...';
const ADVOCATE_TASK_TEMPLATE_LIST_PATHNAME = '/app-config/advocate-task-templates';
const Priorities = Array.from({ length: 5 }, (_, i) => i + 1);

const AdvocateTaskTemplate: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [shouldClosedAfterSave, setShouldClosedAfterSave] = useState(false);
    const [checklist, setChecklist] = useState<AdvocateTaskTemplateChecklistItem[]>([]);
    const [hasFormError, setHasFormError] = useState<boolean>(false);
    const [selectedListItem, setSelectedListItem] = useState<ChecklistItem | null>(null);
    const [taskTypeNameUsed, setTaskTypeNameUsed] = useState<boolean>(false);
    const { id: advocateTaskTemplateId } = useParams<{ id: string }>();
    const isEditMode = advocateTaskTemplateId !== 'new';
    const { classes } = useStyles();
    const history = useNavigate();

    const [getAdvocateTaskTemplate, { data: templateData, error: taskTemplateError }] =
        useAdvocateTaskTemplateForAdvocateTaskTemplatePageLazyQuery();

    const { data: advocateTaskTypesData, loading: advocateTaskTypesLoading } =
        useFetchAdvocateTaskTypesForAdvocateTaskTemplatePageQuery();

    const [createTaskType] = useCreateAdvocateTaskTypeForNewAdvocateTaskPageMutation({
        awaitRefetchQueries: true,
        refetchQueries: ['FetchAdvocateTaskTypesForAdvocateTaskTemplatePage'],
    });

    const [createTaskTemplate, { loading: createTaskTemplateLoading }] =
        useCreateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation({
            onCompleted: data => {
                history(
                    `${ADVOCATE_TASK_TEMPLATE_LIST_PATHNAME}/${data.createAdvocateTaskTemplateEncoded?.resourceCreated?.id}`,
                );
            },
            awaitRefetchQueries: true,
            refetchQueries: ['FetchAdvocateTaskTypesForAdvocateTaskTemplatePage'],
        });

    const [updateTaskTemplate, { loading: updateTaskTemplateLoading }] =
        useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: AdvocateTaskTemplateForAdvocateTaskTemplatePageDocument,
                    variables: { input: { id: isEditMode ? advocateTaskTemplateId : '' } },
                },
            ],
        });

    const { data: timelines } = useFetchTimelinesForAdvocateTaskTemplatePageQuery();

    const { data: tagsData } = useFetchTagsForAdvocateTaskTemplatePageQuery();

    const { data: amsAppsData } = useFetchAmsAppsForAdvocateTaskTemplatePageQuery();

    const { data: slasData } = useFetchSlasV2ForAdvocateTaskTemplatePageQuery({
        variables: { input: { filter: { fields: { type: SlaTypeEnum.HealthAdvocateTask } } } },
    });

    const useFormMethods = useForm<AdvocateTaskTemplateFormInput>({
        resolver: yupResolver(ADVOCATE_TASK_TEMPLATE_SCHEMA as any),
    });

    const {
        control,
        watch,
        register,
        handleSubmit: handleFormSubmit,
        formState: { errors },
        reset,
    } = useFormMethods;

    const typeId = watch('typeId');
    const whenType = watch('whenType');
    const clientSelectionType = watch('whenTimeline.clientSelectionType');

    useEffect(() => {
        if (advocateTaskTemplateId !== 'new') {
            getAdvocateTaskTemplate({ variables: { input: { id: advocateTaskTemplateId } } });
        }
    }, [advocateTaskTemplateId, getAdvocateTaskTemplate]);

    useEffect(() => {
        const formValues = toFormValuesFromAdvocateTaskTemplate(templateData);
        reset({ ...formValues });
        setChecklist(formValues.checklist);
    }, [isEditMode, templateData, reset]);

    useEffect(() => {
        if (taskTemplateError) {
            history(`${ADVOCATE_TASK_TEMPLATE_LIST_PATHNAME}/new`);
        }
    }, [history, taskTemplateError]);

    const deleteItem = (fn: () => void) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this step?`,
            callback: () => {
                fn();
            },
        });
    };

    const handleChecklistItemChange = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        const checklistClone = [...checklist];
        const element = checklistClone[startIndex];
        checklistClone.splice(startIndex, 1);
        checklistClone.splice(endIndex, 0, element);
        setChecklist(checklistClone);
    };

    const handleDeleteChecklistItemClick = (index: number) => {
        deleteItem(() => setChecklist(checklist.filter((_, groupIndex) => groupIndex !== index)));
    };

    const handleAddChecklistItemClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseChecklistModal = () => {
        setIsModalOpen(false);
        setSelectedListItem(null);
    };

    const handleModalSubmit = (modalChecklistItem: ChecklistItem) => {
        const checklistItem = {
            ...modalChecklistItem,
            description: modalChecklistItem.description ?? null,
            label: { en: modalChecklistItem.label },
        };
        const indexToUpdate = checklist.findIndex(item => item.id === checklistItem.id);
        if (indexToUpdate === -1) {
            setChecklist(list => [...list, checklistItem]);
        } else {
            const updatedChecklist = [...checklist];
            updatedChecklist[indexToUpdate] = checklistItem;
            setChecklist(updatedChecklist);
        }
        setIsModalOpen(false);
        setSelectedListItem(null);
    };

    const handleChecklistItemClick = (checklistItem: ListItemType) => {
        const selectedCheklistItem = checklistItem as AdvocateTaskTemplateChecklistItem;
        setSelectedListItem({
            ...selectedCheklistItem,
            description: selectedCheklistItem.description ?? null,
            label: selectedCheklistItem.label.en,
        });
        setIsModalOpen(true);
    };

    const createNewTaskType = async (newType: string) => {
        const { data } = await createTaskType({
            variables: { input: { label: newType } },
        });
        return data?.createAdvocateTaskType?.resourceCreated?.id;
    };

    const handleSubmit: SubmitHandler<AdvocateTaskTemplateFormInput> = async form => {
        if (checklist.length === 0) {
            setHasFormError(true);
            return;
        }
        setHasFormError(false);
        setTaskTypeNameUsed(false);
        const taskTypesDataResults = advocateTaskTypesData?.advocateTaskTypesV2?.results || [];
        const [isNewTaskTypeUsed] = taskTypesDataResults.filter(
            type => type.label.toLowerCase() === form?.newType?.toLowerCase(),
        );
        if (isNewTaskTypeUsed) {
            setTaskTypeNameUsed(true);
            return;
        }
        const typeId =
            form.typeId === CREATE_NEW_TYPE ? await createNewTaskType(form.newType) : form.typeId;
        if (!typeId) return;
        const taskTemplateInput = toRequestInput(form, checklist);
        if (isEditMode) {
            await updateTaskTemplate({
                variables: {
                    input: {
                        id: advocateTaskTemplateId ?? '',
                        data: { ...taskTemplateInput, typeId },
                    },
                },
            });
        } else {
            await createTaskTemplate({
                variables: { input: { ...taskTemplateInput, typeId } },
            });
        }
        if (shouldClosedAfterSave) {
            setShouldClosedAfterSave(false);
            history(ADVOCATE_TASK_TEMPLATE_LIST_PATHNAME);
        }
    };

    if (advocateTaskTypesLoading || createTaskTemplateLoading || updateTaskTemplateLoading) {
        return <Loading />;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button
                    component={Link}
                    to={ADVOCATE_TASK_TEMPLATE_LIST_PATHNAME}
                    startIcon={<ArrowBack />}
                >
                    Back to HA Task Templates
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography variant="h6" paragraph>
                        {isEditMode ? 'Edit' : 'Create'} HA Task Template
                    </Typography>
                    <Divider style={{ marginBottom: 20 }} />
                    <form onSubmit={handleFormSubmit(handleSubmit)}>
                        <Grid item xs={8}>
                            <TextField
                                variant="outlined"
                                label="Label *"
                                fullWidth
                                margin="dense"
                                {...register('label')}
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(errors.label)}
                                helperText={errors.label?.message}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                variant="outlined"
                                label="Description *"
                                fullWidth
                                margin="dense"
                                multiline
                                minRows={4}
                                maxRows={10}
                                {...register('description')}
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(errors.description)}
                                helperText={errors.description?.message}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <ReactHookFormSelect
                                control={control}
                                name="slaId"
                                variant="outlined"
                                label="Sla"
                                margin="dense"
                                fullWidth
                                defaultValue=""
                            >
                                <MenuItem value="">N/A</MenuItem>
                                {slasData?.slasV2.results.map(sla => (
                                    <MenuItem key={sla.id} value={sla.id}>
                                        {sla.name}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {errors?.slaId && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.slaId?.message}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={8} className={classes.addTodoBtnWrap}>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={handleAddChecklistItemClick}
                                startIcon={<AddIcon />}
                            >
                                Add Step
                            </Button>
                        </Grid>
                        {checklist.length === 0 && hasFormError && (
                            <FormHelperText error className={classes.checklistError}>
                                Must select at least one step
                            </FormHelperText>
                        )}
                        <Grid item xs={8}>
                            {checklist && checklist.length > 0 && (
                                <OutlinedSection className={classes.sortableList} title="Steps">
                                    <Grid item xs={12}>
                                        <SortableList
                                            list={checklist}
                                            droppableId="AdvocateSteps"
                                            onChange={handleChecklistItemChange}
                                            onClick={handleChecklistItemClick}
                                            onDelete={handleDeleteChecklistItemClick}
                                        />
                                    </Grid>
                                </OutlinedSection>
                            )}
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                name="shouldNotify"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        label="Should Send Slack Notification"
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={!!field.value}
                                                color="primary"
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <ReactHookFormSelect
                                control={control}
                                name="priority"
                                variant="outlined"
                                label="Priority"
                                margin="dense"
                                fullWidth
                                defaultValue=""
                            >
                                {Priorities.map(i => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item xs={8}>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="typeId"
                                variant="outlined"
                                label="Type"
                                fullWidth
                                margin="dense"
                            >
                                <MenuItem
                                    key={CREATE_NEW_TYPE}
                                    value={CREATE_NEW_TYPE}
                                    className={classes.actionType}
                                >
                                    {CREATE_NEW_TYPE}
                                </MenuItem>
                                {advocateTaskTypesData?.advocateTaskTypesV2?.results.map(
                                    ({ id, label }) => (
                                        <MenuItem key={id} value={id}>
                                            {label}
                                        </MenuItem>
                                    ),
                                )}
                            </ReactHookFormSelect>
                            {errors?.typeId && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.typeId?.message}
                                </FormHelperText>
                            )}
                            {typeId === CREATE_NEW_TYPE && (
                                <>
                                    <TextField
                                        variant="outlined"
                                        label="New Task Type Name"
                                        fullWidth
                                        margin="dense"
                                        {...register('newType')}
                                        error={Boolean(errors.newType)}
                                        helperText={errors.newType?.message}
                                    />
                                    {taskTypeNameUsed && (
                                        <Grid className={classes.validationError}>
                                            Task Type Name already used. Please change.
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item xs={8}>
                            <OutlinedSection className={classes.sortableList} title="When">
                                <Grid item xs={12}>
                                    <ReactHookFormSelect
                                        control={control}
                                        defaultValue=""
                                        name="whenType"
                                        variant="outlined"
                                        label="Type"
                                        fullWidth
                                        margin="dense"
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        {Object.values(AdvocateTaskTemplateWhenType).map(type => (
                                            <MenuItem
                                                style={{ textTransform: 'capitalize' }}
                                                key={type}
                                                value={type}
                                            >
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>
                                    {errors?.whenType && (
                                        <FormHelperText error className={classes.helperMessage}>
                                            {errors?.whenType?.message}
                                        </FormHelperText>
                                    )}
                                    {whenType === AdvocateTaskTemplateWhenType.Intervention && (
                                        <Grid item xs={12}>
                                            <OutlinedSection title="Duration">
                                                <div className={classes.offsetWrap}>
                                                    <TextField
                                                        variant="outlined"
                                                        type="number"
                                                        margin="dense"
                                                        label="Value *"
                                                        {...register('durationValue')}
                                                        InputProps={{
                                                            inputProps: { min: 0, step: 1 },
                                                        }}
                                                        style={{
                                                            width: '90px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={0}
                                                    />
                                                    <ReactHookFormSelect
                                                        style={{
                                                            width: '150px',
                                                            marginRight: '10px',
                                                        }}
                                                        control={control}
                                                        name="durationUnit"
                                                        variant="outlined"
                                                        label="Time Unit"
                                                        margin="dense"
                                                        defaultValue="Days"
                                                    >
                                                        {Object.values(TimeUnit).map(u => (
                                                            <MenuItem key={u} value={u}>
                                                                {u}
                                                            </MenuItem>
                                                        ))}
                                                    </ReactHookFormSelect>
                                                </div>
                                                {errors?.durationValue && (
                                                    <FormHelperText
                                                        error
                                                        className={classes.helperMessage}
                                                    >
                                                        {errors.durationValue.message}
                                                    </FormHelperText>
                                                )}
                                            </OutlinedSection>
                                        </Grid>
                                    )}
                                    {whenType === AdvocateTaskTemplateWhenType.Timeline && (
                                        <Grid item xs={12}>
                                            <ReactHookFormSelect
                                                control={control}
                                                defaultValue=""
                                                name="whenTimeline.timelineId"
                                                variant="outlined"
                                                label="Select Timeline"
                                                fullWidth
                                                margin="dense"
                                            >
                                                {_.sortBy(
                                                    timelines?.whatsNewTimelinesV2?.results,
                                                    'label.en',
                                                ).map(({ id, label, type }) => (
                                                    <MenuItem key={id} value={id}>
                                                        {label?.en} ({type})
                                                    </MenuItem>
                                                ))}
                                            </ReactHookFormSelect>
                                            {errors?.whenTimeline?.timelineId && (
                                                <FormHelperText
                                                    error
                                                    className={classes.helperMessage}
                                                >
                                                    {errors?.whenTimeline?.timelineId?.message}
                                                </FormHelperText>
                                            )}
                                            <Grid item xs={12}>
                                                <span className={classes.sectionLabel}>
                                                    Date To Complete By
                                                </span>
                                                <div className={classes.offsetWrap}>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Days"
                                                        type="number"
                                                        margin="dense"
                                                        {...register('whenTimeline.offsetDays')}
                                                        InputProps={{
                                                            inputProps: { min: 0, step: 1 },
                                                        }}
                                                        style={{
                                                            width: '90px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={0}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label="Months"
                                                        type="number"
                                                        margin="dense"
                                                        {...register('whenTimeline.offsetMonths')}
                                                        InputProps={{
                                                            inputProps: { min: 0, step: 1 },
                                                        }}
                                                        style={{
                                                            width: '90px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={0}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label="Years"
                                                        type="number"
                                                        margin="dense"
                                                        {...register('whenTimeline.offsetYears')}
                                                        InputProps={{
                                                            inputProps: { min: 0, step: 1 },
                                                        }}
                                                        style={{
                                                            width: '90px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={0}
                                                    />
                                                    <ReactHookFormSelect
                                                        style={{
                                                            width: '150px',
                                                            marginRight: '10px',
                                                        }}
                                                        control={control}
                                                        name="whenTimeline.offsetType"
                                                        variant="outlined"
                                                        label="Offset Type"
                                                        margin="dense"
                                                        defaultValue="Before"
                                                    >
                                                        {Object.values(OffsetType).map(u => (
                                                            <MenuItem key={u} value={u}>
                                                                {u}
                                                            </MenuItem>
                                                        ))}
                                                    </ReactHookFormSelect>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <span className={classes.sectionLabel}>
                                                    Anticipated Time To Complete
                                                </span>
                                                <div className={classes.offsetWrap}>
                                                    <TextField
                                                        variant="outlined"
                                                        type="number"
                                                        margin="dense"
                                                        InputProps={{
                                                            inputProps: { min: 0, step: 1 },
                                                        }}
                                                        {...register('whenTimeline.durationValue')}
                                                        style={{
                                                            width: '90px',
                                                            marginRight: '10px',
                                                        }}
                                                        defaultValue={0}
                                                    />
                                                    <ReactHookFormSelect
                                                        style={{
                                                            width: '150px',
                                                            marginRight: '10px',
                                                        }}
                                                        control={control}
                                                        name="whenTimeline.durationUnit"
                                                        variant="outlined"
                                                        label="Time Unit"
                                                        margin="dense"
                                                        defaultValue="Days"
                                                    >
                                                        {Object.values(TimeUnit).map(u => (
                                                            <MenuItem key={u} value={u}>
                                                                {u}
                                                            </MenuItem>
                                                        ))}
                                                    </ReactHookFormSelect>
                                                </div>
                                            </Grid>
                                            {tagsData?.tags && (
                                                <AutocompleteWithRecordOptions
                                                    options={tagsData?.tags ?? []}
                                                    valueKey="id"
                                                    labelKey="name"
                                                    control={control}
                                                    name="whenTimeline.tags"
                                                    label="Tags"
                                                    placeholder="Select tags ..."
                                                    loading={!tagsData}
                                                    loadingText={LOADING_AMS_TAGS_TEXT}
                                                    error={Boolean(errors.tags)}
                                                />
                                            )}
                                            <Grid container item xs={12}>
                                                <OutlinedSection title="Apps">
                                                    <ReactHookFormSelect
                                                        control={control}
                                                        name="whenTimeline.clientSelectionType"
                                                        variant="outlined"
                                                        defaultValue=""
                                                        label="Selection type *"
                                                        fullWidth
                                                        margin="dense"
                                                        error={Boolean(errors.clientSelectionType)}
                                                    >
                                                        {Object.values(ClientSelectionType).map(
                                                            m => (
                                                                <MenuItem
                                                                    key={m}
                                                                    value={m}
                                                                    data-test={m}
                                                                >
                                                                    {m}
                                                                </MenuItem>
                                                            ),
                                                        )}
                                                    </ReactHookFormSelect>
                                                    {clientSelectionType ===
                                                        ClientSelectionType.IncludeCertainApps && (
                                                        <AutocompleteWithRecordOptions
                                                            options={
                                                                _.sortBy(
                                                                    amsAppsData?.amsApps,
                                                                    'bundleId',
                                                                ) ?? []
                                                            }
                                                            valueKey="bundleId"
                                                            labelKey="bundleId"
                                                            control={control}
                                                            name="whenTimeline.includeApps"
                                                            label={
                                                                amsAppsData?.amsApps
                                                                    ? 'Include apps *'
                                                                    : 'The list of apps is loading...'
                                                            }
                                                            placeholder="Select apps to include..."
                                                            required
                                                            loading={!amsAppsData?.amsApps}
                                                            loadingText={LOADING_AMS_APPS_TEXT}
                                                            error={Boolean(
                                                                errors.whenTimeline?.includeApps,
                                                            )}
                                                            helperText={
                                                                (
                                                                    errors.whenTimeline
                                                                        ?.includeApps as
                                                                        | FieldError
                                                                        | undefined
                                                                )?.message
                                                            }
                                                        />
                                                    )}
                                                    {clientSelectionType ===
                                                        ClientSelectionType.ExcludeCertainApps && (
                                                        <AutocompleteWithRecordOptions
                                                            options={
                                                                _.sortBy(
                                                                    amsAppsData?.amsApps,
                                                                    'bundleId',
                                                                ) ?? []
                                                            }
                                                            valueKey="bundleId"
                                                            labelKey="bundleId"
                                                            control={control}
                                                            name="whenTimeline.excludeApps"
                                                            label={
                                                                amsAppsData?.amsApps
                                                                    ? 'Exclude apps *'
                                                                    : 'The list of apps is loading...'
                                                            }
                                                            placeholder="Select apps to exclude..."
                                                            required
                                                            loading={!amsAppsData?.amsApps}
                                                            loadingText={LOADING_AMS_APPS_TEXT}
                                                            error={Boolean(
                                                                errors.whenTimeline?.excludeApps,
                                                            )}
                                                            helperText={
                                                                (
                                                                    errors.whenTimeline
                                                                        ?.excludeApps as
                                                                        | FieldError
                                                                        | undefined
                                                                )?.message
                                                            }
                                                        />
                                                    )}
                                                </OutlinedSection>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </OutlinedSection>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={8}
                            spacing={1}
                            justifyContent="space-between"
                            direction="row"
                            className={classes.actionButtons}
                        >
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <Button
                                    onClick={handleFormSubmit(handleSubmit)}
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={() => {
                                        setShouldClosedAfterSave(true);
                                        handleFormSubmit(handleSubmit);
                                    }}
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Save &amp; Close
                                </Button>
                            </div>
                        </Grid>
                    </form>
                </Card>
            </Grid>
            <Dialog
                scroll="paper"
                open={Boolean(isModalOpen)}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <ChecklistItemModal
                    onSubmit={handleModalSubmit}
                    data={selectedListItem}
                    closeModal={handleCloseChecklistModal}
                    isEditMode={Boolean(selectedListItem)}
                    isTemplate
                />
            </Dialog>
        </Grid>
    );
};

export default AdvocateTaskTemplate;
