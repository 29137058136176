import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    Checkbox,
    Chip,
    Dialog,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import {
    Add as AddIcon,
    ArrowBack,
    Delete as DeleteIcon,
    Save as SaveIcon,
} from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import { add } from 'date-fns';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Mappers from '~/helpers/mappers';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import Loading from '~/components/Loading/Loading';
import { LocalizedStringTextFields } from '~/components/LocalizedStringTextFields/LocalizedStringTextFields';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import SortableList, { SortableListOnChangeProps } from '~/components/SortableList/SortableList';
import tableIcons from '~/helpers/tableIcons';
import {
    AlertSeverity,
    CarePlanForCarePlanPageDocument,
    CarePlansForAddPageDocument,
    CarePlansForCarePlansPageDocument,
    CarePlansForCarePlansPageQuery,
    CarePlanType,
    useCarePlanForCarePlanPageLazyQuery,
    useCreateCarePlanForNewCarePlanPageMutation,
    usePreFetchDataForCarePlanPageQuery,
    useUpdateCarePlanForCarePlanPageMutation,
    WhatsNewTimelineType,
} from '~/schemaTypes';
import { CarePlanEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ClientSelectionType } from '~/enums/enums';
import { CarePlanTodoModal } from '../components';
import { defaultFormTodo } from '../components/CarePlanTodoModal/helpers';
import {
    CarePlanTodoModalDelete,
    CarePlanTodoModalSubmit,
} from '../components/CarePlanTodoModal/types';
import { defaultFormValues, formValuesToUpdateInput, toFormValuesFromCarePlan } from './helpers';
import { useStyles } from './styles';
import {
    CarePlanFormInput,
    CarePlanModalTodo,
    CarePlanTodoGroupType,
    ViewLimitUnit,
} from './types';
import { TodoTemplate } from './types/CarePlanFormInput';
import { CARE_PLAN_SCHEMA } from './yupSchema';

const CARE_PLAN_LIST_PATHNAME = '/app-config/care-plans';
const LOADING_AMS_APPS_TEXT = 'Loading apps...';
const LOADING_TIMELINES_TEXT = 'Loading timelines...';
const LOADING_AMS_TAGS_TEXT = 'Loading tags...';

const isLabelOverridden = (
    baseCarePlanLabel: { en: string; es: string | null },
    derivedCarePlanLabel: { en: string; es: string | null },
): boolean =>
    baseCarePlanLabel.en !== derivedCarePlanLabel.en ||
    (baseCarePlanLabel.es ?? '') !== (derivedCarePlanLabel.es ?? '');

const CarePlan: React.FC = () => {
    const [modalTodo, setModalTodo] = useState<CarePlanModalTodo>(null);
    const [isModalOpen, setIsOpenModal] = useState<boolean>(false);
    const [todoGroups, setTodoGroups] = useState<CarePlanTodoGroupType[]>([]);
    const [timeline, setTimeline] = useState<string>('');
    const [groupIndexFocus, setGroupIndexFocus] = useState<number | null>(null);
    const { id: carePlanId } = useParams<{ id: string }>();
    const isEditMode = carePlanId !== 'new';
    const { classes } = useStyles();
    const history = useNavigate();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [close, setClose] = useState(false);
    const [isDerivedCarePlan, setIsDerivedCarePlan] = useState(false);
    const [overrideState, setOverrideState] = useState<{
        appLabel: boolean;
        groupLabels: string[];
        tags: boolean;
    }>({
        appLabel: false,
        groupLabels: [],
        tags: false,
    });
    const [baseCarePlanAppLabel, setBaseCarePlanAppLabel] = useState<{
        en: string;
        es?: string | null;
    } | null>(null);

    const { data: preFetchData } = usePreFetchDataForCarePlanPageQuery();

    const [getCarePlan, { data: carePlanData, loading: carePlanLoading }] =
        useCarePlanForCarePlanPageLazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
        });

    const [updateCarePlan, { loading: updateCarePlanLoading }] =
        useUpdateCarePlanForCarePlanPageMutation({
            onCompleted: () => {
                if (close) history('/app-config/care-plans');
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: CarePlanForCarePlanPageDocument,
                    variables: { carePlanInput: { id: isEditMode ? carePlanId : '' } },
                },
            ],
        });

    const [createCarePlan, { loading: createCarePlanLoading }] =
        useCreateCarePlanForNewCarePlanPageMutation({
            onCompleted: () => {
                if (close) history('/app-config/care-plans');
            },
            update: (cache, response) => {
                const newCarePlan = response.data?.createCarePlan?.resourceCreated;
                if (response.data?.createCarePlan?.success && newCarePlan) {
                    const currentBaseCarePlans = cache.readQuery<CarePlansForCarePlansPageQuery>({
                        query: CarePlansForCarePlansPageDocument,
                        variables: {
                            input: { filter: { fields: { type: CarePlanType.Base } } },
                        },
                    });
                    if (currentBaseCarePlans?.carePlansV2?.results) {
                        cache.writeQuery<CarePlansForCarePlansPageQuery>({
                            query: CarePlansForCarePlansPageDocument,
                            data: {
                                carePlansV2: {
                                    __typename: currentBaseCarePlans.carePlansV2.__typename,
                                    results: [
                                        ...currentBaseCarePlans.carePlansV2.results,
                                        newCarePlan,
                                    ],
                                },
                            },
                            variables: {
                                input: { filter: { fields: { type: CarePlanType.Base } } },
                            },
                        });
                    }
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: CarePlansForCarePlansPageDocument,
                },
                {
                    query: CarePlansForAddPageDocument,
                    variables: { input: { filter: { fields: { type: CarePlanType.Base } } } },
                },
            ],
        });

    const useFormMethods = useForm<CarePlanFormInput>({
        resolver: yupResolver(CARE_PLAN_SCHEMA as any),
        context: {
            type: isDerivedCarePlan ? CarePlanType.Derived : CarePlanType.Base,
            overrideState,
        },
        defaultValues: defaultFormValues(),
    });

    const {
        control,
        watch,
        register,
        handleSubmit: handleFormSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useFormMethods;

    // useForm watchers
    const clientSelectionType = watch('clientSelectionType');

    useEffect(() => {
        if (carePlanId !== 'new') {
            getCarePlan({ variables: { carePlanInput: { id: carePlanId } } });
        }
    }, [carePlanId, getCarePlan]);

    useEffect(() => {
        if (isEditMode && carePlanData?.carePlan) {
            const { baseCarePlan } = carePlanData.carePlan;
            const carePlan = toFormValuesFromCarePlan(carePlanData.carePlan);

            reset(carePlan);

            const todoGroupsToSet = baseCarePlan
                ? carePlan.groups.map((todoGroup, index) => ({
                      ...todoGroup,
                      baseCarePlanLabel: baseCarePlan.groups[index].label,
                  }))
                : carePlan.groups;

            setTodoGroups(todoGroupsToSet);
            setTimeline(carePlanData.carePlan.timeline?.label.en || '');
            setIsDerivedCarePlan(isEditMode && carePlanData.carePlan.type === CarePlanType.Derived);
            setBaseCarePlanAppLabel({
                en: baseCarePlan?.appLabel?.en ?? '',
                es: baseCarePlan?.appLabel?.es,
            });
        }
    }, [isEditMode, carePlanData, reset, isDerivedCarePlan]);

    useEffect(() => {
        const carePlan = carePlanData?.carePlan;
        const baseCarePlan = carePlanData?.carePlan?.baseCarePlan;

        if (carePlan && baseCarePlan) {
            let isAppLabelOverridden = false;
            let areTagsOverridden = false;
            const overriddenGroupLabels: string[] = [];

            if (baseCarePlan.appLabel && carePlan.appLabel) {
                isAppLabelOverridden = isLabelOverridden(
                    baseCarePlan.appLabel as unknown as { en: string; es: string | null },
                    carePlan.appLabel as unknown as { en: string; es: string | null },
                );
            }

            carePlan.groups.forEach(({ label, id }, index) => {
                const baseGroupLabel = baseCarePlan?.groups[index].label;

                if (
                    baseGroupLabel &&
                    label &&
                    isLabelOverridden(
                        baseGroupLabel as unknown as { en: string; es: string | null },
                        { en: label.en, es: label.es } as unknown as {
                            en: string;
                            es: string | null;
                        },
                    )
                ) {
                    overriddenGroupLabels.push(id);
                }
            });

            if (carePlan.tags?.length === baseCarePlan.tags?.length) {
                areTagsOverridden =
                    !baseCarePlan.tags?.every(tag => carePlan.tags?.includes(tag)) ?? false;
            } else {
                areTagsOverridden = true;
            }

            setOverrideState({
                appLabel: isAppLabelOverridden,
                groupLabels: overriddenGroupLabels,
                tags: areTagsOverridden,
            });
        }
    }, [setValue, carePlanData]);

    useEffect(() => {
        if (groupIndexFocus !== null && control?._fields) {
            const fieldReference =
                // todo: needs to be redefined since it is dangerous to access "protected" fields
                control._fields[`todoGroups[${groupIndexFocus}].label.en`]?._f.ref;
            if (fieldReference !== undefined && fieldReference.focus) {
                fieldReference.focus();
                setGroupIndexFocus(null);
            }
        }
    }, [groupIndexFocus, control]);

    const carePlan = carePlanData?.carePlan;
    const baseCarePlan = carePlan?.baseCarePlan;

    const deleteItem = (fn: () => void) => {
        TriggerGlobalConfirm({
            open: true,
            message: `Are you sure you want to delete this Group?`,
            callback: () => {
                fn();
            },
        });
    };

    const deleteGroupTemplateConfirm = (fn: () => void) => {
        TriggerGlobalConfirm({
            open: true,
            message: `Are you sure you want to delete this Todo Template?`,
            callback: () => {
                fn();
            },
        });
    };

    const handleValidSubmit = () => {
        let validateTodos = true;
        const carePlan = carePlanData?.carePlan
            ? toFormValuesFromCarePlan(carePlanData?.carePlan)
            : undefined;
        if (todoGroups.length === 0) {
            validateTodos = false;
            reset(carePlan);
        } else if (
            !todoGroups.some(
                group => group.templates.length !== 0 || group.suppressedTemplates?.length !== 0,
            )
        ) {
            validateTodos = false;
            reset(carePlan);
        }
        return validateTodos;
    };

    const handleValidSubmitAlert = () => {
        if (todoGroups.length === 0) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'You must add at least one Todo Group',
            });
        } else if (todoGroups.some(group => group.templates.length === 0)) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Todo Group has to have at least one todo',
            });
        }
    };

    const handleSubmit: SubmitHandler<CarePlanFormInput> = form => {
        if (isEditMode && carePlanId) {
            updateCarePlan({
                variables: {
                    input: {
                        id: carePlanId,
                        data: Mappers.excludeTypeName(
                            formValuesToUpdateInput(
                                { ...form, baseCarePlanAppLabel },
                                todoGroups,
                                isDerivedCarePlan ? CarePlanType.Derived : CarePlanType.Base,
                                overrideState,
                            ),
                        ),
                    },
                },
            });
        } else {
            createCarePlan({
                variables: {
                    input: Mappers.excludeTypeName(formValuesToUpdateInput(form, todoGroups)),
                },
            });
        }
    };

    const handleTodoModalSubmit: CarePlanTodoModalSubmit = updatedTodo => {
        if (!modalTodo) return;
        const { groupIndex } = modalTodo;
        const selectedId = modalTodo.id;
        const groups = [...todoGroups];
        const todos = groups[groupIndex].templates ?? [];
        if (selectedId) {
            const todoIndex = todos.findIndex(t => t.id === selectedId);
            if (todoIndex < 0) {
                todos.push(updatedTodo);
            } else {
                todos[todoIndex] = updatedTodo;
            }
        } else {
            todos.push(updatedTodo);
        }
        groups[groupIndex].templates = todos;
        setTodoGroups(groups);
    };

    const handleTodoClick = useCallback((todo: CarePlanModalTodo) => {
        setModalTodo(todo);
        setIsOpenModal(true);
    }, []);

    const handleSuppressClick = useCallback(
        ({ todoId, groupId }: { todoId?: string; groupId: string }) => {
            if (!todoId || !groupId) return;

            const currentGroup = todoGroups.find(group => group.id.equals(new ObjectId(groupId)));
            if (currentGroup) {
                let suppressedTemplates = [...(currentGroup?.suppressedTemplates ?? [])];
                const isTodoIdExist = !!suppressedTemplates?.find(item => item === todoId);

                if (isTodoIdExist) {
                    suppressedTemplates = suppressedTemplates.filter(
                        suppressedTemplate => suppressedTemplate !== todoId,
                    );
                } else {
                    suppressedTemplates.push(todoId);
                }

                setTodoGroups(
                    todoGroups.map(group => {
                        if (group.id.equals(new ObjectId(groupId))) {
                            return {
                                ...currentGroup,
                                id: new ObjectId(groupId),
                                suppressedTemplates,
                            };
                        }

                        return group;
                    }),
                );
            }
        },
        [todoGroups],
    );

    const handleDeleteTodoTemplate: CarePlanTodoModalDelete = () => {
        if (!modalTodo) return;
        const { groupIndex } = modalTodo;
        const groups = [...todoGroups];
        const todos = groups[groupIndex].templates ?? [];
        groups[groupIndex].templates = todos.filter(t => t.id !== modalTodo.id);
        setTodoGroups(groups);
    };

    const handleDeleteTodoTemplateFromList = useCallback(
        ({ todoId, groupId }: { todoId: string; groupId: string }) => {
            const currentGroup = todoGroups.find(group => group.id.equals(new ObjectId(groupId)));
            if (currentGroup) {
                setTodoGroups(
                    todoGroups.map(group => {
                        if (group.id.equals(new ObjectId(groupId))) {
                            return {
                                ...currentGroup,
                                id: new ObjectId(groupId),
                                templates: currentGroup.templates.filter(
                                    template =>
                                        template.id && !template.id.equals(new ObjectId(todoId)),
                                ),
                            };
                        }

                        return group;
                    }),
                );
            }
        },
        [todoGroups],
    );

    const handleAddTodoClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        const { index } = e.currentTarget.dataset;
        if (index) {
            setModalTodo({ ...defaultFormTodo(), groupIndex: +index });
            setIsOpenModal(true);
        }
    };
    const handleAddTodoGroup = () => {
        setTodoGroups([
            ...todoGroups,
            { id: new ObjectId(), templates: [], suppressedTemplates: [], label: { en: '' } },
        ]);
    };
    const handleCloseTodoModal = () => setIsOpenModal(false);
    const handleTodoGroupChange = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        const todoGroupsClone = [...todoGroups];
        const element = todoGroupsClone[startIndex];
        todoGroupsClone.splice(startIndex, 1);
        todoGroupsClone.splice(endIndex, 0, element);
        setTodoGroups(todoGroupsClone);
    };
    const handleDeleteTodoGroupClick = (index: number) => {
        deleteItem(() => setTodoGroups(todoGroups.filter((_, groupIndex) => groupIndex !== index)));
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
    };

    if (carePlanLoading || createCarePlanLoading || updateCarePlanLoading) {
        return <Loading />;
    }
    let todoGroupOnChangePolling: number | null | undefined = null;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button component={Link} to={CARE_PLAN_LIST_PATHNAME} startIcon={<ArrowBack />}>
                    Back to Care Plans
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography variant="h6" paragraph>
                        {isEditMode ? 'Edit' : 'Create'} Care Plan
                    </Typography>
                    <Divider className={classes.titleDivider} />
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            if (!handleValidSubmit()) {
                                handleValidSubmitAlert();
                            } else {
                                handleFormSubmit(handleSubmit)();
                            }
                        }}
                    >
                        {isDerivedCarePlan && (
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    label="Base Care Plan"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={baseCarePlan?.portalLabel?.en ?? ''}
                                    disabled
                                />
                            </Grid>
                        )}
                        <Grid item>
                            <ReactHookFormSelect
                                control={control}
                                name="timelineId"
                                variant="outlined"
                                defaultValue=""
                                label="Timeline *"
                                margin="dense"
                                fullWidth
                                error={Boolean(errors.timelineId)}
                                loading={!preFetchData?.whatsNewTimelines}
                                loadingText={LOADING_TIMELINES_TEXT}
                                data-test={CarePlanEnum.TIMELINE}
                                disabled={isDerivedCarePlan}
                            >
                                {_.sortBy(
                                    preFetchData?.whatsNewTimelines?.filter(
                                        t => t.type !== WhatsNewTimelineType.Appointment,
                                    ),
                                    'label.en',
                                ).map(t => (
                                    <MenuItem
                                        onClick={() => setTimeline(t.label.en)}
                                        key={t.id}
                                        value={t.id}
                                        data-test={t.label.en}
                                    >
                                        {t.label.en}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Label *"
                                fullWidth
                                margin="dense"
                                {...register('portalLabel')}
                                error={Boolean(errors.portalLabel)}
                                helperText={errors.portalLabel?.message}
                                data-test={CarePlanEnum.LABEL}
                            />
                        </Grid>
                        {isDerivedCarePlan && (
                            <Grid container item xs={12}>
                                <OutlinedSection title="Client">
                                    <ReactHookFormSelect
                                        control={control}
                                        name="clientSelectionType"
                                        variant="outlined"
                                        defaultValue={ClientSelectionType.IncludeAllApps}
                                        label="Selection type *"
                                        fullWidth
                                        margin="dense"
                                        error={Boolean(errors.clientSelectionType)}
                                    >
                                        {Object.values(ClientSelectionType).map(m => (
                                            <MenuItem key={m} value={m} data-test={m}>
                                                {m}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>
                                    {clientSelectionType ===
                                        ClientSelectionType.IncludeCertainApps && (
                                        <AutocompleteWithRecordOptions
                                            options={
                                                _.sortBy(preFetchData?.amsApps, 'bundleId') ?? []
                                            }
                                            valueKey="bundleId"
                                            labelKey="bundleId"
                                            control={control}
                                            name="includeApps"
                                            label="Include apps *"
                                            placeholder="Select apps to include..."
                                            required
                                            loading={!preFetchData?.amsApps}
                                            loadingText={LOADING_AMS_APPS_TEXT}
                                            error={Boolean(errors.includeApps)}
                                            helperText={
                                                (errors.includeApps as FieldError | undefined)
                                                    ?.message
                                            }
                                        />
                                    )}
                                    {clientSelectionType ===
                                        ClientSelectionType.ExcludeCertainApps && (
                                        <AutocompleteWithRecordOptions
                                            options={
                                                _.sortBy(preFetchData?.amsApps, 'bundleId') ?? []
                                            }
                                            valueKey="bundleId"
                                            labelKey="bundleId"
                                            control={control}
                                            name="excludeApps"
                                            label="Exclude apps *"
                                            placeholder="Select apps to exclude..."
                                            required
                                            loading={!preFetchData?.amsApps}
                                            loadingText={LOADING_AMS_APPS_TEXT}
                                            error={Boolean(errors.excludeApps)}
                                            helperText={
                                                (errors.excludeApps as FieldError | undefined)
                                                    ?.message
                                            }
                                        />
                                    )}
                                </OutlinedSection>
                            </Grid>
                        )}
                        <Grid container item xs={12} justifyContent="flex-end">
                            {isDerivedCarePlan && (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={event =>
                                                    setOverrideState({
                                                        ...overrideState,
                                                        appLabel: event.target.checked,
                                                    })
                                                }
                                            />
                                        }
                                        checked={overrideState.appLabel}
                                        label="Override"
                                    />
                                </FormGroup>
                            )}
                            {isDerivedCarePlan && (
                                <Grid container item xs={12}>
                                    <OutlinedSection title="Care Plan Label">
                                        <TextField
                                            variant="outlined"
                                            label="English"
                                            margin="dense"
                                            defaultValue={baseCarePlan?.appLabel?.en}
                                            disabled
                                            fullWidth
                                        />
                                        {baseCarePlan?.appLabel?.es && (
                                            <TextField
                                                variant="outlined"
                                                label="Spanish"
                                                margin="dense"
                                                defaultValue={baseCarePlan?.appLabel?.es}
                                                disabled
                                                fullWidth
                                            />
                                        )}
                                    </OutlinedSection>
                                </Grid>
                            )}
                            {((overrideState.appLabel && isDerivedCarePlan) ||
                                !isDerivedCarePlan) && (
                                <LocalizedStringTextFields
                                    name="appLabel"
                                    label="Care Plan Label"
                                    register={register}
                                    rhfErrors={errors}
                                    dataTest={CarePlanEnum.CARE_PLAN_LABEL}
                                />
                            )}
                        </Grid>
                        {todoGroups.map((group, groupIndex) => (
                            <>
                                {isDerivedCarePlan && (
                                    <Grid container item xs={12} justifyContent="flex-end">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={event =>
                                                            setOverrideState({
                                                                ...overrideState,
                                                                groupLabels: event.target.checked
                                                                    ? [
                                                                          ...overrideState.groupLabels,
                                                                          group.id.toString(),
                                                                      ]
                                                                    : overrideState.groupLabels.filter(
                                                                          i =>
                                                                              i !==
                                                                              group.id.toString(),
                                                                      ),
                                                            })
                                                        }
                                                    />
                                                }
                                                checked={overrideState.groupLabels.includes(
                                                    group.id.toString(),
                                                )}
                                                label="Override"
                                            />
                                        </FormGroup>
                                    </Grid>
                                )}
                                <OutlinedSection
                                    key={`${group.id.toString()}_${group.label?.en}`}
                                    title="Todo Group"
                                    dataTest={`${CarePlanEnum.TODO_GROUP}-${groupIndex}`}
                                >
                                    {isDerivedCarePlan && (
                                        <Grid container item xs={12}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Label (En) *"
                                                    fullWidth
                                                    margin="dense"
                                                    value={group.baseCarePlanLabel?.en}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Label (Es)"
                                                    fullWidth
                                                    margin="dense"
                                                    value={group.baseCarePlanLabel?.es}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                    {(overrideState.groupLabels.includes(group.id.toString()) ||
                                        !isDerivedCarePlan) && (
                                        <Grid container item xs={12}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="English *"
                                                    fullWidth
                                                    {...register(
                                                        `todoGroups.${groupIndex}.label.en`,
                                                    )}
                                                    margin="dense"
                                                    onChange={e => {
                                                        if (todoGroupOnChangePolling !== null) {
                                                            clearTimeout(todoGroupOnChangePolling);
                                                            todoGroupOnChangePolling = null;
                                                        }
                                                        todoGroupOnChangePolling =
                                                            window.setTimeout(() => {
                                                                setTodoGroups(
                                                                    todoGroups.map((group, index) =>
                                                                        index === groupIndex
                                                                            ? {
                                                                                  ...group,
                                                                                  label: {
                                                                                      ...group.label,
                                                                                      en: e.target
                                                                                          .value,
                                                                                  },
                                                                              }
                                                                            : group,
                                                                    ),
                                                                );
                                                                setGroupIndexFocus(groupIndex);
                                                            }, 1000);
                                                    }}
                                                    error={Boolean(
                                                        errors?.todoGroups &&
                                                            errors?.todoGroups[groupIndex]?.label
                                                                ?.en,
                                                    )}
                                                    helperText={
                                                        errors?.todoGroups &&
                                                        errors?.todoGroups[groupIndex]?.label?.en
                                                            ?.message
                                                    }
                                                    defaultValue={group.label?.en}
                                                    data-test={`${CarePlanEnum.TODO_GROUP_LABEL_EN}-${groupIndex}`}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Spanish"
                                                    fullWidth
                                                    margin="dense"
                                                    onBlur={e =>
                                                        setTodoGroups(
                                                            todoGroups.map((group, index) =>
                                                                index === groupIndex
                                                                    ? {
                                                                          ...group,
                                                                          label: {
                                                                              ...group.label,
                                                                              es: e.target.value,
                                                                          },
                                                                      }
                                                                    : group,
                                                            ),
                                                        )
                                                    }
                                                    defaultValue={group.label?.es}
                                                    data-test={`${CarePlanEnum.TODO_GROUP_LABEL_ES}-${groupIndex}`}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item className={classes.addTodoBtnWrap}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            data-index={groupIndex}
                                            onClick={handleAddTodoClick}
                                            startIcon={<AddIcon />}
                                            data-test={`${CarePlanEnum.ADD_TODO}-${groupIndex}`}
                                        >
                                            Add Todo
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className={classes.todoTable}>
                                        <MaterialTable
                                            title="Todo List"
                                            icons={tableIcons}
                                            data={group.templates ?? []}
                                            options={{
                                                search: false,
                                                pageSize: rowsPerPage,
                                                sorting: true,
                                            }}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            columns={[
                                                {
                                                    title: 'Suppress',
                                                    field: 'name',
                                                    filtering: false,
                                                    render: (todo: TodoTemplate) => {
                                                        const baseCarePlanGroup =
                                                            baseCarePlan?.groups.find(
                                                                ({ id }) =>
                                                                    id.toString() ===
                                                                    group.id.toString(),
                                                            );
                                                        const isBaseCarePlanTodoTemplate =
                                                            !!baseCarePlanGroup?.templates?.find(
                                                                ({ id: templateId }) =>
                                                                    templateId.toString() ===
                                                                    todo.id?.toString(),
                                                            ) ?? false;
                                                        const stateGroup = todoGroups.find(
                                                            item => item.id === group.id,
                                                        );
                                                        const isSuppressedTodoTemplate =
                                                            !!stateGroup?.suppressedTemplates?.find(
                                                                todoId =>
                                                                    todoId === todo.id?.toString(),
                                                            );
                                                        return (
                                                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                            <div>
                                                                {isBaseCarePlanTodoTemplate && (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={
                                                                                    isSuppressedTodoTemplate
                                                                                }
                                                                                onChange={() =>
                                                                                    handleSuppressClick(
                                                                                        {
                                                                                            todoId: todo.id?.toHexString(),
                                                                                            groupId:
                                                                                                group.id?.toHexString(),
                                                                                        },
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                        label=""
                                                                    />
                                                                )}
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    render: ({ id }) => {
                                                        return (
                                                            <IconButton
                                                                onClick={() => {
                                                                    if (
                                                                        id === undefined ||
                                                                        group.id === undefined
                                                                    )
                                                                        return;
                                                                    const callback = () => {
                                                                        handleDeleteTodoTemplateFromList(
                                                                            {
                                                                                todoId: id.toHexString(),
                                                                                groupId:
                                                                                    group.id.toHexString(),
                                                                            },
                                                                        );
                                                                    };
                                                                    deleteGroupTemplateConfirm(
                                                                        callback,
                                                                    );
                                                                }}
                                                                data-test={`${CarePlanEnum.DELETE_TODO_ITEM}-${groupIndex}`}
                                                                size="small"
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </IconButton>
                                                        );
                                                    },
                                                },
                                                {
                                                    title: 'Name',
                                                    field: 'name',
                                                    filtering: false,
                                                    width: '40%',
                                                    defaultSort: 'asc',
                                                    render: (todo: TodoTemplate) => {
                                                        // TODO: refactor to map
                                                        const baseCarePlanGroup =
                                                            baseCarePlan?.groups.find(
                                                                ({ id }) =>
                                                                    id.toString() ===
                                                                    group.id.toString(),
                                                            );
                                                        const isBaseCarePlanTodoTemplate =
                                                            !!baseCarePlanGroup?.templates?.find(
                                                                ({ id: templateId }) =>
                                                                    templateId.toString() ===
                                                                    todo.id?.toString(),
                                                            ) ?? false;

                                                        return (
                                                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                            <div
                                                                role="link"
                                                                tabIndex={0}
                                                                data-test={
                                                                    CarePlanEnum.TODO_LIST_ITEM_NAME
                                                                }
                                                                onClick={
                                                                    isBaseCarePlanTodoTemplate
                                                                        ? // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                                          () => {}
                                                                        : () =>
                                                                              handleTodoClick({
                                                                                  ...todo,
                                                                                  groupIndex,
                                                                              })
                                                                }
                                                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                                                {...(!isBaseCarePlanTodoTemplate && {
                                                                    className: classes.tableLabel,
                                                                })}
                                                            >
                                                                {todo.title.en}
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    title: 'Offset',
                                                    filtering: false,
                                                    customSort: (a, b) => {
                                                        const currentValue =
                                                            a.offsetDays ||
                                                            a.offsetMonths ||
                                                            a.offsetYears
                                                                ? add(new Date(), {
                                                                      days: a.offsetDays,
                                                                      months: a.offsetMonths,
                                                                      years: a.offsetYears,
                                                                  }).getTime()
                                                                : 0;

                                                        const nextValue =
                                                            b.offsetDays ||
                                                            b.offsetMonths ||
                                                            b.offsetYears
                                                                ? add(new Date(), {
                                                                      days: b.offsetDays,
                                                                      months: b.offsetMonths,
                                                                      years: b.offsetYears,
                                                                  }).getTime()
                                                                : 0;

                                                        if (currentValue < nextValue) {
                                                            return -1;
                                                        }
                                                        if (currentValue > nextValue) {
                                                            return 1;
                                                        }

                                                        const currentInfo = a.isRecurring
                                                            ? `${a.repeatIntervalUnit} starting ${a.offsetDays} days ${a.offsetMonths} months ${a.offsetYears} years ${a.offsetType}`.toLowerCase()
                                                            : `${a.offsetDays} days ${a.offsetMonths} months ${a.offsetYears} years ${a.offsetType}`.toLowerCase();
                                                        const nextInfo = b.isRecurring
                                                            ? `${b.repeatIntervalUnit} starting ${b.offsetDays} days ${b.offsetMonths} months ${b.offsetYears} years ${b.offsetType}`.toLowerCase()
                                                            : `${b.offsetDays} days ${b.offsetMonths} months ${b.offsetYears} years ${b.offsetType}`.toLowerCase();

                                                        if (currentInfo < nextInfo) {
                                                            return -1;
                                                        }
                                                        if (currentInfo > nextInfo) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    },
                                                    render: (todo: any) => (
                                                        <span>
                                                            {todo.isRecurring
                                                                ? `${todo.repeatIntervalUnit} starting ${todo.offsetDays} days ${todo.offsetMonths} months ${todo.offsetYears} years ${todo.offsetType}`
                                                                : `${todo.offsetDays} days ${todo.offsetMonths} months ${todo.offsetYears} years ${todo.offsetType}`}
                                                        </span>
                                                    ),
                                                },
                                                {
                                                    title: 'Tags',
                                                    filtering: false,
                                                    customSort: (a, b) => {
                                                        const generateComparable: (
                                                            tags: string[],
                                                        ) => string = tags => {
                                                            return tags
                                                                .map((tag: string) => {
                                                                    const tagName =
                                                                        preFetchData?.tags.find(
                                                                            item => item.id === tag,
                                                                        )?.name;
                                                                    return tagName || '';
                                                                })
                                                                .join()
                                                                .toLowerCase();
                                                        };
                                                        const current = a.tags
                                                            ? generateComparable(a.tags)
                                                            : '';
                                                        const next = b.tags
                                                            ? generateComparable(b.tags)
                                                            : ``;

                                                        if (current < next) {
                                                            return -1;
                                                        }
                                                        if (current > next) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    },
                                                    render: (todo: any) =>
                                                        todo.tags.map((tag: string) => {
                                                            const tagName = preFetchData?.tags.find(
                                                                item => item.id === tag,
                                                            )?.name;
                                                            return tagName ? (
                                                                <Chip
                                                                    className={classes.tableTag}
                                                                    label={tagName}
                                                                />
                                                            ) : null;
                                                        }),
                                                },
                                                {
                                                    title: 'Recurring',
                                                    field: 'isRecurring',
                                                    type: 'boolean',
                                                },
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item className={classes.removeTodoGroupBtnWrap}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => handleDeleteTodoGroupClick(groupIndex)}
                                            startIcon={<DeleteIcon />}
                                            disabled={isDerivedCarePlan}
                                            data-test={`${CarePlanEnum.REMOVE_TODO_GROUP}-${groupIndex}`}
                                        >
                                            Remove Todo Group
                                        </Button>
                                    </Grid>
                                </OutlinedSection>
                            </>
                        ))}
                        {!isDerivedCarePlan && (
                            <Grid className={classes.addTodoGroupBtn} item xs={12}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    fullWidth
                                    onClick={handleAddTodoGroup}
                                    startIcon={<AddIcon />}
                                    data-test={CarePlanEnum.ADD_TODO_GROUP}
                                >
                                    Add Todo Group
                                </Button>
                            </Grid>
                        )}
                        {!isDerivedCarePlan && todoGroups && todoGroups.length > 0 && (
                            <OutlinedSection
                                className={classes.sortableList}
                                title="Todo Groups Order"
                            >
                                <Grid item xs={12}>
                                    <SortableList
                                        list={todoGroups}
                                        droppableId="TodoGroupOrder"
                                        onChange={handleTodoGroupChange}
                                        onDelete={handleDeleteTodoGroupClick}
                                    />
                                </Grid>
                            </OutlinedSection>
                        )}
                        {isDerivedCarePlan && (
                            <Grid container item xs={12} justifyContent="flex-end">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={event =>
                                                    setOverrideState(prevState => ({
                                                        ...prevState,
                                                        tags: event.target.checked,
                                                    }))
                                                }
                                            />
                                        }
                                        checked={overrideState.tags}
                                        label="Override"
                                    />
                                </FormGroup>
                            </Grid>
                        )}
                        {preFetchData?.tags && isDerivedCarePlan && (
                            <AutocompleteWithRecordOptions
                                options={preFetchData.tags ?? []}
                                valueKey="id"
                                labelKey="name"
                                control={control}
                                name="baseCarePlanTags"
                                label="Tags"
                                loading={!preFetchData?.tags}
                                loadingText={LOADING_AMS_TAGS_TEXT}
                                error={Boolean(errors.tags)}
                                disabled
                            />
                        )}
                        {preFetchData?.tags && (!isDerivedCarePlan || overrideState.tags) && (
                            <AutocompleteWithRecordOptions
                                options={preFetchData.tags ?? []}
                                valueKey="id"
                                labelKey="name"
                                control={control}
                                name="tags"
                                label="Tags"
                                placeholder="Select tags ..."
                                loading={!preFetchData?.tags}
                                loadingText={LOADING_AMS_TAGS_TEXT}
                                error={Boolean(errors.tags)}
                            />
                        )}
                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                margin="dense"
                                {...register('viewLimitValue')}
                                style={{ width: '80px' }}
                                error={Boolean(errors.viewLimitValue)}
                                defaultValue={1}
                            />
                            <ReactHookFormSelect
                                style={{ width: '300px' }}
                                control={control}
                                name="viewLimitUnit"
                                variant="outlined"
                                label="Time Unit"
                                margin="dense"
                                error={Boolean(errors.viewLimitUnit)}
                                defaultValue={ViewLimitUnit.Months}
                            >
                                {Object.values(ViewLimitUnit).map(u => (
                                    <MenuItem key={u} value={u}>
                                        {u}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            justifyContent="space-between"
                            direction="row"
                            className={classes.actionButtons}
                        >
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <Button
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={() => {
                                        if (handleValidSubmit()) {
                                            setClose(true);
                                        }
                                    }}
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    data-test={CarePlanEnum.SAVE_AND_CLOSE}
                                >
                                    Save &amp; Close
                                </Button>
                            </div>
                        </Grid>
                    </form>
                </Card>
                <Dialog
                    scroll="paper"
                    open={Boolean(isModalOpen)}
                    fullWidth
                    maxWidth="md"
                    aria-labelledby="form-dialog-title"
                >
                    <CarePlanTodoModal
                        onSubmit={handleTodoModalSubmit}
                        data={modalTodo}
                        closeModal={handleCloseTodoModal}
                        timeline={timeline}
                        onDelete={handleDeleteTodoTemplate}
                        prefetchData={preFetchData}
                        isEditMode={Boolean(modalTodo?.title.en)}
                    />
                </Dialog>
            </Grid>
        </Grid>
    );
};

export default CarePlan;
