import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import { Control, Controller, UseControllerReturn } from 'react-hook-form';
import { isNotUndefined } from '~/typeguards/isNotUndefined';

interface AutocompleteWithRecordOptionsProps<
    T extends Record<V | L, string>,
    V extends keyof T,
    L extends keyof T,
> {
    options: T[];
    defaultValues?: T[]; // make sure you pass defaultValues to useForm if none are provided to the components
    valueKey: V;
    labelKey: L;
    name: string;
    control: Control<any>;
    sortOptionsComparator?: (optionA: T, optionB: T) => number;
    label: string;
    placeholder?: string;
    required?: boolean;
    requiredCount?: number;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    dataTest?: string;
    loading?: boolean;
    loadingText?: string;
    onLoadMore?: () => void;
    hasMoreOptions?: boolean;
    onSearch?: (searchText: string) => void;
    isFilteringDisabled?: boolean;
}

export const AutocompleteWithRecordOptions = <
    T extends Record<V | L, string>,
    V extends keyof T,
    L extends keyof T,
>({
    name,
    control,
    options: propOptions,
    defaultValues,
    sortOptionsComparator,
    label,
    placeholder = '',
    valueKey,
    labelKey,
    required,
    requiredCount = 1,
    error,
    helperText,
    loading,
    loadingText,
    disabled = false,
    dataTest,
    onLoadMore,
    hasMoreOptions,
    onSearch,
    isFilteringDisabled = false,
}: AutocompleteWithRecordOptionsProps<T, V, L>): JSX.Element => {
    const [selectedOptions, setSelectedOptions] = useState<T[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const optionMap = useMemo(
        () =>
            [...propOptions, ...selectedOptions].reduce(
                (map, option) => map.set(option[valueKey], option),
                new Map<T[V], T>(),
            ),
        [propOptions, valueKey, selectedOptions],
    );

    const optionIds = useMemo(
        () =>
            sortOptionsComparator
                ? propOptions
                      .slice()
                      .sort(sortOptionsComparator)
                      .map(option => option[valueKey])
                : propOptions.map(option => option[valueKey]),
        [propOptions, valueKey, sortOptionsComparator],
    );

    const getOptionLabelByValue = (value: T[V]) => optionMap.get(value)?.[labelKey] ?? '';

    const handleScroll = (event: React.SyntheticEvent) => {
        const listboxNode = event.currentTarget;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 5) {
            if (onLoadMore && hasMoreOptions) {
                onLoadMore();
            }
        }
    };

    useEffect(() => {
        if (onSearch) {
            const delayDebounceFn = setTimeout(() => {
                onSearch(searchText);
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
        return undefined;
    }, [searchText, onSearch]);

    const renderProp = ({ field: { value: _values, onChange } }: UseControllerReturn) => {
        const values: T[V][] = _values; // for typecasting
        const selectedOptionValues = sortOptionsComparator
            ? values
                  .map(value => optionMap.get(value))
                  .filter(isNotUndefined)
                  .sort(sortOptionsComparator)
                  .map(option => option[valueKey])
            : values ?? [];

        return (
            <Autocomplete<T[V], true>
                multiple
                limitTags={5}
                options={optionIds}
                filterOptions={isFilteringDisabled ? x => x : undefined}
                value={selectedOptionValues}
                onChange={(e, optionValuesOnChange) => {
                    setSelectedOptions(
                        optionValuesOnChange
                            .map(value => optionMap.get(value))
                            .filter(isNotUndefined),
                    );
                    onChange(optionValuesOnChange);
                }}
                getOptionLabel={getOptionLabelByValue}
                isOptionEqualToValue={(option, value) => option === value}
                disabled={disabled}
                loading={loading}
                loadingText={loadingText}
                ListboxProps={{
                    onScroll: handleScroll,
                    style: { maxHeight: '300px', overflow: 'auto' },
                }}
                onInputChange={(e, newValue) => setSearchText(newValue)}
                renderOption={(props, option, { index }) => (
                    <li {...props} key={index}>
                        {getOptionLabelByValue(option)}
                    </li>
                )}
                filterSelectedOptions
                renderInput={params => (
                    <TextField
                        variant="outlined"
                        label={label}
                        placeholder={placeholder}
                        error={error}
                        helperText={helperText}
                        required={required && selectedOptionValues.length < requiredCount}
                        {...(dataTest && { 'data-test': dataTest })}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress
                                            style={{ right: 15, position: 'absolute' }}
                                            size={20}
                                        />
                                    ) : null}
                                    {loading ? null : params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        );
    };

    const controllerWithDefs = (
        <Controller
            name={name}
            control={control}
            render={renderProp}
            defaultValue={defaultValues}
        />
    );
    const controllerWithoutDefs = <Controller name={name} control={control} render={renderProp} />;
    return defaultValues ? controllerWithDefs : controllerWithoutDefs;
};
