export const logInfo = ({ message }: { message: string }): void => {
    // eslint-disable-next-line no-console
    console.info(`[PORTAL-UI] - INFO ${new Date().toISOString()} PORTAL-UI: ${message}`);
};

export const logWarn = ({ message }: { message: string }): void => {
    // eslint-disable-next-line no-console
    console.warn(`[PORTAL-UI] - WARN ${new Date().toISOString()} PORTAL-UI: ${message}`);
};

export const logError = ({ message }: { message: string }): void => {
    // eslint-disable-next-line no-console
    console.warn(`[PORTAL-UI] - ERROR ${new Date().toISOString()} PORTAL-UI: ${message}`);
};
