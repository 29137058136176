// This component has a UI Test
import { Alert, Button, Grid, IconButton, MenuItem, Snackbar, TextField } from '@mui/material';
import { AddCircleOutline, Save } from '@mui/icons-material';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import SortableList from '~/components/SortableList/SortableList';
import tableIcons from '~/helpers/tableIcons';
import {
    FetchConfigAppForConfigAppSummaryPageDocument,
    FetchConfigAppForConfigAppSummaryPageQuery,
    useFetchConfigAppForConfigAppSummaryPageQuery,
    useSurveyScreensForOnboardingPageQuery,
    useUpsertAppValueForAppDefMutation,
} from '~/schemaTypes';
import { OnboardingScreenEnum } from '~/selectors';
import useStyles from './styles';

type AMSApp = NonNullable<FetchConfigAppForConfigAppSummaryPageQuery['amsAppByBundleId']>;
type ConfigValueDef = NonNullable<
    FetchConfigAppForConfigAppSummaryPageQuery['amsAppByBundleId']
>['defaultValues'][0];

interface onboardingScreen {
    id: string;
    name: string;
    label: {
        en: string;
    };
    displayedScreenName?: string;
    organization?: {
        id: string;
        brandingName: string;
    };
}

type ConfigAppSummaryOnboardingProps = {
    valueDefs: ConfigValueDef[];
    app: AMSApp;
};

const ONBOARDING_SCREEN_NAME = 'onBoardingScreens';
const ONBOARDING_SCREEN_SOURCE = '(Onboarding screens)';
const SURVEY_SCREEN_SOURCE = '(Survey screens)';

const screenList = [
    {
        id: 'OnBoardingCareTeamView',
        name: 'Care Team View',
        label: { en: 'Care Team' },
    },
    {
        id: 'OnBoardingChooseLanguageView',
        name: 'On Boarding Choose Language View',
        label: { en: 'Language chooser' },
    },
    { id: 'OnBoardingWelcomeView', name: 'On Boarding Welcome View', label: { en: 'Welcome' } },
    {
        id: 'OnBoardingPrivacyPolicyView',
        name: 'On Boarding Privacy Policy View',
        label: { en: 'Privacy Policy' },
    },
    {
        id: 'OnBoardingTermsOfUseView',
        name: 'On Boarding Terms Of Use View',
        label: { en: 'Terms of Use' },
    },
    { id: 'OnBoardingEulaView', name: 'On Boarding Eula View', label: { en: 'EULA' } },
    { id: 'WelcomeForSSO', name: 'Welcome For SSO', label: { en: 'Welcome for SSO' } },
    {
        id: 'WelcomeForEligibility',
        name: 'Welcome for Eligibility',
        label: { en: 'Welcome for Eligibility' },
    },
    {
        id: 'WelcomeForEligibilityWithNoInviteCode',
        name: 'Welcome for Eligibility with no invitation code',
        label: { en: 'Welcome for Eligibility with no invitation code' },
    },
    {
        id: 'WelcomeWithNoEligibility',
        name: 'Welcome with no Eligibility',
        label: { en: 'Welcome with no Eligibility' },
    },
    {
        id: 'Authorization',
        name: 'Authorization',
        label: { en: 'Authorization' },
    },
];

const ConfigAppSummaryOnboarding: React.FC<ConfigAppSummaryOnboardingProps> = ({
    app,
    valueDefs,
}) => {
    const { classes } = useStyles();
    const appBundleId = app.bundleId;
    const configValueDef = valueDefs.find(item => item.name === ONBOARDING_SCREEN_NAME);
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [upsertValue] = useUpsertAppValueForAppDefMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchConfigAppForConfigAppSummaryPageDocument,
                variables: { input: { bundleId: appBundleId } },
            },
        ],
    });

    // Snackbar
    const ERROR_MESSAGE = 'Missing onBoardingScreens app config';
    const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
    const hideErrorMessage = () => setIsErrorMessageOpen(false);

    // Onboarding screens
    const onboardingScreenList = screenList.map(item => ({
        ...item,
        displayedScreenName: `${item.label.en} ${ONBOARDING_SCREEN_SOURCE}`,
    }));
    const [onboardingScreens, setOnboardingScreens] =
        useState<onboardingScreen[]>(onboardingScreenList);
    const [selectedOnboardingScreenId, setSelectedOnboardingScreenId] = useState<string>(
        onboardingScreens[0]?.id,
    );

    // Survey screens
    const [surveyScreensFullList, setsurveyScreensFullList] = useState<onboardingScreen[]>([]);
    const [surveyScreens, setSurveyScreens] = useState<onboardingScreen[]>([]);

    const { data: surveyScreensData, loading: surveyScreensLoading } =
        useSurveyScreensForOnboardingPageQuery({
            onCompleted: data => {
                const surveyScreenList = (data?.surveyDefs || []).map(item => ({
                    ...item,
                    displayedScreenName: `${item.label.en} ${SURVEY_SCREEN_SOURCE}`,
                }));
                setSurveyScreens(surveyScreenList as onboardingScreen[]);
                setsurveyScreensFullList(surveyScreenList as onboardingScreen[]);
            },
        });

    const [selectedSurveyScreenId, setSelectedSurveyScreenId] = useState<string>('');

    // Sortable List
    const [screenSortableList, setScreenSortableList] = useState<onboardingScreen[]>([]);
    const [sortableScreenListIds, setSortableScreenListIds] = useState<string[]>([]);

    useFetchConfigAppForConfigAppSummaryPageQuery({
        variables: { input: { bundleId: appBundleId } },
        onCompleted: data => {
            setSortableScreenListIds(
                (data?.amsAppByBundleId?.values.find(item => item.name === ONBOARDING_SCREEN_NAME)
                    ?.value?.strArr as string[]) || [],
            );
        },
    });

    useEffect(() => {
        if (surveyScreensFullList.length) {
            const sortableScreenListIdsSet = new Set(sortableScreenListIds);
            // survey screens
            const notSelectedSurveyScreens = surveyScreens.filter(
                screen => !sortableScreenListIdsSet.has(screen.id),
            );
            setSurveyScreens(notSelectedSurveyScreens);
            if (notSelectedSurveyScreens.length) {
                setSelectedSurveyScreenId(notSelectedSurveyScreens[0].id);
            }
            // onboarding screens
            const notSelectedOnboardingScreens = onboardingScreens.filter(
                screen => !sortableScreenListIdsSet.has(screen.id),
            );
            setOnboardingScreens(notSelectedOnboardingScreens);
            if (notSelectedOnboardingScreens.length) {
                setSelectedOnboardingScreenId(notSelectedOnboardingScreens[0].id);
            }

            const allScreens = surveyScreens.concat(onboardingScreens);
            setScreenSortableList(
                sortableScreenListIds
                    .map(id => {
                        return allScreens.find(screen => screen.id === id) as onboardingScreen;
                    })
                    .filter(s => s !== undefined),
            );
        }
    }, [surveyScreensFullList]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectOnboardingScreen = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
        setSelectedOnboardingScreenId(e.target.value);
    };

    const selectSurveyScreen = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSelectedSurveyScreenId(e.target.value);
    };

    const addOnboardingScreen = () => {
        setScreenSortableList(
            screenSortableList.concat(
                onboardingScreens.filter(item => item.id === selectedOnboardingScreenId),
            ),
        );
        const restOfScreens = onboardingScreens.filter(
            item => item.id !== selectedOnboardingScreenId,
        );
        setOnboardingScreens(restOfScreens);
        if (!restOfScreens.length) return;
        setSelectedOnboardingScreenId(restOfScreens[0].id);
    };

    const addSurveyScreen = () => {
        const screenId = selectedSurveyScreenId || surveyScreens[0].id;
        setScreenSortableList(
            screenSortableList.concat(surveyScreens.filter(item => item.id === screenId)),
        );
        const restOfScreens = surveyScreens.filter(item => item.id !== screenId);
        setSurveyScreens(restOfScreens);
        if (!restOfScreens.length) return;
        setSelectedSurveyScreenId(restOfScreens[0].id);
    };

    const deleteSortableItem = (sortableItemIndex: number) => {
        const deletedItem = screenSortableList.find(
            (item, index) => index === sortableItemIndex,
        ) as onboardingScreen;

        if (screenList.find(item => item.id === deletedItem.id)) {
            const deletedOnboardingScreens = onboardingScreens.concat(deletedItem);
            setOnboardingScreens(deletedOnboardingScreens);
            setSelectedOnboardingScreenId(deletedOnboardingScreens[0].id);
        } else {
            const deletedSurveyScreens = surveyScreens.concat(deletedItem);
            setSurveyScreens(deletedSurveyScreens);
            setSelectedSurveyScreenId(deletedSurveyScreens[0].id);
        }

        setScreenSortableList(
            screenSortableList.filter((item, index) => index !== sortableItemIndex),
        );
    };

    const sortScreens = (arr: onboardingScreen[], startIndex: number, endIndex: number) => {
        const screens = [...arr];
        const sortableItem = screens[startIndex];
        screens.splice(startIndex, 1);
        screens.splice(endIndex, 0, sortableItem);
        setScreenSortableList(screens);
    };

    const saveOnboardingScreens = () => {
        const value = { strArr: screenSortableList.map(item => item.id) };
        if (configValueDef) {
            upsertValue({
                variables: {
                    input: {
                        data: {
                            name: ONBOARDING_SCREEN_NAME,
                            value,
                            appBundleId,
                            valueDefId: configValueDef.id,
                        },
                    },
                },
            });
        } else {
            setIsErrorMessageOpen(true);
        }
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    if (surveyScreensLoading) {
        return <Loading />;
    }

    const displayedSortableList = screenSortableList.map(item => ({
        id: item.id,
        label: { en: item.displayedScreenName },
    })) as onboardingScreen[];

    return (
        <div className={classes.root} ref={tableRef}>
            <Snackbar
                open={isErrorMessageOpen}
                autoHideDuration={5000}
                onClose={hideErrorMessage}
                ClickAwayListenerProps={{ mouseEvent: false }}
            >
                <Alert onClose={hideErrorMessage} severity="error">
                    {ERROR_MESSAGE}
                </Alert>
            </Snackbar>
            <MaterialTable
                icons={tableIcons as MaterialTableProps<any>['icons']}
                columns={[]}
                data={[]}
                title="Onboarding Screens"
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [5, 10, 20], search: false }}
                components={{
                    Body: () => (
                        <Grid container spacing={1} direction="row" alignItems="center">
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    label="Onboarding screens"
                                    id="selectOnboardingScreenValue"
                                    select
                                    fullWidth
                                    size="small"
                                    value={selectedOnboardingScreenId}
                                    onChange={selectOnboardingScreen}
                                    disabled={onboardingScreens.length === 0}
                                    data-test={OnboardingScreenEnum.ONBOARDING_SCREENS}
                                >
                                    {onboardingScreens.map(({ id, label }) => (
                                        <MenuItem value={id} data-test={label.en}>
                                            {label.en}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    aria-label="add"
                                    disabled={onboardingScreens.length === 0}
                                    data-test={OnboardingScreenEnum.ADD_ONBOARDING_SCREEN}
                                    size="large"
                                >
                                    <AddCircleOutline
                                        onClick={addOnboardingScreen}
                                        fontSize="large"
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    id="selectSurveyScreenValue"
                                    label="Survey screens"
                                    select
                                    fullWidth
                                    size="small"
                                    value={
                                        selectedSurveyScreenId ||
                                        surveyScreensData?.surveyDefs[0].id
                                    }
                                    onChange={selectSurveyScreen}
                                    disabled={surveyScreens.length === 0}
                                    data-test={OnboardingScreenEnum.SURVEY_SCREENS}
                                >
                                    {surveyScreens.map(({ id, name, label }) => (
                                        <MenuItem value={id} data-test={label.en}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    aria-label="add"
                                    disabled={surveyScreens.length === 0}
                                    data-test={OnboardingScreenEnum.ADD_SURVEY_SCREEN}
                                    size="large"
                                >
                                    <AddCircleOutline onClick={addSurveyScreen} fontSize="large" />
                                </IconButton>
                            </Grid>

                            <Grid item xs={8}>
                                <OutlinedSection title="Screen Order">
                                    <SortableList
                                        list={displayedSortableList}
                                        droppableId="surveyGroupOrder"
                                        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
                                        onChange={({ startIndex, endIndex }) => {
                                            sortScreens(screenSortableList, startIndex, endIndex);
                                        }}
                                        onDelete={deleteSortableItem}
                                    />
                                </OutlinedSection>
                            </Grid>
                            <Grid item xs={12} spacing={2}>
                                <div style={{ width: '100%', textAlign: 'right' }}>
                                    <Button
                                        onClick={saveOnboardingScreens}
                                        startIcon={<Save />}
                                        color="secondary"
                                        variant="contained"
                                        data-test={OnboardingScreenEnum.SAVE}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    ),
                    Pagination: () => null,
                }}
            />
        </div>
    );
};

export default ConfigAppSummaryOnboarding;
