import { ResourcesConfig } from 'aws-amplify';

const CognitoUserPoolIdStorageKey = 'CognitoUserPoolId';
const CognitoClientIdStorageKey = 'CognitoClientId';
const cognitoUserPoolIdStored = localStorage.getItem(CognitoUserPoolIdStorageKey);
const cognitoClientIdStored = localStorage.getItem(CognitoClientIdStorageKey);

export const AmplifyConfig: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolClientId:
                process.env.REACT_APP_COGNITO_CLIENT_ID ??
                cognitoClientIdStored ??
                '40qk3nv2tmt7na02krahpu1867',
            userPoolId:
                process.env.REACT_APP_COGNITO_POOL_ID ??
                cognitoUserPoolIdStored ??
                'us-east-1_01Lex5CI8',
        },
    },
};
