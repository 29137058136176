import { cognitoUserPoolsTokenProvider } from '@aws-amplify/auth/cognito';
import { AlertSeverity } from '~/schemaTypes';
import { AccessToken, PersistUserCredentials, TriggerGlobalAlert } from '~/state';
import { reInitSignoutWorker } from '~/workers/signOutWorker';

export const refreshCognitoTokenAndInitSignOutWorker = async () => {
    const tokens = await cognitoUserPoolsTokenProvider.getTokens({
        forceRefresh: true,
    });
    if (tokens === null) {
        return null;
    }
    const { accessToken, idToken } = tokens;
    try {
        if (!accessToken || !idToken) {
            throw new Error('No tokens found');
        }
        PersistUserCredentials({
            accessToken: accessToken.toString(),
            idToken: idToken.toString(),
        });
        const cognitoToken = AccessToken();
        if (cognitoToken) {
            reInitSignoutWorker(cognitoToken.getExpiration());
        }
        return {
            accessToken,
            idToken,
        };
    } catch (error) {
        TriggerGlobalAlert({
            message: 'Your session expired, please login again.',
            severity: AlertSeverity.Warning,
        });
        return null;
    }
};
