import { Button } from '@mui/material';
import { Save } from '@mui/icons-material';
import _ from 'lodash';
import React, { useState } from 'react';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { AmsProduct, Language, ReadingLevel, usePreFetchAppsQuery } from '~/schemaTypes';
import { CloneVariantModalParms } from '../Editors/ArticleEditor';

const CloneVariantModal: React.FC<CloneVariantModalParms> = parms => {
    const { existingVariants, submitHandler } = parms;
    const [variantExists, setVariantExists] = useState(false);
    const [selectedLang, setSelectedLang] = useState<Language>(Language.En);
    const [selectedReadingLevel, setSelectedReadingLevel] = useState<ReadingLevel>(
        ReadingLevel.Eighth,
    );
    const [selectedApp, setSelectedApp] = useState<string | null>(null);
    const { data: appList, loading: appListLoading } = usePreFetchAppsQuery();

    if (appListLoading) return <Loading />;

    const onSubmit = () => {
        setVariantExists(false);
        if (selectedLang && selectedReadingLevel) {
            if (
                existingVariants.find(
                    v =>
                        v.language === selectedLang &&
                        v.readingLevel === selectedReadingLevel &&
                        (selectedApp === null
                            ? v.appBundleId === undefined
                            : v.appBundleId === selectedApp),
                ) !== undefined
            )
                setVariantExists(true);
            else
                submitHandler({
                    language: selectedLang,
                    readingLevel: selectedReadingLevel,
                    appBundleId: selectedApp || undefined,
                });
        }
    };

    return (
        <>
            <OutlinedSection title="New Variant Type *">
                <div>
                    <span>
                        Language:{' '}
                        <select
                            onChange={e => {
                                setSelectedLang(e.target.value as Language);
                            }}
                            value={selectedLang}
                        >
                            <option key={Language.En} value={Language.En}>
                                English
                            </option>
                            <option key={Language.Es} value={Language.Es}>
                                Spanish
                            </option>
                        </select>
                    </span>
                    <span style={{ marginLeft: '10px' }}>
                        Reading Level:{' '}
                        <select
                            onChange={e => {
                                setSelectedReadingLevel(e.target.value as ReadingLevel);
                            }}
                            value={selectedReadingLevel}
                        >
                            <option key="5" value={ReadingLevel.Fifth}>
                                Fifth Grade
                            </option>
                            <option key="6" value={ReadingLevel.Eighth}>
                                Eighth Grade
                            </option>
                        </select>
                    </span>
                    <span style={{ marginLeft: '10px' }}>
                        App:{' '}
                        <select
                            onChange={e => {
                                setSelectedApp(e.target.value);
                            }}
                            value={selectedApp ?? ''}
                        >
                            <option key="1" value="">
                                All Apps
                            </option>
                            {_.sortBy(appList?.amsApps, 'appBundleId')
                                .filter(a => a.productConst === AmsProduct.VbcPlatform)
                                .map(a => (
                                    <option key={a.bundleId} value={a.bundleId}>
                                        {a.bundleId}
                                    </option>
                                ))}
                        </select>
                    </span>
                </div>
                {variantExists && (
                    <div style={{ fontWeight: 'bold', color: 'red' }}>
                        Variant for selected Language, Reading Level and App already exists.
                    </div>
                )}
            </OutlinedSection>
            <div style={{ width: '100%', textAlign: 'right' }}>
                <Button
                    aria-label="clone"
                    type="submit"
                    startIcon={<Save />}
                    color="secondary"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save Clone
                </Button>
            </div>
        </>
    );
};

export default CloneVariantModal;
