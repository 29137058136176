/* eslint-disable radix */
import MaterialTable, {
    MaterialTableProps,
    MTableBodyRow,
    OrderByCollection,
} from '@material-table/core';
import React, { useMemo, useRef, useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import { PAGESIZE } from '~/constants';
import {
    OrderByDirectionEnum,
    AlertSeverity,
    PatientToCall,
    useUsersV2ForPatientsPageQuery,
    useFetchPatientCallListForPreviewQuery,
    FetchPatientCallListForPreviewQueryVariables,
    Patient,
    PatientFilterFilterInput,
    useUpdatePatientCallListCallStatusForPreviewMutation,
    useFetchPatientCallListPreviewExportToCsvLazyQuery,
} from '~/schemaTypes';
import tableIcons from '~/helpers/tableIcons';
import {
    Badge,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Link as MuiLink,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material';
import { TriggerGlobalAlert } from '~/state';
import { ArrowBack, Download, FilterList, InfoOutlined, Loop, MoreVert } from '@mui/icons-material';
import { keyframes } from 'tss-react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useUserPermissions } from '~/hooks';
import moment from 'moment';
import { UserProfileDefsEnum } from '~/enums/UserProfileDefsEnum';
import { format, toDate } from 'date-fns-tz';
import copy from 'copy-to-clipboard';
import { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import { primaryColor } from '~/theme/WildTheme';
import { useAtom } from 'jotai';
import { isString, toFormattedDateString } from '~/utils/dateValue';
import PatientProfileVariableLink from '../../Patients/PatientProfileVariableLink/PatientProfileVariableLink';
import {
    CallListPreviewSortingFields,
    CallListSortingFieldsToPatientSorting,
    ICallListPreviewOrderChange,
} from './types';
import { FilterColumnsModal } from '../../Patients/FilterColumnsModal/FilterColumnsModal';
import { FilterPatientsModal } from '../../Patients/FilterPatientsModal/FilterPatientsModal';
import { PatientsHiddenColumnsAtom } from '../../Patients/Patients.context';
import PatientNotesModal from '../../Patients/PatientNotesModal/PatientNotesModal';
import { PatientSortingFields } from '../../Patients/types';

const columnIdToSortingFieldMap: Record<number, CallListPreviewSortingFields> = {
    2: CallListPreviewSortingFields.firstName,
    3: CallListPreviewSortingFields.lastName,
    5: CallListPreviewSortingFields.createdAt,
    6: CallListPreviewSortingFields.assignedHealthAdvocate,
    11: CallListPreviewSortingFields.birthDate,
    13: CallListPreviewSortingFields.onboardingCompleteDate,
    14: CallListPreviewSortingFields.isPregnant,
    15: CallListPreviewSortingFields.dueDate,
    16: CallListPreviewSortingFields.deliveryDate,
};
const tableFieldNameToSortingFieldName: (
    fieldName: CallListPreviewSortingFields,
) => PatientSortingFields = fieldName => {
    return CallListSortingFieldsToPatientSorting[fieldName];
};

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    backButton: {
        marginBottom: '1.5em',
    },
    searchChips: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
    },
    rotating: {
        animation: `${keyframes`
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
        `} 1s linear infinite`,
    },
    tasksButton: {
        '&:hover': { backgroundColor: 'transparent' },
        '&.Mui-disabled': { color: '#116b8e' },
    },
}));

const PatientCallListPreview: React.FC = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const { classes } = useStyles();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<ICallListPreviewOrderChange>({
        field: tableFieldNameToSortingFieldName(CallListPreviewSortingFields.createdAt),
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
    });
    const [openFilterPatientsModal, setOpenFilterPatientsModal] = useState<boolean>(false);
    const [filter] = useState<IFilterChange<PatientToCall> | null>(null);
    const { pagePermissions } = useUserPermissions();
    const [hiddenColumnsAnchorEl, setHiddenColumnsAnchorEl] = useState<null | HTMLElement>(null);
    const [tableTitle] = useState<string>('Call List Preview');
    const [processingPatientId, seProcessingPatientId] = useState<string | null>(null);
    const { callListId } = useParams();
    const filteredTags = localStorage.getItem('filteredTags');
    const filteredAdvocate = localStorage.getItem('filteredAdvocate');
    const filteredFirstName = localStorage.getItem('filteredFirstName');
    const filteredLastName = localStorage.getItem('filteredLastName');
    const filteredExternalId = localStorage.getItem('filteredExternalId');
    const filteredBirthDate = localStorage.getItem('filteredBirthDate');
    const filteredEmail = localStorage.getItem('filteredEmail');
    const filteredMemberId = localStorage.getItem('filteredMemberId');
    const filterTestData = localStorage.getItem('filterTestData') === 'true' ?? false;
    const navigate = useNavigate();

    const handleOpenHiddenColumnsModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        setHiddenColumnsAnchorEl(event.currentTarget);
    };

    const filtersFromFilterModal = useMemo<IFilterChange<Patient>>(() => {
        const processed: IFilterChange<Patient> = {
            fields: {},
        };
        if (filteredFirstName) {
            processed.fields.firstName = filteredFirstName;
        }
        if (filteredLastName) {
            processed.fields.lastName = filteredLastName;
        }
        if (filteredEmail) {
            processed.fields.email = filteredEmail;
        }
        if (filteredExternalId) {
            processed.fields.externalId = filteredExternalId;
        }
        if (filteredBirthDate) {
            processed.fields.birthDate = filteredBirthDate;
        }
        if (filteredMemberId) {
            processed.fields.memberId = filteredMemberId;
        }
        return processed;
    }, [
        filteredFirstName,
        filteredLastName,
        filteredEmail,
        filteredExternalId,
        filteredBirthDate,
        filteredMemberId,
    ]);

    const { data: usersForAssignedHealthAdvocate, loading: usersToAssignLoading } =
        useUsersV2ForPatientsPageQuery();
    const usersForAssignedHealthAdvocateList = usersForAssignedHealthAdvocate?.usersV2?.results;

    const [hiddenColumns] = useAtom(PatientsHiddenColumnsAtom);
    const getHeaderStyle = (field: CallListPreviewSortingFields): React.CSSProperties => {
        const sortingFieldName = tableFieldNameToSortingFieldName(field);
        return {
            fontWeight: order.field === sortingFieldName ? 'bold' : 'initial',
        };
    };
    const fields = useMemo(() => {
        const fields: string[] = [];
        if (filteredTags) {
            fields.push('tagIds');
        }
        if (filteredAdvocate) {
            fields.push('assignedHealthAdvocate');
        }
        return fields;
    }, [filteredTags, filteredAdvocate]);
    const values = useMemo(() => {
        const values: (string | null)[][] = [];
        if (filteredTags) {
            values.push(filteredTags?.split(',') || ['']);
        }
        if (filteredAdvocate) {
            if (filteredAdvocate === 'unassigned') {
                values.push([null]);
            } else {
                values.push([filteredAdvocate || '']);
            }
        }
        return values;
    }, [filteredTags, filteredAdvocate]);

    const patientsFilteredInput = {
        pagination: {
            skip: page * rowsPerPage,
            limit: rowsPerPage,
        },
        orderBy: {
            field: order.field,
            order: order.direction,
            fieldIsProfileDefId: order.fieldIsProfileDefId,
        },
        filter: { ...filter, ...filtersFromFilterModal } as PatientFilterFilterInput | null,
        fields,
        values,
        filterTestData,
    };

    const fetchPatientCallListForPreviewVariables: FetchPatientCallListForPreviewQueryVariables = {
        input: {
            id: callListId,
        },
        patientsToCallInput2: patientsFilteredInput,
    };
    const {
        data: callListPreviewPageData,
        loading: callListPreviewDataLoading,
        refetch: refetchPatientToCallList,
    } = useFetchPatientCallListForPreviewQuery({
        variables: fetchPatientCallListForPreviewVariables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });
    const [updatePatientCallListCallStatus, { loading: updatePatientCallListLoading }] =
        useUpdatePatientCallListCallStatusForPreviewMutation({
            onCompleted: data => {
                const success = data?.updatePatientCallListCallStatus?.success;
                const status = data?.updatePatientCallListCallStatus?.status;
                const message = data?.updatePatientCallListCallStatus?.message;
                if (!success || status !== 200) {
                    TriggerGlobalAlert({
                        message: `Error setting Patient Call List call status: ${JSON.stringify(
                            message,
                        )}`,
                        severity: AlertSeverity.Error,
                    });
                }
            },
        });

    const callStatusHandler = async (callListId: string, patientId: string, checked: boolean) => {
        seProcessingPatientId(patientId);
        await updatePatientCallListCallStatus({
            variables: {
                input: {
                    id: callListId,
                    called: checked,
                    patientId,
                },
            },
        });
        await refetchPatientToCallList(fetchPatientCallListForPreviewVariables);
        seProcessingPatientId(null);
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;
    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table passes empty array some times, which causes undefined error
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];
        const fieldName = columnIdToSortingFieldMap[orderBy.orderBy];
        const field = tableFieldNameToSortingFieldName(fieldName);
        let isInit = false;
        const fieldIsProfileDefId = false;
        if (field) {
            if (field !== order.field) {
                isInit = true;
            }
            const direction = getDirection(orderBy.orderDirection);
            const stateObject: ICallListPreviewOrderChange = {
                direction,
                field,
                isInit,
                fieldIsProfileDefId,
            };
            setOrder(stateObject);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const [rowIndex, setRowIndex] = useState<number>(0);
    const [menuShown, setMenuShown] = useState<boolean>(false);
    const [selectedPatient, setSelectedPatient] = useState<PatientToCall>();
    const [isPatientNotesModalOpen, setIsPatientNotesModalOpen] = useState<boolean>(false);
    // refs for table rows needed for "more" pup-up menu
    const rowRefs = useRef(new Map());
    const setRowRef = (index: number) => (element: any) => {
        rowRefs.current.set(index, element);
    };

    const [fetchCSVExport, { loading: csvExportLoading }] =
        useFetchPatientCallListPreviewExportToCsvLazyQuery({
            fetchPolicy: 'no-cache',
            onCompleted: data => {
                const csvContent = data?.PatientCallListPreviewExportToCSV?.data;
                const fileName = data?.PatientCallListPreviewExportToCSV?.fileName;
                if (csvContent) {
                    const parsedContent = csvContent.filter(c => c !== null) as string[];
                    const forBLob: ArrayLike<number> = new Uint8Array(
                        parsedContent.map(c => parseInt(c)),
                    );
                    const { buffer } = new Uint8Array(forBLob);
                    const blob = new Blob([buffer], { type: 'text/csv' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName ?? 'calllist.csv';
                    a.click();
                    a.remove();
                } else {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Warning,
                        message: 'Recieved empty response for csv documetn from the server.',
                    });
                }
            },
            onError: () => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'Failed to export Patient Call List.',
                });
            },
        });
    const handleDownloadPatientsCsv = () => {
        if (csvExportLoading) return;
        fetchCSVExport({
            variables: {
                input: {
                    patientsInput: patientsFilteredInput,
                    callListId,
                },
            },
        });
    };
    const goBackHandler = () => {
        navigate(-1);
    };
    return (
        <div className={classes.root} ref={tableRef}>
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        startIcon={<ArrowBack />}
                        className={classes.backButton}
                        onClick={goBackHandler}
                    >
                        Back to Patient Call List
                    </Button>
                </Grid>
            </Grid>
            <MaterialTable<PatientToCall>
                title={tableTitle}
                isLoading={callListPreviewDataLoading || usersToAssignLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        tooltip: 'Filter Patients',
                        onClick: () => {
                            setOpenFilterPatientsModal(true);
                        },
                        icon: () => (
                            <Badge
                                badgeContent={
                                    (localStorage
                                        .getItem('filteredTags')
                                        ?.split(',')
                                        .filter(str => str.length > 1).length as number) +
                                        (fields[0] === 'tagIds'
                                            ? values.length - 1
                                            : values.length) || 0
                                }
                                color="error"
                                style={{
                                    padding: '12px',
                                    backgroundColor: primaryColor,
                                    color: 'white',
                                    fontSize: '1rem',
                                    borderRadius: '1rem',
                                }}
                            >
                                Filter Patients
                            </Badge>
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: event => {
                            handleOpenHiddenColumnsModal(event);
                        },
                        tooltip: 'Filter Columns',
                        icon: () => <FilterList />,
                        isFreeAction: true,
                    },
                    {
                        onClick: (event: React.MouseEvent, patient) => {
                            const { currentTarget } = event;
                            const tr = currentTarget.closest('tr');
                            const index = tr?.getAttribute('index');
                            setRowIndex(parseInt(index || '0'));
                            setMenuShown(true);
                            setSelectedPatient(patient as PatientToCall);
                            event.stopPropagation();
                            event.preventDefault();
                        },
                        icon: () => <MoreVert />,
                    },
                    {
                        tooltip: 'Export CSV',
                        onClick: handleDownloadPatientsCsv,
                        icon: () =>
                            csvExportLoading ? <Loop className={classes.rotating} /> : <Download />,
                        isFreeAction: true,
                        hidden: !pagePermissions?.PatientCallLists.Export,
                    },
                ]}
                columns={[
                    {
                        title: 'Invitation Code',
                        field: 'patient.invitationCode',
                        align: 'center',
                        width: 32,
                        sorting: false,
                        hidden: hiddenColumns.invitationCode,
                    },
                    {
                        title: 'External Id',
                        field: 'patient.externalId',
                        align: 'center',
                        hidden: hiddenColumns.externalId,
                        render: ({ patient }) => {
                            const externalId = patient?.externalId;
                            if (externalId) {
                                return (
                                    <Tooltip title={externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${externalId}`);
                                                TriggerGlobalAlert({
                                                    message: 'External Id Copied to Clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    {
                        title: 'First Name',
                        field: 'patient.firstName',
                        align: 'center',
                        render: ({ patient }) => {
                            const id = patient?.id;
                            const firstName = patient?.firstName;
                            return (
                                <MuiLink
                                    target="_blank"
                                    to={`/portal/patients/${id}`}
                                    component={Link}
                                    className={classes.tasksButton}
                                    underline="hover"
                                >
                                    <Typography>{firstName}</Typography>
                                </MuiLink>
                            );
                        },
                        headerStyle: getHeaderStyle(CallListPreviewSortingFields.firstName),
                        hidden: hiddenColumns.firstName,
                    },
                    {
                        title: 'Last Name',
                        field: 'patient.lastName',
                        align: 'center',
                        render: ({ patient }) => {
                            const id = patient?.id;
                            const lastName = patient?.lastName;
                            return (
                                <MuiLink
                                    target="_blank"
                                    to={`/portal/patients/${id}`}
                                    component={Link}
                                    className={classes.tasksButton}
                                    underline="hover"
                                >
                                    <Typography>{lastName}</Typography>
                                </MuiLink>
                            );
                        },
                        headerStyle: getHeaderStyle(CallListPreviewSortingFields.lastName),
                        hidden: hiddenColumns.lastName,
                    },
                    {
                        title: 'Created',
                        render: ({ patient }) => {
                            const createdAt = patient?.createdAt;
                            return (
                                <Typography>
                                    {createdAt && moment.utc(createdAt).format('MM/DD/yyyy')}
                                </Typography>
                            );
                        },
                        customSort: (a, b) =>
                            new Date(a.patient?.createdAt).getTime() -
                            new Date(b.patient?.createdAt).getTime(),
                        headerStyle: getHeaderStyle(CallListPreviewSortingFields.createdAt),
                        hidden: hiddenColumns.createdAt,
                    },
                    {
                        title: 'Called?',
                        field: 'called',
                        render: ({ called, patient }) => {
                            const isCurrentPatient = processingPatientId === patient?.id;
                            if (updatePatientCallListLoading && isCurrentPatient) {
                                return <CircularProgress color="primary" />;
                            }
                            return (
                                <Checkbox
                                    onChange={async e => {
                                        if (!patient?.id || !callListId) return;
                                        await callStatusHandler(
                                            callListId,
                                            patient.id,
                                            e.target.checked,
                                        );
                                    }}
                                    checked={called ?? false}
                                    disabled={!pagePermissions?.PatientCallLists.Edit}
                                />
                            );
                        },
                    },
                    {
                        title: 'Assigned Health Advocate',
                        field: 'patient.assignedHealthAdvocate',
                        align: 'center',
                        render: ({ patient }) => (
                            <Typography>
                                {
                                    usersForAssignedHealthAdvocateList?.find(
                                        user => user.id === patient?.assignedHealthAdvocate,
                                    )?.name
                                }
                            </Typography>
                        ),
                        headerStyle: getHeaderStyle(
                            CallListPreviewSortingFields.assignedHealthAdvocate,
                        ),
                        hidden: hiddenColumns.assignedHealthAdvocate,
                    },
                    {
                        title: 'Open Tasks',
                        field: 'patient.openAdvocateTaskCount',
                        disableClick: !pagePermissions?.HealthAdvocate.Read,
                        render: ({ patient }) => {
                            const openAdvocateTaskCount = patient?.openAdvocateTaskCount;
                            const id = patient?.id;
                            return (
                                <MuiLink
                                    to={`/portal/patients/${id}/ha-tasks`}
                                    component={Link}
                                    className={classes.tasksButton}
                                    underline="hover"
                                >
                                    <Typography>{openAdvocateTaskCount}</Typography>
                                </MuiLink>
                            );
                        },
                        hidden: hiddenColumns.openAdvocateTaskCount,
                        sorting: false,
                    },
                    {
                        title: 'Open Critical Tasks',
                        field: 'patient.openCriticalAdvocateTaskCount',
                        disableClick: !pagePermissions?.HealthAdvocate.Read,
                        render: ({ patient }) => {
                            const openCriticalAdvocateTaskCount =
                                patient?.openCriticalAdvocateTaskCount;
                            const id = patient?.id;
                            return (
                                <MuiLink
                                    to={`/portal/patients/${id}/ha-tasks`}
                                    component={Link}
                                    className={classes.tasksButton}
                                    underline="hover"
                                >
                                    <Typography>{openCriticalAdvocateTaskCount}</Typography>
                                </MuiLink>
                            );
                        },
                        hidden: hiddenColumns.openCriticalAdvocateTaskCount,
                        sorting: false,
                    },

                    {
                        title: 'App Registration Complete',
                        field: 'patient.onboardingDate',
                        render: ({ patient }) => {
                            const onboardingDate = patient?.onboardingDate;
                            return (
                                <Typography>
                                    {onboardingDate &&
                                        moment.utc(onboardingDate).format('MM/DD/yyyy')}
                                </Typography>
                            );
                        },
                        customSort: (a, b) =>
                            new Date(a.patient?.onboardingDate ?? '').getTime() -
                            new Date(b.patient?.onboardingDate ?? '').getTime(),
                        sorting: false,
                        hidden: hiddenColumns.appRegistrationCompleted,
                    },
                    {
                        title: 'Call Status',
                        width: 32,
                        sorting: false,
                        align: 'center',
                        field: 'patient.patientCallStatus',
                        hidden: hiddenColumns.patientCallStatus,
                        render: ({ patient }) => {
                            const id = patient?.id;
                            const patientCallStatus = patient?.patientCallStatus;
                            const appBundleId = patient?.appBundleId;

                            return (
                                <PatientProfileVariableLink
                                    patientId={id}
                                    appBundleId={appBundleId || ''}
                                    profileVariableDefId={UserProfileDefsEnum.CALL_STATUS}
                                >
                                    {updatedPatientCallStatus => (
                                        <Typography>
                                            {updatedPatientCallStatus ?? patientCallStatus}
                                        </Typography>
                                    )}
                                </PatientProfileVariableLink>
                            );
                        },
                    },
                    {
                        title: 'Birth Date',
                        field: 'patient.birthDate',
                        render: ({ patient }) => {
                            const id = patient?.id;
                            const birthDate = patient?.birthDate;
                            const appBundleId = patient?.appBundleId;
                            return (
                                <PatientProfileVariableLink
                                    patientId={id}
                                    appBundleId={appBundleId || ''}
                                    profileVariableDefId={UserProfileDefsEnum.BIRTH_DATE}
                                >
                                    {updatedBirthDate => (
                                        <Typography>
                                            {(updatedBirthDate ?? birthDate) &&
                                                format(
                                                    // dates are recieved as UTC ISO strings by default, we need to screen out the timestamp to avoid recalculation
                                                    toDate(
                                                        (updatedBirthDate ?? birthDate).split(
                                                            'T',
                                                        )[0],
                                                    ),
                                                    'MM/dd/yyyy',
                                                )}
                                        </Typography>
                                    )}
                                </PatientProfileVariableLink>
                            );
                        },
                        customSort: (a, b) =>
                            new Date(a.patient?.birthDate ?? '').getTime() -
                            new Date(b.patient?.birthDate ?? '').getTime(),
                        headerStyle: getHeaderStyle(CallListPreviewSortingFields.birthDate),
                        hidden: hiddenColumns.birthDate,
                    },
                    {
                        title: 'Practice',
                        field: 'patient.practice.name',
                        sorting: false,
                        render: ({ patient }) => {
                            const id = patient?.id;
                            const practice = patient?.practice;
                            return (
                                <MuiLink
                                    to={`/portal/patients/${id}/care-team`}
                                    component={Link}
                                    className={classes.tasksButton}
                                    underline="hover"
                                >
                                    <Typography>{practice?.name}</Typography>
                                </MuiLink>
                            );
                        },
                        hidden: hiddenColumns.practice,
                    },
                    {
                        title: 'Onboarding Completed',
                        field: 'patient.onboardingCompleteDate',
                        render: ({ patient }) => {
                            const onboardingCompleteDate = patient?.onboardingCompleteDate;
                            return (
                                <Typography>
                                    {onboardingCompleteDate &&
                                        moment.utc(onboardingCompleteDate).format('MM/DD/yyyy')}
                                </Typography>
                            );
                        },
                        customSort: (a, b) =>
                            new Date(a.patient?.onboardingCompleteDate).getTime() -
                            new Date(b.patient?.onboardingCompleteDate).getTime(),
                        hidden: hiddenColumns.onboardingCompleteDate,
                        headerStyle: getHeaderStyle(
                            CallListPreviewSortingFields.onboardingCompleteDate,
                        ),
                    },
                    {
                        title: 'Is Pregnant',
                        field: 'patient.isPregnant',
                        render: ({ patient }) => {
                            const isPregnant = patient?.isPregnant;
                            const id = patient?.id;
                            const appBundleId = patient?.appBundleId;
                            return (
                                <PatientProfileVariableLink
                                    patientId={id}
                                    appBundleId={appBundleId || ''}
                                    profileVariableDefId={UserProfileDefsEnum.IS_PREGNANT}
                                >
                                    {updatedIsPregnant => (
                                        <Typography>
                                            {updatedIsPregnant ?? isPregnant ? 'Yes' : 'No'}
                                        </Typography>
                                    )}
                                </PatientProfileVariableLink>
                            );
                        },
                        customSort: (a, b) =>
                            (a.patient?.isPregnant ? 1 : 0) - (b.patient?.isPregnant ? 1 : 0),
                        hidden: hiddenColumns.isPregnant,
                        headerStyle: getHeaderStyle(CallListPreviewSortingFields.isPregnant),
                    },
                    {
                        title: 'Due Date',
                        field: 'patient.dueDate',
                        render: ({ patient }) => {
                            const id = patient?.id;
                            const dueDate = patient?.dueDate;
                            const appBundleId = patient?.appBundleId;
                            return (
                                <PatientProfileVariableLink
                                    patientId={id}
                                    appBundleId={appBundleId || ''}
                                    profileVariableDefId={UserProfileDefsEnum.DUE_DATE}
                                >
                                    {updatedDueDate => {
                                        const dateValue = updatedDueDate ?? dueDate;
                                        let formattedDateString = '';
                                        if (isString(dateValue)) {
                                            formattedDateString = toFormattedDateString(dateValue);
                                        }
                                        return <Typography>{formattedDateString}</Typography>;
                                    }}
                                </PatientProfileVariableLink>
                            );
                        },
                        customSort: (a, b) =>
                            new Date(a.patient?.dueDate ?? '').getTime() -
                            new Date(b.patient?.dueDate ?? '').getTime(),
                        hidden: hiddenColumns.dueDate,
                        headerStyle: getHeaderStyle(CallListPreviewSortingFields.dueDate),
                    },
                    {
                        title: 'Delivery Date',
                        field: 'patient.deliveryDate',
                        render: ({ patient }) => {
                            const id = patient?.id;
                            const deliveryDate = patient?.deliveryDate;
                            const appBundleId = patient?.appBundleId;
                            return (
                                <PatientProfileVariableLink
                                    patientId={id}
                                    appBundleId={appBundleId || ''}
                                    profileVariableDefId={UserProfileDefsEnum.DELIVERY_DATE}
                                >
                                    {updatedDeliveryDate => {
                                        const dateValue = updatedDeliveryDate ?? deliveryDate;
                                        let formattedDateString = '';
                                        if (isString(dateValue)) {
                                            formattedDateString = toFormattedDateString(dateValue);
                                        }
                                        return <Typography>{formattedDateString}</Typography>;
                                    }}
                                </PatientProfileVariableLink>
                            );
                        },
                        customSort: (a, b) =>
                            new Date(a.patient?.deliveryDate ?? '').getTime() -
                            new Date(b.patient?.deliveryDate ?? '').getTime(),
                        hidden: hiddenColumns.deliveryDate,
                        headerStyle: getHeaderStyle(CallListPreviewSortingFields.deliveryDate),
                    },
                    {
                        title: 'Email',
                        field: 'patient.email',
                        hidden: hiddenColumns.email,
                    },
                    {
                        title: 'Pateint ID',
                        field: 'patient.id',
                        width: 32,
                        sorting: false,
                        align: 'center',
                        hidden: hiddenColumns.id,
                    },
                ]}
                data={
                    (callListPreviewPageData?.patientCallList?.patientsToCall
                        ?.results as PatientToCall[]) || []
                }
                onOrderCollectionChange={handleOrderChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={
                                callListPreviewPageData?.patientCallList?.patientsToCall?.total ?? 0
                            }
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                    Row: props => {
                        const { index } = props;
                        // because of the table rows are dynamically rendered, we can refer them only by useRef
                        return <MTableBodyRow ref={setRowRef(index)} {...props} />;
                    },
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    exportAllData: pagePermissions?.PatientDetails.Export,
                    search: false,
                    paginationPosition: 'both',
                }}
            />
            <FilterColumnsModal
                hiddenColumnsAnchorEl={hiddenColumnsAnchorEl}
                setHiddenColumnsAnchorEl={setHiddenColumnsAnchorEl}
            />
            <FilterPatientsModal
                open={openFilterPatientsModal}
                onClose={() => setOpenFilterPatientsModal(false)}
            />
            {menuShown && (
                <Menu
                    id="calllist-drop-down"
                    anchorEl={() => {
                        // because of the table rows are dynamically rendered, we can refer them only by useRef
                        return rowRefs.current.get(rowIndex);
                    }}
                    open={menuShown}
                    onClose={() => {
                        setMenuShown(false);
                    }}
                >
                    {pagePermissions?.PatientNotes.Read && (
                        <MenuItem
                            onClick={() => {
                                setIsPatientNotesModalOpen(true);
                                setMenuShown(false);
                            }}
                        >
                            Add Notes
                        </MenuItem>
                    )}
                </Menu>
            )}

            {isPatientNotesModalOpen && (
                <PatientNotesModal
                    handleClose={() => setIsPatientNotesModalOpen(false)}
                    isModalOpen={isPatientNotesModalOpen}
                    patientId={selectedPatient?.patient?.id}
                />
            )}
        </div>
    );
};

export default PatientCallListPreview;
