/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {
    Autocomplete,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
    Divider,
} from '@mui/material';
import { Save as SaveIcon, LaunchRounded } from '@mui/icons-material';
import { useUser } from '~/hooks';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import Mappers from '~/helpers/mappers';
import {
    FetchProfileDefsForProfileDefsPageQeryDocument,
    HaPermission,
    useConditionTypeListQuery,
    useCreateUserProfileDefinitionFromModalMutation,
    useFetchUserProfileDefinitionByIdLazyQuery,
    UserProfileValueType,
    useUpdateUserProfileDefinitionFromModalMutation,
    usePreFetchAppFullNamesQuery,
    useJiraInfoByUrlLazyQuery,
    UserProfileDefHistoryItem,
    LocalizedString,
} from '~/schemaTypes';
import { ProfileDefEnum } from '~/selectors';
import ProfileDefsChoicesForm, {
    CurrentChoiceValueType,
} from './ProfileDefsChoicesForm/ProfileDefsChoicesForm';
import { createHistory } from '../ProfileDefHistoryModal/createHistory';
import { PROFILE_DEFINITION_VALIDATION_SCHEMA } from './types';

export interface ProfileDefinitionFormInput {
    name: string;
    description: string;
    reportingNotes?: string;
    inboundNotes?: string;
    category: string;
    valueType: UserProfileValueType;
    label: {
        en: string;
        es?: string;
    };
    questionLabel: {
        en?: string;
        es?: string;
    };
    choices: any; // FetchUserProfileDefinitionById_userProfileDef_choices[];
    choiceValueType?: string;
    isClientSpecific: boolean;
    purposeAppPersonalization: boolean;
    purposeHumanServices: boolean;
    linkToJIRA?: string;
    clientSpecificDisplayName?: string;
    dataSourceNotes?: string;
    purposeDetails?: string;
    dwLocation?: string;
    dwObject?: string;
    dwMetrics?: string;
    dwInternalReports?: string;
    dwExternalReports?: string;
    claimsField?: string;
    icd10DiagnosisCodes?: string;
    procedureCodes?: string;
    history: UserProfileDefHistoryItem[];
}

type ProfileDefsModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditProfileDefId: Dispatch<SetStateAction<string>>;
    id?: string | undefined;
};

const ProfileDefsModal: React.FC<ProfileDefsModalProps> = ({
    setOpen,
    setEditProfileDefId,
    id,
}) => {
    const [currentChoiceValueType, setCurrentChoiceValueType] =
        useState<UserProfileValueType | null>(UserProfileValueType.Str);
    const [episodeRequired, setEpisodeRequired] = useState<boolean>(false);
    const [questionDisabled, setQuestionDiabled] = useState<boolean>(false);
    const [haPermission, setHaPermission] = useState(HaPermission.Display);
    const [isClientSpecific, setIsClientSpecific] = useState<boolean>(false);
    const [purposeAppPersonalization, setPurposeAppPersonalization] = useState<boolean>(false);
    const [purposeHumanServices, setPurposeHumanServices] = useState<boolean>(false);
    const [linkToJIRA, setLinkToJIRA] = useState<string>('');

    const [jiraDateRequested, setJiraDateRequested] = useState<string>('');
    const [jiraDateCompleted, setJiraDateCompleted] = useState<string>('');
    const [jiraAssignee, setJiraAssignee] = useState<string>('');
    const [jiraRequestor, setJiraRequestor] = useState<string>('');
    const [jiraDateExpected, setJiraDateExpected] = useState<string>('');
    const [jiraStatus, setJiraStatus] = useState<string>('');
    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<ProfileDefinitionFormInput>({
        // @ts-expect-error RHF V7 limitation #7895 will be fixed in V8 of react-hook-form
        resolver: yupResolver(PROFILE_DEFINITION_VALIDATION_SCHEMA),
    });
    const [valueType, setValueType] = useState<UserProfileValueType | null>(null);
    const [selectedConditionTypeId, setSelectedConditionTypeId] = useState<string | null>('');
    const [selectedClientSpecificDisplayName, setClientSpecificDisplayName] = useState<string>('');

    type ConditionType = {
        id: any;
        name: LocalizedString;
    };

    const [conditionTypeList, setConditionTypeList] = useState<ConditionType[]>([]);
    const { loading: conditionTypesLoading } = useConditionTypeListQuery({
        onCompleted: data => {
            const conditionTypes = [...data.conditionTypes];
            conditionTypes.sort((conditionA, conditionB) => {
                if (conditionA.name.en > conditionB.name.en) return 1;
                if (conditionA.name.en < conditionB.name.en) return -1;
                return 0;
            });
            setConditionTypeList(conditionTypes);
        },
    });

    const [updateUserProfileDef, { loading: updateUserProfileDefLoading }] =
        useUpdateUserProfileDefinitionFromModalMutation({
            onCompleted: data => {
                if (data.updateUserProfileDef?.success) {
                    setOpen(false);
                    setEditProfileDefId('');
                }
            },
        });

    const [createUserProfileDef, { loading: createUserProfileDefLoading }] =
        useCreateUserProfileDefinitionFromModalMutation({
            onCompleted: data => {
                if (data.createUserProfileDef?.success) {
                    setOpen(false);
                }
            },
            refetchQueries: [{ query: FetchProfileDefsForProfileDefsPageQeryDocument }],
        });

    const [jiraFields] = useJiraInfoByUrlLazyQuery({
        onCompleted: data => {
            if (data?.jiraInfoByUrl) {
                setJiraDateRequested(
                    moment(data?.jiraInfoByUrl?.dateRequested).local().format('MM/DD/YYYY h:mm a'),
                );
                setJiraDateCompleted(
                    data?.jiraInfoByUrl?.dateCompleted
                        ? moment(data?.jiraInfoByUrl?.dateCompleted)
                              .local()
                              .format('MM/DD/YYYY h:mm a')
                        : '',
                );
                setJiraDateExpected(
                    data?.jiraInfoByUrl?.dateExpected
                        ? moment(data?.jiraInfoByUrl?.dateExpected).format('MM/DD/YYYY')
                        : '',
                );
                setJiraAssignee(data?.jiraInfoByUrl?.assignee ?? '');
                setJiraRequestor(data?.jiraInfoByUrl?.requestor);
                setJiraStatus(data?.jiraInfoByUrl?.requestStatus);
            }
        },
    });

    const [fullAppNames, setFullAppNames] = useState<string[]>([]);
    const { loading: fullAppNamesLoading } = usePreFetchAppFullNamesQuery({
        onCompleted: data => {
            const appNames = data?.amsApps.map(app => app.fullName);
            if (appNames?.length > 0) {
                appNames.sort();
            }
            setFullAppNames(appNames);
        },
    });
    const { data: userData } = useUser();

    const [
        getProfileDefinition,
        { data: profileDefinitionData, loading: profileDefinitionLoading },
    ] = useFetchUserProfileDefinitionByIdLazyQuery({
        onCompleted: ({ userProfileDef }) => {
            userProfileDef = Mappers.excludeTypeName(userProfileDef);
            if (userProfileDef) {
                const {
                    name,
                    description,
                    category,
                    valueType,
                    label,
                    questionLabel,
                    choiceValueType,
                    choices,
                    surveyQuestionDisabled,
                    conditionTypeId,
                    episodeRequired,
                    reportingNotes,
                    inboundNotes,
                    haPermission,
                    isClientSpecific,
                    linkToJIRA,
                    purposeAppPersonalization,
                    purposeHumanServices,
                    clientSpecificDisplayName,
                    dataSourceNotes,
                    purposeDetails,
                    dwLocation,
                    dwObject,
                    dwMetrics,
                    dwInternalReports,
                    dwExternalReports,
                    claimsField,
                    icd10DiagnosisCodes,
                    procedureCodes,
                } = userProfileDef;
                if (linkToJIRA) {
                    jiraFields({
                        variables: {
                            input: { url: linkToJIRA },
                        },
                    });
                }
                reset({
                    name: name ?? '',
                    description: description ?? '',
                    category: category ?? '',
                    valueType: valueType ?? Object.values(UserProfileValueType)[0],
                    label: {
                        en: label?.en ?? '',
                        es: label?.es ?? '',
                    },
                    questionLabel: {
                        en: questionLabel?.en ?? '',
                        es: questionLabel?.es ?? '',
                    },
                    choiceValueType: choiceValueType ?? undefined,
                    choices: choices ?? [],
                    reportingNotes: reportingNotes ?? '',
                    inboundNotes: inboundNotes ?? '',
                    dataSourceNotes: dataSourceNotes ?? '',
                    purposeDetails: purposeDetails ?? '',
                    dwLocation: dwLocation ?? '',
                    dwObject: dwObject ?? '',
                    dwMetrics: dwMetrics ?? '',
                    dwInternalReports: dwInternalReports ?? '',
                    dwExternalReports: dwExternalReports ?? '',
                    claimsField: claimsField ?? '',
                    icd10DiagnosisCodes: icd10DiagnosisCodes ?? '',
                    procedureCodes: procedureCodes ?? '',
                });
                setValueType(valueType);
                setCurrentChoiceValueType(choiceValueType ?? null);
                setSelectedConditionTypeId(conditionTypeId);
                setEpisodeRequired(episodeRequired ?? false);
                setQuestionDiabled(surveyQuestionDisabled ?? false);
                setIsClientSpecific(isClientSpecific ?? false);
                setPurposeAppPersonalization(purposeAppPersonalization ?? false);
                setPurposeHumanServices(purposeHumanServices ?? false);
                setLinkToJIRA(linkToJIRA ?? '');
                if (isClientSpecific) setClientSpecificDisplayName(clientSpecificDisplayName ?? '');
                if (haPermission) setHaPermission(haPermission);
            }
        },
        fetchPolicy: 'network-only',
    });

    const initialProfileDefinitionState = Mappers.excludeTypeName(
        profileDefinitionData?.userProfileDef,
    );

    const onSubmit = (userProfileDef: any) => {
        const profileDefInput = { ...userProfileDef };
        const { valueType } = userProfileDef;
        const hasChoiceValueType =
            valueType === UserProfileValueType.Choice || valueType === UserProfileValueType.Choices;

        if (!hasChoiceValueType) {
            profileDefInput.choiceValueType = undefined;
            profileDefInput.choices = [];
        }

        if (hasChoiceValueType && profileDefInput.choiceValueType == null) {
            profileDefInput.choiceValueType = UserProfileValueType.Str;
        }

        if (id) {
            const updateData = {
                ...profileDefInput,
                systemGenerated: false,
                conditionTypeId: selectedConditionTypeId === '' ? null : selectedConditionTypeId,
                episodeRequired: selectedConditionTypeId === '' ? false : episodeRequired,
                surveyQuestionDisabled: questionDisabled,
                haPermission,
                isClientSpecific,
                purposeAppPersonalization,
                purposeHumanServices,
                linkToJIRA,
                clientSpecificDisplayName: isClientSpecific
                    ? selectedClientSpecificDisplayName
                    : '',
            };

            const editHistory = createHistory(updateData, userData, initialProfileDefinitionState);

            updateUserProfileDef({
                variables: {
                    input: {
                        id,
                        data: {
                            ...updateData,
                            history: [...editHistory, ...initialProfileDefinitionState.history],
                        },
                    },
                },
            });
        } else {
            const data = {
                ...profileDefInput,
                systemGenerated: false,
                conditionTypeId: selectedConditionTypeId === '' ? null : selectedConditionTypeId,
                episodeRequired: selectedConditionTypeId === '' ? false : episodeRequired,
                surveyQuestionDisabled: questionDisabled,
                haPermission,
                isClientSpecific,
                purposeAppPersonalization,
                purposeHumanServices,
                linkToJIRA,
                clientSpecificDisplayName: isClientSpecific
                    ? selectedClientSpecificDisplayName
                    : '',
            };
            const history = createHistory(data, userData);
            createUserProfileDef({
                variables: {
                    input: {
                        ...data,
                        history,
                    },
                },
            });
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEditProfileDefId('');
    };

    useEffect(() => {
        if (id) getProfileDefinition({ variables: { input: { id } } });
    }, [id, getProfileDefinition]);

    const conditionTypeChanged = (event: any) => {
        setSelectedConditionTypeId(event.target.value);
    };
    const episodeRequiredChanged = () => {
        setEpisodeRequired(!episodeRequired);
    };
    const questionDisabledChanged = () => {
        setQuestionDiabled(!questionDisabled);
    };
    const clientSpecificChanged = () => {
        setIsClientSpecific(!isClientSpecific);
    };
    const purposeAppPersonalizationChanged = () => {
        setPurposeAppPersonalization(!purposeAppPersonalization);
    };
    const purposeHumanServicesChanged = () => {
        setPurposeHumanServices(!purposeHumanServices);
    };
    const clientSpecificDisplayNameChanged = (event: any) => {
        setClientSpecificDisplayName(event.target.value);
    };
    const linkToJIRAChanged = (event: any) => {
        setLinkToJIRA(event.target.value);
    };
    if (
        profileDefinitionLoading ||
        updateUserProfileDefLoading ||
        createUserProfileDefLoading ||
        conditionTypesLoading ||
        fullAppNamesLoading
    )
        return <Loading />;
    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)} data-test={ProfileDefEnum.MODAL}>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                {profileDefinitionData?.userProfileDef?.id === undefined
                    ? 'New Profile Definition'
                    : 'Edit Profile Definition'}
            </DialogTitleWithClose>
            <DialogContent className="formCont">
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Name"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('name')}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            data-test={ProfileDefEnum.MODAL_NAME_INPUT}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Description"
                            multiline
                            maxRows={4}
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('description')}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                            data-test={ProfileDefEnum.MODAL_DESCRIPTION_TEXTAREA}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Reporting Notes"
                            multiline
                            maxRows={4}
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('reportingNotes')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Inbound Notes"
                            multiline
                            maxRows={4}
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('inboundNotes')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            label="Category"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('category')}
                            error={!!errors.category}
                            helperText={errors.category?.message}
                            data-test={ProfileDefEnum.MODAL_CATEGORY_INPUT}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="valueType"
                            render={({ field: { onChange } }) => (
                                <Autocomplete
                                    size="small"
                                    onChange={(_, val) => {
                                        onChange(val);
                                        setValueType(val);
                                        setCurrentChoiceValueType(null);
                                    }}
                                    getOptionLabel={selected => selected}
                                    fullWidth
                                    options={Object.values(UserProfileValueType)}
                                    defaultValue={profileDefinitionData?.userProfileDef?.valueType}
                                    isOptionEqualToValue={(option, val) => option === val}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder="Select Value Type"
                                            error={!!errors.valueType}
                                            helperText={errors.valueType?.message}
                                            label="Value Type"
                                            InputLabelProps={{ shrink: true }}
                                            data-test={ProfileDefEnum.MODAL_VALUE_TYPE_DROPDOWN}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label="Do not include in Survey Question list"
                            labelPlacement="start"
                            control={
                                <Checkbox
                                    checked={questionDisabled}
                                    onChange={questionDisabledChanged}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label="Health Advocate Permission"
                            labelPlacement="start"
                            control={
                                <select
                                    style={{ margin: '10px' }}
                                    value={haPermission}
                                    onChange={e => setHaPermission(e.target.value as HaPermission)}
                                >
                                    <option value={HaPermission.Hide}>Hide</option>
                                    <option value={HaPermission.Display}>Display</option>{' '}
                                    <option value={HaPermission.Edit}>Edit</option>
                                </select>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedSection title="Condition (optional)">
                            <div>
                                <select
                                    onChange={conditionTypeChanged}
                                    value={selectedConditionTypeId || ''}
                                >
                                    <option key="" value="">
                                        None
                                    </option>
                                    {conditionTypeList &&
                                        conditionTypeList.map(t => (
                                            <option key={t.id} value={t.id}>
                                                {t.name.en}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            {selectedConditionTypeId && selectedConditionTypeId !== '' && (
                                <FormControlLabel
                                    label="Episode is Required?"
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            checked={episodeRequired}
                                            onChange={episodeRequiredChanged}
                                        />
                                    }
                                />
                            )}
                        </OutlinedSection>
                    </Grid>
                    <Grid item xs={6}>
                        <OutlinedSection title="Label">
                            <TextField
                                variant="outlined"
                                {...register('label.en')}
                                label="English"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.label?.en}
                                helperText={errors.label?.en?.message}
                                data-test={ProfileDefEnum.MODAL_LABEL_EN_INPUT}
                            />
                            <TextField
                                variant="outlined"
                                label="Spanish"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('label.es')}
                                error={!!errors.label?.es}
                                helperText={errors.label?.es?.message}
                            />
                        </OutlinedSection>
                    </Grid>
                    <Grid item xs={6}>
                        <OutlinedSection title="Question Label">
                            <TextField
                                variant="outlined"
                                label="English"
                                {...register('questionLabel.en')}
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.questionLabel?.en}
                                helperText={errors.questionLabel?.en?.message}
                                data-test={ProfileDefEnum.MODAL_QUESTION_LABEL_EN_INPUT}
                            />
                            <TextField
                                variant="outlined"
                                label="Spanish"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('questionLabel.es')}
                                error={!!errors.questionLabel?.es}
                                helperText={errors.questionLabel?.es?.message}
                            />
                        </OutlinedSection>
                    </Grid>
                    {(valueType === UserProfileValueType.Choice ||
                        valueType === UserProfileValueType.Choices) && (
                        <>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="choiceValueType"
                                    render={({ field: { onChange } }) => (
                                        <Autocomplete
                                            size="small"
                                            onChange={(_, type) => {
                                                onChange(type);
                                                setCurrentChoiceValueType(type);
                                            }}
                                            getOptionLabel={selected => selected}
                                            fullWidth
                                            defaultValue={
                                                profileDefinitionData?.userProfileDef
                                                    ?.choiceValueType ||
                                                currentChoiceValueType ||
                                                UserProfileValueType.Str
                                            }
                                            options={[
                                                UserProfileValueType.Str,
                                                UserProfileValueType.Num,
                                                UserProfileValueType.Bool,
                                                UserProfileValueType.Date,
                                            ]}
                                            disableClearable
                                            isOptionEqualToValue={(option, val) => option === val}
                                            renderInput={params => (
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...params}
                                                    placeholder="Choice Value Type"
                                                    error={!!errors.choiceValueType}
                                                    helperText={errors.choiceValueType?.message}
                                                    label="Choice Value Type"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ margin: '10px' }}>
                                {valueType === UserProfileValueType.Choice ? (
                                    <Typography variant="h6">Choice</Typography>
                                ) : (
                                    <Typography variant="h6">Choices</Typography>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <ProfileDefsChoicesForm
                                    currentChoiceValueType={
                                        currentChoiceValueType as CurrentChoiceValueType
                                    }
                                    choices={Mappers.excludeTypeName(
                                        profileDefinitionData?.userProfileDef?.choices,
                                    )}
                                    formMethods={{ register, setValue }}
                                    name="choices"
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Is Client Specific"
                            labelPlacement="start"
                            {...register('isClientSpecific')}
                            control={
                                <Checkbox
                                    checked={isClientSpecific}
                                    onChange={clientSpecificChanged}
                                />
                            }
                        />
                        <select
                            {...register('clientSpecificDisplayName')}
                            onChange={clientSpecificDisplayNameChanged}
                            value={selectedClientSpecificDisplayName}
                            style={{ margin: '20px' }}
                        >
                            <option key="" value="">
                                None
                            </option>
                            {fullAppNames.length > 0 &&
                                fullAppNames.map(app => (
                                    <option key={app} value={app}>
                                        {app}
                                    </option>
                                ))}
                        </select>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Link To JIRA"
                            fullWidth
                            type="text"
                            margin="dense"
                            size="small"
                            value={linkToJIRA}
                            onChange={linkToJIRAChanged}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                window.open(linkToJIRA, '_blank');
                                            }}
                                            edge="end"
                                        >
                                            <LaunchRounded />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            label="Date Requested"
                            fullWidth
                            type="text"
                            size="small"
                            value={jiraDateRequested}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Requestor"
                            fullWidth
                            type="text"
                            size="small"
                            value={jiraRequestor}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Date Expected"
                            fullWidth
                            type="text"
                            size="small"
                            value={jiraDateExpected}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            label="Request Status"
                            fullWidth
                            type="text"
                            size="small"
                            value={jiraStatus}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Assignee"
                            fullWidth
                            type="text"
                            size="small"
                            value={jiraAssignee}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Date completed"
                            fullWidth
                            type="text"
                            size="small"
                            value={jiraDateCompleted}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Data Source Notes"
                            {...register('dataSourceNotes')}
                            multiline
                            rows={4}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '10px' }}>
                        <Typography variant="h6" className="title">
                            Purpose
                        </Typography>
                        <Divider style={{ marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="App Personalization"
                            labelPlacement="start"
                            {...register('purposeAppPersonalization')}
                            control={
                                <Checkbox
                                    checked={purposeAppPersonalization}
                                    onChange={purposeAppPersonalizationChanged}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Human Services"
                            labelPlacement="start"
                            {...register('purposeHumanServices')}
                            control={
                                <Checkbox
                                    checked={purposeHumanServices}
                                    onChange={purposeHumanServicesChanged}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Details"
                            multiline
                            {...register('purposeDetails')}
                            rows={4}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '10px' }}>
                        <Typography variant="h6" className="title">
                            Data Warehousing & Analytics
                        </Typography>
                        <Divider style={{ marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="DW Location (Table)"
                            {...register('dwLocation')}
                            fullWidth
                            type="text"
                            margin="dense"
                            size="small"
                        />
                        <TextField
                            variant="outlined"
                            label="DW Object/Field"
                            {...register('dwObject')}
                            fullWidth
                            type="text"
                            margin="dense"
                            size="small"
                        />
                        <TextField
                            variant="outlined"
                            label="Metrics"
                            {...register('dwMetrics')}
                            multiline
                            rows={4}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="Internal Reports"
                            {...register('dwInternalReports')}
                            multiline
                            rows={4}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="External Reports"
                            {...register('dwExternalReports')}
                            multiline
                            rows={4}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} style={{ margin: '10px' }}>
                        <Typography variant="h6" className="title">
                            Medical Conditions
                        </Typography>
                        <Divider style={{ marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item style={{ marginLeft: '10px' }}>
                            <Typography variant="body1" className="title">
                                Associated Condition
                            </Typography>
                            <select
                                onChange={conditionTypeChanged}
                                value={selectedConditionTypeId || ''}
                            >
                                <option key="" value="">
                                    None
                                </option>
                                {conditionTypeList &&
                                    conditionTypeList.map(t => (
                                        <option key={t.id} value={t.id}>
                                            {t.name.en}
                                        </option>
                                    ))}
                            </select>
                        </Grid>
                        <TextField
                            variant="outlined"
                            label="Claims Field"
                            {...register('claimsField')}
                            multiline
                            rows={4}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="ICD-10 Diagnosis Codes"
                            {...register('icd10DiagnosisCodes')}
                            multiline
                            rows={4}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            label="Procedures Codes"
                            {...register('procedureCodes')}
                            multiline
                            rows={4}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default ProfileDefsModal;
