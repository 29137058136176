import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { signIn, fetchAuthSession, signOut } from '@aws-amplify/auth';
import { AccessToken, PersistUserCredentials } from '~/state';
import { reInitSignoutWorker } from '~/workers/signOutWorker';
import { useFetchCurrentUserLoginLazyQuery } from '~/schemaTypes';
import { LoginEnum } from '~/selectors/login.selector';
import { makeStyles } from 'tss-react/mui';
import { Alert } from '@mui/material';
import { useInactiveLoginCthx } from './InactiveLoginWrapper';
import { LOGIN_VALIDATION_SCHEMA, LoginFormInput } from './Login';
import { EmailHelpLink } from './EmailHelpLink';

interface InactiveLoginProps {
    children: React.ReactElement<any, any> | null;
}

const useStyles = makeStyles()(() => ({
    inputCont: {
        padding: 20,
        minHeight: 200,
    },
    bottomControl: {
        paddingRight: 20,
        paddingBottom: 20,
        '& > button': {
            '&:not(:last-child)': {
                marginRight: 10,
            },
        },
    },
}));

const InactiveLogin: React.FC<InactiveLoginProps> = ({ children }) => {
    const { isTokenExpired, setIsTokenExpired } = useInactiveLoginCthx();
    const [fetchUser, { loading: userLoading }] = useFetchCurrentUserLoginLazyQuery();
    const { classes } = useStyles();
    const [shouldShow, setShouldShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const expireAccessTokenCallback = useCallback(() => {
        setIsTokenExpired(true);
        signOut();
    }, [setIsTokenExpired]);
    useEffect(() => {
        const accessToken = AccessToken();
        if (accessToken) {
            reInitSignoutWorker(accessToken.getExpiration());
        }
        if (window.logoutWorker) {
            window.logoutWorker.removeEventListener('signout', expireAccessTokenCallback);
            window.logoutWorker.addEventListener('signout', expireAccessTokenCallback);
        }
    }, [expireAccessTokenCallback]);
    useEffect(() => {
        setShouldShow(isTokenExpired);
    }, [isTokenExpired]);
    const {
        register: loginFormRegister,
        handleSubmit: loginFormHandleSubmit,
        formState: { errors: loginFormErrors },
    } = useForm<LoginFormInput>({
        resolver: yupResolver(LOGIN_VALIDATION_SCHEMA as any),
    });
    const handleLoginUser = async () => {
        const session = await fetchAuthSession();
        if (session.tokens && session.tokens.idToken) {
            PersistUserCredentials({
                accessToken: session.tokens.accessToken.toString(),
                idToken: session.tokens.idToken.toString(),
            });
            const accessToken = AccessToken();
            if (accessToken) {
                const expirationTimestamp = accessToken.getExpiration();
                reInitSignoutWorker(expirationTimestamp);
            }
            fetchUser();
        }
    };
    const handleLoginSubmit = async ({ email, password }: LoginFormInput) => {
        try {
            await signIn({ username: email, password });
            await handleLoginUser();
            setIsTokenExpired(false);
            setErrorMessage(null);
        } catch (e: any) {
            setErrorMessage(e.message);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handleClose = () => {};
    return (
        <>
            {children}
            <Dialog open={shouldShow} onClose={handleClose}>
                <form onSubmit={loginFormHandleSubmit(handleLoginSubmit)}>
                    <DialogTitle>Log in to continue</DialogTitle>
                    <DialogContent>
                        <Typography paragraph variant="body1">
                            We&apos;ve logged you out due to inactivity. Please log in to continue.
                        </Typography>
                        {Boolean(errorMessage) && <Alert severity="error">{errorMessage}</Alert>}

                        <div className={classes.inputCont}>
                            <TextField
                                {...loginFormRegister('email')}
                                variant="outlined"
                                label="Email"
                                type="email"
                                data-test={LoginEnum.EMAIL_INPUT}
                                fullWidth
                                error={!!loginFormErrors.email || Boolean(errorMessage)}
                                helperText={loginFormErrors.email?.message}
                            />
                            <TextField
                                {...loginFormRegister('password')}
                                variant="outlined"
                                label="Password"
                                type="password"
                                data-test={LoginEnum.PASSWORD_INPUT}
                                fullWidth
                                error={!!loginFormErrors.password || Boolean(errorMessage)}
                                helperText={loginFormErrors.password?.message}
                            />
                            <EmailHelpLink />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.bottomControl}>
                            <Button
                                disabled={userLoading}
                                type="submit"
                                color="primary"
                                variant="contained"
                            >
                                Login
                            </Button>
                        </div>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default InactiveLogin;
