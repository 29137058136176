import React, { useMemo } from 'react';
import { Typography, Link } from '@mui/material';

type NoOptionTextProps = {
    fullName: string;
    portalView?: boolean;
    affiliateId?: string;
};

const NoOptionText: React.FC<NoOptionTextProps> = props => {
    const { fullName, affiliateId = '', portalView = false } = props;
    const internalUrl = useMemo(() => {
        const [firstName = '', lastName = ''] = fullName
            .split(' ')
            .filter(s => s.trim().length > 0);
        if (portalView) {
            return `/affiliate-care/${affiliateId}/1?sc=true&firstName=${firstName}&lastName=${lastName}`;
        }
        return `/affiliate-care-portal/patients/${affiliateId}/1?sc=true&firstName=${firstName}&lastName=${lastName}`;
    }, [fullName, affiliateId, portalView]);
    return (
        <Typography variant="body1">
            No Patient Match -{' '}
            <Link href={internalUrl} target="_blank" rel="noopener noreferrer">
                Add Patient
            </Link>
        </Typography>
    );
};

export default NoOptionText;
