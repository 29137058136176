import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Divider, Slide, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import {
    AlertSeverity,
    useFetchCurrentUserLoginLazyQuery,
    useLoginUserForLoginPageMutation,
    useResetPasswordLoginPageMutation,
    useLoginAuthMethodLazyQuery,
} from '~/schemaTypes';
import { LoginEnum } from '~/selectors/login.selector';
import { ClearAuthTokens, PersistUserCredentials, TriggerGlobalAlert } from '~/state';
import { Amplify } from 'aws-amplify';
import { signIn, fetchAuthSession, confirmSignIn, resetPassword } from '@aws-amplify/auth';
import { AmplifyConfig } from '~/utils/amplifyConfig';
import { useQueryParams } from '~/hooks';
import history from 'history/browser';
import { useStyles } from './styles';
import { EmailHelpLink } from './EmailHelpLink';
import { PASSWORD_REGEX } from '../../helpers/regularExpressions';

Amplify.configure(AmplifyConfig);

export type LoginFormInput = {
    email: string;
    password: string;
};

export const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
    password: Yup.string().required('Password is required'),
});

const LOGIN_MIGRATION_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
    newPassword: Yup.string()
        .required('Password is required')
        .matches(
            PASSWORD_REGEX,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character',
        ),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), undefined], `Passwords don't match`)
        .required('Password confirm is required'),
});

type LoginMigrationFormInput = {
    email: string;
    newPassword: string;
    confirmNewPassword: string;
};

const RESET_PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
});

type ResetPasswordFormInput = {
    email: string;
};

const LOGIN_WITH_TOTP_VALIDATION_SCHEMA = Yup.object().shape({
    code: Yup.string().required('Code is Required'),
});

type LoginWithTOTPFormInput = {
    code: string;
};

type LoginProps = { clearAuthTokens?: boolean };

const Login: React.FC<LoginProps> = ({ clearAuthTokens }: LoginProps) => {
    const { classes } = useStyles();

    const [helpMode, setHelpMode] = useState<boolean>(false);
    // Only shown to users migrating to awsCognito
    const [showMigrationForm, setShowMigrationForm] = useState<boolean>(false);
    const [tempPasswordToken, setTempPasswordToken] = useState<string>();
    const [mfaRequired, setMfaRequired] = useState<boolean>(false);
    const [showEmailEditPage, setShowEmailEditPage] = useState<boolean>(true);

    const [query] = useQueryParams();
    const prefilledEmail = query.get('email');

    const {
        register: loginFormRegister,
        handleSubmit: loginFormHandleSubmit,
        getValues: loginFormGetValues,
        reset: loginFormReset,
        setValue: loginFormSetValue,
        trigger: loginFormTrigger,
        clearErrors: loginClearErrors,

        formState: { errors: loginFormErrors },
    } = useForm<LoginFormInput>({
        resolver: yupResolver(LOGIN_VALIDATION_SCHEMA as any),
    });

    const [checkEmail] = useLoginAuthMethodLazyQuery({
        onCompleted: data => {
            if (data.loginAuthMethod?.type === 'password') {
                setShowEmailEditPage(false);
            } else if (
                data.loginAuthMethod?.type === 'SAMLv2' &&
                data.loginAuthMethod?.samlRequest &&
                data.loginAuthMethod.samlUrlBase
            ) {
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = data.loginAuthMethod.samlUrlBase;
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'SAMLRequest';
                input.value = data.loginAuthMethod?.samlRequest;
                form.appendChild(input);
                document.body.appendChild(form);
                form.submit();
            }
        },
    });

    useEffect(() => {
        if (prefilledEmail && !loginFormGetValues('email')) {
            loginFormSetValue('email', prefilledEmail);
            checkEmail({ variables: { input: { email: prefilledEmail } } });
        }
    }, [checkEmail, loginFormGetValues, loginFormSetValue, prefilledEmail]);

    useEffect(() => {
        if (clearAuthTokens) {
            localStorage.clear();
        }
    }, [clearAuthTokens]);

    const {
        register: migrationFormRegister,
        handleSubmit: migrationFormHandleSubmit,
        setValue: migrationFormSetValue,

        formState: { errors: migrationFormErrors },
    } = useForm<LoginMigrationFormInput>({
        resolver: yupResolver(LOGIN_MIGRATION_VALIDATION_SCHEMA as any),
    });

    const {
        register: resetPasswordFormRegister,
        handleSubmit: resetPasswordFormHandleSubmit,
        reset: resetPasswordFormReset,
        setValue: resetPasswordFormSetValue,

        formState: { errors: resetPasswordFormErrors },
    } = useForm<ResetPasswordFormInput>({
        resolver: yupResolver(RESET_PASSWORD_VALIDATION_SCHEMA as any),
    });

    const {
        register: loginWithTOTPFormRegister,
        handleSubmit: loginWithTOTPFormHandleSubmit,
        reset: loginWithTOTPFormReset,
        formState: { errors: loginWithTOTPFormErrors },
    } = useForm<LoginWithTOTPFormInput>({
        resolver: yupResolver(LOGIN_WITH_TOTP_VALIDATION_SCHEMA as any),
    });
    const [fetchUser, { loading: userLoading }] = useFetchCurrentUserLoginLazyQuery({
        onCompleted: data => {
            if (clearAuthTokens) {
                history.replace('/');
            }
            if (data.currentUser) {
                window.location.reload();
            }
        },
    });

    const [loginUser, { loading: loginUserLoading }] = useLoginUserForLoginPageMutation({
        onCompleted: data => {
            if (data.loginUser?.success) {
                // User is migrating from old auth
                if (data.loginUser?.tempPasswordToken) {
                    const email = loginFormGetValues('email');
                    loginFormReset({
                        email,
                    });
                    migrationFormSetValue('email', email, {
                        shouldValidate: false,
                    });
                    setTempPasswordToken(data.loginUser?.tempPasswordToken);
                    setShowMigrationForm(true);
                } else if (data.loginUser?.tokens) {
                    PersistUserCredentials(data.loginUser?.tokens);
                    setShowMigrationForm(false);
                    fetchUser();
                } else {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message: 'An unexpected error occurred, please try again.',
                    });
                }
            }
        },
    });

    const [sendResetPasswordEmail, { loading: resetPasswordLoading }] =
        useResetPasswordLoginPageMutation({
            onCompleted: data => {
                if (data.resetPassword?.success) {
                    resetPasswordFormReset();
                }
            },
        });

    const handleLoginUser = async () => {
        const session = await fetchAuthSession();
        if (session.tokens && session.tokens.idToken) {
            PersistUserCredentials({
                accessToken: session.tokens.accessToken.toString(),
                idToken: session.tokens.idToken.toString(),
            });
            fetchUser();
        }
    };

    const handleLoginSubmit = async ({ email, password }: LoginFormInput) => {
        try {
            const user = await signIn({ username: email, password });
            if (user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
                loginFormReset();
                loginWithTOTPFormReset();
                setMfaRequired(true);
                return;
            }
            if (user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                setShowMigrationForm(true);
                return;
            }
            await handleLoginUser();
        } catch (e: any) {
            if (e.name === 'UserAlreadyAuthenticatedException') {
                await handleLoginUser();
                return;
            }
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Invalid Credentials: ${e.message}`,
            });
        }
    };

    const handleMfaSubmit = async ({ code }: LoginWithTOTPFormInput) => {
        try {
            const user = await confirmSignIn({ challengeResponse: code });
            if (user.isSignedIn) {
                await handleLoginUser();
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `Unable to login. Please try again.`,
                });
            }
        } catch (e: any) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Invalid Code: ${e.message}`,
            });
        }
    };

    const handleLoginMigrationSubmit = ({
        email,
        newPassword,
        confirmNewPassword,
    }: LoginMigrationFormInput) => {
        if (newPassword !== confirmNewPassword) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Passwords don't match.`,
            });
            return;
        }
        if (!tempPasswordToken) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Something went wrong on our end. Please reset your password using the "Password Help" button.`,
            });
            return;
        }
        loginUser({
            variables: {
                input: {
                    email,
                    newPassword,
                    passwordToken: tempPasswordToken,
                },
            },
        });
    };

    const handleResetPasswordSubmit = async ({ email }: ResetPasswordFormInput) => {
        try {
            const response = await resetPassword({ username: email });
            const { nextStep } = response;
            switch (nextStep.resetPasswordStep) {
                case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                    sendResetPasswordEmail({
                        variables: {
                            input: {
                                email,
                            },
                        },
                    });
                    break;
                case 'DONE':
                    break;
                default:
                    throw new Error(`Unexpected next step ${nextStep.resetPasswordStep}`);
            }
        } catch (e: any) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Unable to reset password: ${e.message}`,
            });
        }
    };

    const handleEmailSubmit = () => {
        const email = loginFormGetValues('email');
        if (!email) {
            loginFormTrigger('email', { shouldFocus: false });
            return;
        }
        loginClearErrors('email');
        ClearAuthTokens();
        checkEmail({ variables: { input: { email } } });
    };

    return (
        <div className={classes.root}>
            <Card className={classes.loginCard}>
                <Typography variant="h6" className="title">
                    {helpMode
                        ? 'Help Me Login to Wildflower Health'
                        : 'Log in to the Wildflower Health Portal'}
                </Typography>
                <Divider />
                {!helpMode ? (
                    <div>
                        {!showMigrationForm ? (
                            <div>
                                {!mfaRequired ? (
                                    <div>
                                        {showEmailEditPage ? (
                                            <Slide in direction="right">
                                                <form>
                                                    <div className="inputCont">
                                                        <TextField
                                                            {...loginFormRegister('email')}
                                                            variant="outlined"
                                                            label="Email"
                                                            type="email"
                                                            data-test={LoginEnum.EMAIL_INPUT}
                                                            fullWidth
                                                            error={!!loginFormErrors.email}
                                                            helperText={
                                                                loginFormErrors.email?.message
                                                            }
                                                        />
                                                        <EmailHelpLink />
                                                    </div>
                                                    <div className="bottomControl">
                                                        <Button
                                                            onClick={() => handleEmailSubmit()}
                                                            color="secondary"
                                                            variant="outlined"
                                                        >
                                                            Next
                                                        </Button>
                                                    </div>
                                                </form>
                                            </Slide>
                                        ) : (
                                            <Slide in direction="right">
                                                <form
                                                    onSubmit={loginFormHandleSubmit(
                                                        handleLoginSubmit,
                                                    )}
                                                >
                                                    <div className="inputCont">
                                                        <TextField
                                                            {...loginFormRegister('email')}
                                                            variant="outlined"
                                                            label="Email"
                                                            type="email"
                                                            data-test={LoginEnum.EMAIL_INPUT}
                                                            fullWidth
                                                            disabled
                                                            error={!!loginFormErrors.email}
                                                            helperText={
                                                                loginFormErrors.email?.message
                                                            }
                                                        />
                                                        <TextField
                                                            {...loginFormRegister('password')}
                                                            variant="outlined"
                                                            label="Password"
                                                            type="password"
                                                            data-test={LoginEnum.PASSWORD_INPUT}
                                                            fullWidth
                                                            error={!!loginFormErrors.password}
                                                            helperText={
                                                                loginFormErrors.password?.message
                                                            }
                                                        />
                                                        <EmailHelpLink />
                                                    </div>
                                                    <div className="bottomControl">
                                                        <div className="back">
                                                            <Button
                                                                onClick={() => {
                                                                    setShowEmailEditPage(true);
                                                                    loginFormReset({
                                                                        password: '',
                                                                    });
                                                                }}
                                                                color="secondary"
                                                                variant="outlined"
                                                            >
                                                                Back
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button
                                                                onClick={() => {
                                                                    setHelpMode(true);
                                                                    resetPasswordFormSetValue(
                                                                        'email',
                                                                        loginFormGetValues('email'),
                                                                    );
                                                                }}
                                                                color="secondary"
                                                                variant="outlined"
                                                            >
                                                                Reset password
                                                            </Button>
                                                            <AsyncActionButton
                                                                loading={
                                                                    loginUserLoading || userLoading
                                                                }
                                                            >
                                                                <Button
                                                                    disabled={
                                                                        loginUserLoading ||
                                                                        userLoading
                                                                    }
                                                                    type="submit"
                                                                    color="primary"
                                                                    variant="contained"
                                                                >
                                                                    Login
                                                                </Button>
                                                            </AsyncActionButton>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Slide>
                                        )}
                                    </div>
                                ) : (
                                    <Slide in direction="right">
                                        <form
                                            onSubmit={loginWithTOTPFormHandleSubmit(
                                                handleMfaSubmit,
                                            )}
                                        >
                                            <div className="inputCont">
                                                <TextField
                                                    {...loginWithTOTPFormRegister('code')}
                                                    variant="outlined"
                                                    label="Code"
                                                    type="code"
                                                    fullWidth
                                                    error={!!loginWithTOTPFormErrors.code}
                                                    helperText={
                                                        loginWithTOTPFormErrors.code?.message
                                                    }
                                                />
                                            </div>
                                            <div className="bottomControl">
                                                <Button
                                                    onClick={() => {
                                                        setMfaRequired(false);
                                                    }}
                                                    color="secondary"
                                                    variant="outlined"
                                                >
                                                    Back
                                                </Button>
                                                <AsyncActionButton
                                                    loading={loginUserLoading || userLoading}
                                                >
                                                    <Button
                                                        disabled={loginUserLoading || userLoading}
                                                        type="submit"
                                                        color="primary"
                                                        variant="contained"
                                                    >
                                                        Login
                                                    </Button>
                                                </AsyncActionButton>
                                            </div>
                                        </form>
                                    </Slide>
                                )}
                            </div>
                        ) : (
                            <Slide in direction="right">
                                <form
                                    onSubmit={migrationFormHandleSubmit(handleLoginMigrationSubmit)}
                                >
                                    <div className="inputCont">
                                        <TextField
                                            {...migrationFormRegister('email')}
                                            variant="outlined"
                                            label="Email"
                                            type="email"
                                            fullWidth
                                            error={!!migrationFormErrors.email}
                                            helperText={migrationFormErrors.email?.message}
                                        />
                                        <TextField
                                            {...migrationFormRegister('newPassword')}
                                            variant="outlined"
                                            label="New Password"
                                            type="password"
                                            name="newPassword"
                                            fullWidth
                                            error={!!migrationFormErrors.newPassword}
                                            helperText={
                                                migrationFormErrors.newPassword?.message ??
                                                'Passwords must be a minimum of 8 characters, and must contain at least one uppercase character, one lowercase character, one symbol, and one number.'
                                            }
                                        />
                                        <TextField
                                            {...migrationFormRegister('confirmNewPassword')}
                                            variant="outlined"
                                            label="Confirm Password"
                                            type="password"
                                            name="confirmNewPassword"
                                            fullWidth
                                            error={!!migrationFormErrors.confirmNewPassword}
                                            helperText={
                                                migrationFormErrors.confirmNewPassword?.message
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className="bottomControl">
                                        <Button
                                            onClick={() => {
                                                setHelpMode(true);
                                            }}
                                            color="secondary"
                                            variant="outlined"
                                        >
                                            Password Help
                                        </Button>
                                        <AsyncActionButton
                                            loading={loginUserLoading || userLoading}
                                        >
                                            <Button
                                                disabled={loginUserLoading || userLoading}
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                            >
                                                Login
                                            </Button>
                                        </AsyncActionButton>
                                    </div>
                                </form>
                            </Slide>
                        )}
                    </div>
                ) : (
                    <Slide in direction="right">
                        <form onSubmit={resetPasswordFormHandleSubmit(handleResetPasswordSubmit)}>
                            <div className="inputCont">
                                <TextField
                                    {...resetPasswordFormRegister('email')}
                                    variant="outlined"
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    fullWidth
                                    error={!!resetPasswordFormErrors.email}
                                    helperText={resetPasswordFormErrors.email?.message}
                                />
                            </div>
                            <div className="bottomControl">
                                <div className="back">
                                    <Button
                                        onClick={() => {
                                            setHelpMode(false);
                                        }}
                                        color="secondary"
                                        variant="outlined"
                                    >
                                        Back
                                    </Button>
                                </div>
                                <AsyncActionButton loading={resetPasswordLoading}>
                                    <Button
                                        disabled={resetPasswordLoading}
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                    >
                                        Reset Password
                                    </Button>
                                </AsyncActionButton>
                            </div>
                        </form>
                    </Slide>
                )}
            </Card>
        </div>
    );
};

export default Login;
