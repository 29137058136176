import React from 'react';
import { Grid, Typography } from '@mui/material';
import Header from '~/views/VirtualCareSurvey/components/Header';
import useStyles from '~/views/VirtualCareSurvey/styles';

type VirtualCareSurveyWrapperProps = {
    children: React.ReactNode;
    isMobileView: boolean;
    showFormDescription: boolean;
    isAffiliateView: boolean;
    affiliateName?: string;
};

const VirtualCareSurveyWrapper: React.FC<VirtualCareSurveyWrapperProps> = props => {
    const { children, isMobileView, showFormDescription, isAffiliateView, affiliateName } = props;
    const { classes } = useStyles();
    const formContainer = React.useRef<HTMLDivElement>(null);
    return (
        <div className={classes.container} ref={formContainer}>
            <Grid
                className={classes.form}
                container
                justifyContent={isMobileView ? 'flex-start' : 'center'}
            >
                <Header
                    isMobileView={isMobileView}
                    showFormDescription={showFormDescription}
                    subHeading={
                        isAffiliateView ? (
                            <Typography variant="body1" mb={2}>
                                In Partnership with: {affiliateName}
                            </Typography>
                        ) : null
                    }
                    isAffiliateView={isAffiliateView}
                />
                <Grid container className={classes.formContainer}>
                    {children}
                </Grid>
            </Grid>
        </div>
    );
};

export default VirtualCareSurveyWrapper;
