import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import { Grid, Typography, Dialog, TablePagination, FormControlLabel, Switch } from '@mui/material';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import {
    useOrganizationVcEligibilityEligibilityResultsLazyQuery,
    OrganizationVcEligibilityEligibilityResultsQuery,
    OrderByDirectionEnum,
} from '~/schemaTypes';
import { PAGESIZE } from '~/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ViewSelectedResult from './ViewSelectedResult';

type EligibilityResult = NonNullable<
    OrganizationVcEligibilityEligibilityResultsQuery['eligibilityResults']
>['results'][0];

const OrganizationVCEligibility: React.FC = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [showViewModal, setShowViewModal] = useState<boolean>(false);
    const [showFailureResults, setShowFailureResults] = useState<boolean>(true);
    const [selectedId, setSelectedId] = useState<string>();
    const [searchText, setSearchText] = useState<string>('');
    const [fetchEligibilityResults, { data: eligibilityResults, loading }] =
        useOrganizationVcEligibilityEligibilityResultsLazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
        });
    const fetchEligibilityResultsPayload = useMemo(
        () => ({
            variables: {
                input: {
                    filter: {
                        fields: {
                            passed: showFailureResults ? false : undefined,
                            ...(searchText && { _id: searchText }),
                        },
                    },
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                },
            },
        }),
        [showFailureResults, page, rowsPerPage, searchText],
    );
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    useEffect(() => {
        fetchEligibilityResults(fetchEligibilityResultsPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchEligibilityResultsPayload]);
    const resultViewHandler = useCallback((_: any, item: EligibilityResult) => {
        setSelectedId(item.id);
        setShowViewModal(true);
    }, []);
    return (
        <div style={{ marginRight: '1rem' }} ref={tableRef}>
            <Grid>
                <MaterialTable<EligibilityResult>
                    columns={[
                        {
                            title: '_id',
                            field: 'id',
                            render: ({ id }) => <Typography data-test={id}>{id}</Typography>,
                            sorting: false,
                        },
                        {
                            title: 'Passed',
                            field: 'passed',
                            render: ({ passed }) => (
                                <Typography data-test={passed}>
                                    {passed ? 'True' : 'False'}
                                </Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'First Name',
                            field: 'firstName',
                            render: ({ firstName }) => (
                                <Typography data-test={firstName}>{firstName}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Last Name',
                            field: 'lastName',
                            render: ({ lastName }) => (
                                <Typography data-test={lastName}>{lastName}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Date Time',
                            field: 'createdAt',
                            render: ({ createdAt }) => (
                                <Typography data-test={createdAt}>
                                    {moment(createdAt).local().format('MM-DD-YYYY hh:mm A')}
                                </Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                    ]}
                    data={
                        eligibilityResults?.eligibilityResults?.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    title="Virtual Care Eligibility requests"
                    options={{
                        search: true,
                        paginationPosition: 'bottom',
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                        debounceInterval: 500,
                    }}
                    isLoading={loading}
                    localization={{
                        header: {
                            actions: '',
                        },
                        toolbar: {
                            searchPlaceholder: 'Request Id',
                        },
                    }}
                    actions={[
                        {
                            onClick: resultViewHandler,
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View Eligibility Result',
                        },
                    ]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onSearchChange={setSearchText}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={eligibilityResults?.eligibilityResults?.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                        Toolbar: props => (
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={5}>
                                    <MTableToolbar {...props} />
                                </Grid>
                                <Grid item>
                                    <Grid container columnSpacing={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showFailureResults}
                                                    onChange={(_, checked) =>
                                                        setShowFailureResults(checked)
                                                    }
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label="Only Show Failures"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ),
                    }}
                />
            </Grid>
            {showViewModal && (
                <Dialog open={showViewModal} scroll="paper" fullWidth maxWidth="lg">
                    <ViewSelectedResult resultId={selectedId} setOpen={setShowViewModal} />
                </Dialog>
            )}
        </div>
    );
};

export default OrganizationVCEligibility;
