import * as Yup from 'yup';
import moment from 'moment';

export const ADD_PATIENT_TO_CLAIM_SCHEMA = () =>
    Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        birthDate: Yup.string(),
        dateOfService: Yup.date()
            .typeError('Invalid date')
            .dateFormatVCF()
            .min(moment().subtract(90, 'days').toDate(), 'DOS Greater than 90 days')
            .required('DOS is required'),
        serviceType: Yup.string().required('Service Type is required'),
    });
