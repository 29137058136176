import React, { StrictMode } from 'react';
// eslint-disable-next-line
import { createRoot } from 'react-dom/client';

import App from '~/App';
import * as serviceWorker from '~/serviceWorker';
import { ApolloClientWrapper } from './ApolloClientWrapper';
import InactiveLoginWrapper from './components/Login/InactiveLoginWrapper';

// env variables come from config/env.js
const { STRICT_MODE_DISABLED } = process.env;

// Only log in non-production environments
if (!STRICT_MODE_DISABLED) {
    // eslint-disable-next-line no-console
    console.log(`Strict mode is enabled.`);
}

(window as any)['__react-beautiful-dnd-disable-dev-warnings'] = true;

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
    STRICT_MODE_DISABLED ? (
        <InactiveLoginWrapper>
            <ApolloClientWrapper>
                <App />
            </ApolloClientWrapper>
        </InactiveLoginWrapper>
    ) : (
        <StrictMode>
            <InactiveLoginWrapper>
                <ApolloClientWrapper>
                    <App />
                </ApolloClientWrapper>
            </InactiveLoginWrapper>
        </StrictMode>
    ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
