import React from 'react';
import { Grid, Typography, Link, Button } from '@mui/material';
import { HomeOutlined } from '@mui/icons-material';
import { SUPPORT_EMAIL } from '../constants';

type PacifyConfirmationProps = {
    classes: Record<string, string>;
    isMobileView: boolean;
    backHandler: () => void;
};

const EmailLink = () => <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>;

const PacifyConfirmation: React.FC<PacifyConfirmationProps> = props => {
    const { classes, isMobileView, backHandler } = props;
    return (
        <Grid
            container
            justifyContent="center"
            className={classes.confirmationContainer}
            flexDirection="row"
            alignContent="flex-start"
            flex={1}
        >
            <Grid container justifyContent="center" paddingBottom={4}>
                <Grid item xs={10}>
                    <Typography style={{ textAlign: 'center' }}>
                        <HomeOutlined style={{ fontSize: '50px' }} />
                    </Typography>
                </Grid>
            </Grid>
            <Grid container rowSpacing={2} justifyContent="center">
                <Grid item xs={10}>
                    <Typography variant="body1" style={{ textAlign: 'center' }}>
                        Thank you. We will reach out to you to schedule your appointment.
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1" style={{ textAlign: 'center' }}>
                        If you have any questions, please feel to email us at <EmailLink /> .
                    </Typography>
                </Grid>
                <Grid container justifyContent="center" paddingTop={5}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={backHandler}
                            size={isMobileView ? 'small' : 'large'}
                            fullWidth={isMobileView}
                        >
                            Select A Different Appointment Method
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PacifyConfirmation;
