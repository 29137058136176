import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    AlertSeverity,
    FetchPatientCallListsForDashboardDocument,
    FetchPatientCallListsForDashboardQuery,
    PatientCallList,
    useCreatePatientCallListMutation,
    useDeletePatientCallListMutation,
    usePreFetchTagsPatientCallListQuery,
    useUpdatePatientCallListMutation,
} from '~/schemaTypes';
import { ArrowBack } from '@mui/icons-material';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from '~/components/Loading/Loading';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { useUserPermissions } from '../../../../hooks';

const useStyles = makeStyles()({
    root: {},
    inputField: {
        width: '100%',
        '& label': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '80%',
            overflow: 'hidden',
        },
    },
});

type PatientCallListModalProps = {
    onClose: () => void;
    onUpdate: () => void;
    selected: PatientCallList | null;
};
const LOADING_AMS_TAGS_TEXT = 'Loading tags...';

interface PatientCallListForm {
    name: string;
    tagIds: string[];
}

const formDefaults: PatientCallListForm = {
    name: '',
    tagIds: [],
};

const toFormDefaults = (list: PatientCallList): PatientCallListForm => {
    if (list.tags && list.tags?.length > 0) {
        const tagIds = list.tags.map(item => item.id);
        return {
            name: list.name,
            tagIds,
        };
    }
    return formDefaults;
};

const PATIENT_CALL_LIST_SCHEMA: Yup.ObjectSchema<PatientCallListForm> = Yup.object({
    name: Yup.string()
        .required('Title required')
        .test('empty-check', 'Should not be empty', title => title.trim() !== ''),
    tagIds: Yup.array(Yup.string().defined())
        .defined()
        .test('empty-array-check', 'Should not be empty', ids => ids.length !== 0),
});

const idsAreEqual = (arr1: string[], arr2: string[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    const isEqual = arr1.every(element => arr2.some(item => element === item));
    return isEqual;
};

const PatientCallListModal = ({ onClose, onUpdate, selected }: PatientCallListModalProps) => {
    const [isEditMode] = useState(!!selected);
    const [shouldCLose, setShouldClose] = useState(false);
    const [patientsNotFoundError, setPatientsNotFoundError] = useState<string>();

    const { pagePermissions } = useUserPermissions();

    const { classes } = useStyles();

    const { data: preFetchData, loading: preFetchLoading } = usePreFetchTagsPatientCallListQuery();
    const {
        register,
        handleSubmit,
        reset,
        control,
        getValues,
        formState: { errors },
    } = useForm<PatientCallListForm>({
        resolver: yupResolver<PatientCallListForm>(PATIENT_CALL_LIST_SCHEMA),
        defaultValues: isEditMode && selected ? toFormDefaults(selected) : formDefaults,
    });
    const [createdResult, { loading: createCallListLoading }] = useCreatePatientCallListMutation({
        onCompleted: data => {
            const { createPatientCallList } = data;
            const success = createPatientCallList?.success ?? false;
            const status = createPatientCallList?.status;
            const message = createPatientCallList?.message;
            if (success) {
                if (status === 404) {
                    setPatientsNotFoundError(message);
                } else {
                    onUpdate();
                    if (shouldCLose) {
                        reset();
                        onClose();
                    }
                }
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'An unexpected error occurred on Call List creation.',
                });
            }
        },
    });
    const [updatedResult, { loading: updateCallListLoading }] = useUpdatePatientCallListMutation({
        onCompleted: data => {
            const { updatePatientCallList } = data;
            const success = updatePatientCallList?.success ?? false;
            const status = updatePatientCallList?.status;
            const message = updatePatientCallList?.message;
            if (success) {
                if (status === 404) {
                    setPatientsNotFoundError(message);
                } else if (shouldCLose) {
                    reset();
                    onClose();
                }
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'An unexpected error occurred on Call List creation.',
                });
            }
        },
        update: (cache, response) => {
            const updatedCallList = response.data?.updatePatientCallList?.resourceUpdated;
            if (response.data?.updatePatientCallList?.success && updatedCallList) {
                const currentCallLists = cache.readQuery<FetchPatientCallListsForDashboardQuery>({
                    query: FetchPatientCallListsForDashboardDocument,
                });
                if (currentCallLists?.patientCallListsV2?.results) {
                    cache.writeQuery<FetchPatientCallListsForDashboardQuery>({
                        query: FetchPatientCallListsForDashboardDocument,
                        data: {
                            patientCallListsV2: {
                                ...currentCallLists?.patientCallListsV2,
                                results: [
                                    ...currentCallLists.patientCallListsV2.results.map(list => {
                                        if (list.id === updatedCallList.id) {
                                            return updatedCallList;
                                        }
                                        return list;
                                    }),
                                ],
                            },
                        },
                    });
                }
            }
        },
    });
    const [deletedResult, { loading: deleteCallListLoading }] = useDeletePatientCallListMutation({
        onCompleted: data => {
            const { deletePatientCallList } = data;
            const success = deletePatientCallList?.success ?? false;
            if (success) {
                onUpdate();
                reset();
                onClose();
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'An unexpected error occurred on Call List deletion.',
                });
            }
        },
    });

    const onSubmit = async (formData: PatientCallListForm) => {
        setPatientsNotFoundError(undefined);
        if (isEditMode) {
            await updatedResult({
                variables: {
                    input: {
                        id: selected?.id ?? '',
                        name: formData.name,
                        tagIds: formData.tagIds,
                    },
                },
            });
        } else {
            await createdResult({
                variables: {
                    input: {
                        name: formData.name,
                        tagIds: formData.tagIds,
                    },
                },
            });
        }
    };

    const deleteItemHandler = async () => {
        if (selected) {
            const { id } = selected;
            await deletedResult({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    };

    const goBackHandler = () => {
        const defaults = isEditMode && selected ? toFormDefaults(selected) : formDefaults;

        const name = getValues('name');
        const tagIds = getValues('tagIds');
        const isNameChanged = name !== defaults.name;
        const isTagIdsChanged = !idsAreEqual(tagIds, defaults.tagIds);

        let notSaved = false;

        if (isTagIdsChanged || isNameChanged) {
            notSaved = true;
        }

        if (isEditMode && notSaved) {
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Patient Call Lists page?`,
                callback: () => {
                    onClose();
                },
            });
        } else {
            onClose();
        }
    };

    if (createCallListLoading || updateCallListLoading || deleteCallListLoading) {
        return <Loading />;
    }
    return (
        <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={12}>
                        <Button startIcon={<ArrowBack />} onClick={goBackHandler}>
                            Back to Patient Call List
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {isEditMode ? 'Edit Patient Call List' : 'Create Patient Call List'}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className="formCont">
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Title"
                            type="text"
                            margin="dense"
                            className={classes.inputField}
                            fullWidth
                            {...register('name')}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {preFetchData?.tags && (
                            <AutocompleteWithRecordOptions
                                options={preFetchData.tags ?? []}
                                valueKey="id"
                                labelKey="name"
                                control={control}
                                name="tagIds"
                                label="Tags"
                                placeholder="Select tags ..."
                                loading={preFetchLoading}
                                loadingText={LOADING_AMS_TAGS_TEXT}
                                error={Boolean(errors.tagIds) || Boolean(patientsNotFoundError)}
                                helperText={errors.tagIds?.message || patientsNotFoundError}
                            />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                {pagePermissions?.PatientCallLists.Delete && isEditMode && (
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={async () => {
                            await deleteItemHandler();
                        }}
                    >
                        Delete
                    </Button>
                )}
                <Button type="submit" color="primary" variant="contained">
                    Save
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={async () => {
                        setShouldClose(true);
                        // reset();
                        // onClose();
                        // onUpdate();
                    }}
                >
                    Save &amp; Close
                </Button>
            </DialogActions>
        </form>
    );
};

export default PatientCallListModal;
