import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { Language, PreFetchAppsQuery, ReadingLevel } from '~/schemaTypes';
import { Replacement } from './TokenEditor';

type AMSApps = NonNullable<PreFetchAppsQuery['amsApps'][0]>;

interface ReplacementFormInput {
    replacement: string;
}
const FormInputValidation = Yup.object().shape({
    replacement: Yup.string().required('Required'),
});

const ReplacementModal: React.FC<{
    replacement: Replacement | null;
    apps: AMSApps[];
    replacementList: Replacement[];
    closeHandler: () => void;
    submitHandler: (data: Replacement) => void;
}> = props => {
    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<ReplacementFormInput>({
        resolver: yupResolver(FormInputValidation as any),
    });
    const { replacement, apps, replacementList, closeHandler, submitHandler } = props;
    const [usedError, setUsedError] = useState(false);
    const [selectedLang, setSelectedLang] = useState(replacement?.language);
    const [selectedReadingLevel, setSelectedReadingLevel] = useState(replacement?.readingLevel);
    const [selectedApp, setSelectedApp] = useState(replacement?.appId);
    const onSubmit = (values: any) => {
        setUsedError(false);
        const rep: Replacement = {
            appId: selectedApp,
            language: selectedLang,
            readingLevel: selectedReadingLevel,
            replacement: values.replacement,
        };
        if (
            // adding new replacement so check it doesn't alreay exist
            replacement === null &&
            replacementList.find(
                o =>
                    o.appId === rep.appId &&
                    o.language === rep.language &&
                    o.readingLevel === rep.readingLevel,
            ) !== undefined
        ) {
            setUsedError(true);
            return;
        }
        submitHandler(rep);
    };
    return (
        <Dialog open>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                    Edit Replacement
                </DialogTitleWithClose>
                <DialogContent>
                    <OutlinedSection title="Variant">
                        Language:{' '}
                        <select
                            onChange={e => setSelectedLang(e.target.value as Language)}
                            disabled={replacement !== undefined && replacement !== null}
                            value={selectedLang || ''}
                        >
                            <option key="1" value="">
                                Any Language
                            </option>
                            <option key="2" value={Language.En}>
                                English
                            </option>
                            <option key="3" value={Language.Es}>
                                Spanish
                            </option>
                        </select>
                        Reading Level:{' '}
                        <select
                            onChange={e => setSelectedReadingLevel(e.target.value as ReadingLevel)}
                            disabled={replacement !== undefined && replacement !== null}
                            value={selectedReadingLevel || ''}
                        >
                            <option key="4" value="">
                                Any Reading Level
                            </option>
                            <option key="5" value={ReadingLevel.Fifth}>
                                Fifth Grade
                            </option>
                            <option key="6" value={ReadingLevel.Eighth}>
                                Eighth Grade
                            </option>
                        </select>
                    </OutlinedSection>
                    <OutlinedSection title="App">
                        <select
                            onChange={e => setSelectedApp(e.target.value)}
                            disabled={replacement !== undefined && replacement !== null}
                            value={selectedApp || ''}
                        >
                            <option value="">All Apps</option>
                            {apps.map(a => (
                                <option value={a.id}>{a.bundleId}</option>
                            ))}
                        </select>
                    </OutlinedSection>
                    <OutlinedSection title="Replacement Text">
                        <TextField
                            variant="outlined"
                            label="Replacement Text"
                            type="text"
                            margin="dense"
                            defaultValue={replacement?.replacement}
                            fullWidth
                            {...register('replacement')}
                            error={!!errors.replacement}
                            helperText={errors.replacement?.message}
                        />
                    </OutlinedSection>
                    {usedError && (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>
                            Variant and App combination already defined. Please change.
                        </div>
                    )}
                </DialogContent>
                <DialogActions
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: 1000,
                    }}
                >
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" color="secondary" variant="contained">
                        Update
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
export {};
export default ReplacementModal;
