import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack, Save } from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { useUserPermissions } from '~/hooks';
import {
    AmsProduct,
    ExpressionUseType,
    TagInput,
    TagListDocument,
    TagListQuery,
    TagUpdateInput,
    TagUsageType,
    useCreateTagMutation,
    useFetchTagLazyQuery,
    usePreFetchAppsQuery,
    useTagListQuery,
    useUpdateTagMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

import ConfigExpressionSelect from '~/views/ConfigDashboard/ConfigExpressions/components/ConfigExpressionSelect';
import { ClientSelectionType, ClientSelectionTypes } from '~/enums/enums';
import _ from 'lodash';

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
}));
const toClientSelectionType = ({ excludeApps, includeApps }: TagInput): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};

// TODO: Fix id trying to be used on a type that doesn't have it
interface TagFormInput {
    id?: string;
    name: string;
    expressionId: string;
    clientSelectionType: ClientSelectionType;
    includeApps?: string[];
    excludeApps?: string[];
    isPatientFilter: boolean;
    usageType: TagUsageType;
    patientRiskScorePoints?: number;
}

export const USAGE_TYPES_OPTIONS = [
    TagUsageType.Filtering,
    TagUsageType.PatientRiskScore,
    // `Trigger` is omitted; it's created and displayed at separate UI
];

const formValuesFromData = (instance: TagInput): TagFormInput => {
    return {
        // id: instance.id,
        name: instance.name,
        expressionId: instance.expressionId ?? '',
        clientSelectionType: toClientSelectionType(instance),
        includeApps: instance.includeApps ?? [],
        excludeApps: instance.excludeApps ?? [],
        isPatientFilter: instance.isPatientFilter ?? false,
        usageType: instance.usageType ?? TagUsageType.Filtering,
        patientRiskScorePoints: instance.patientRiskScorePoints ?? undefined,
    };
};

const formValuesDefault = (tagId: string): TagFormInput => {
    return {
        id: tagId,
        name: '',
        expressionId: '',
        clientSelectionType: ClientSelectionType.IncludeAllApps,
        includeApps: [],
        excludeApps: [],
        isPatientFilter: false,
        usageType: TagUsageType.Filtering,
    };
};

const TagValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    clientSelectionType: Yup.mixed().oneOf(ClientSelectionTypes).required(),
    includeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            if (clientSelectionType === ClientSelectionType.IncludeCertainApps) {
                return schema.min(1, 'Must select at least one app to include').required();
            }
            return schema;
        }),
    excludeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            if (clientSelectionType === ClientSelectionType.ExcludeCertainApps) {
                return schema.min(1, 'Must select at least one app to exclude').required();
            }
            return schema;
        }),
    isPatientFilter: Yup.boolean().default(false).required(),
    usageType: Yup.mixed().oneOf(USAGE_TYPES_OPTIONS).required(),
    patientRiskScorePoints: Yup.number().when('usageType', ([usageType], schema) => {
        if (usageType === TagUsageType.PatientRiskScore) {
            return schema.min(0).required();
        }
        return schema;
    }),
});
const toIncludeOrExclude = ({ clientSelectionType, includeApps, excludeApps }: TagFormInput) => {
    switch (clientSelectionType) {
        case ClientSelectionType.IncludeCertainApps:
            return {
                includeApps: includeApps ?? [],
                excludeApps: [],
            };
        case ClientSelectionType.ExcludeCertainApps:
            return {
                includeApps: [],
                excludeApps: excludeApps ?? [],
            };
        case ClientSelectionType.IncludeAllApps:
        default:
            return {
                includeApps: [],
                excludeApps: [],
            };
    }
};
export const formValuesToCreate = (
    form: TagFormInput,
    expressionId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tagId: string,
): TagInput => {
    const {
        clientSelectionType,
        includeApps,
        excludeApps,
        id,
        isPatientFilter,
        usageType,
        patientRiskScorePoints,
        ...rest
    } = form;

    return {
        ...rest,
        expressionId,
        ...toIncludeOrExclude(form),
        // id: tagId,
        isPatientFilter: usageType !== TagUsageType.Filtering ? false : isPatientFilter,
        patientRiskScorePoints:
            usageType !== TagUsageType.PatientRiskScore ? undefined : patientRiskScorePoints,
        usageType,
    };
};

export const formValuesToUpdate = (form: TagFormInput, expressionId: string): TagUpdateInput => {
    const {
        clientSelectionType,
        includeApps,
        excludeApps,
        isPatientFilter,
        usageType,
        patientRiskScorePoints,
        ...rest
    } = form;

    return {
        ...rest,
        expressionId,
        ...toIncludeOrExclude(form),
        isPatientFilter: usageType !== TagUsageType.Filtering ? false : isPatientFilter,
        patientRiskScorePoints:
            usageType !== TagUsageType.PatientRiskScore ? undefined : patientRiskScorePoints,
        usageType,
    };
};
const TagEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: urlId } = useParams<{ id: string }>();
    const [isModified, setIsModified] = useState(false);
    const [close, setClose] = useState(false);
    const { pagePermissions } = useUserPermissions();
    const canEdit = pagePermissions?.Tags.Edit || false;
    const [selectedExpressionId, setSelectedExpressionId] = useState<string | null>();
    const isEditMode = urlId !== 'new';
    const [tagId] = useState(isEditMode ? urlId : new ObjectId().toHexString());
    let title = isEditMode ? 'Edit Tag' : 'Create Tag';
    if (!canEdit) title = 'Tag Details';
    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsQuery();

    const [fetchTagById, { data: tagData, loading: tagLoading }] = useFetchTagLazyQuery();

    const { data: tagList, loading: tagsLoading } = useTagListQuery({
        variables: { tagListInput: [TagUsageType.Filtering, TagUsageType.PatientRiskScore] },
    });

    const [updateTag, { loading: updateTagLoading }] = useUpdateTagMutation({
        onCompleted: () => {
            if (close) history(`/app-config/tags`);
        },
        update: (cache, response) => {
            const updatedTag = response.data?.updateTag?.resourceUpdated;
            if (response.data?.updateTag?.success && updatedTag) {
                const currentTags = cache.readQuery<TagListQuery>({
                    query: TagListDocument,
                });
                if (currentTags?.getTagListByUsageTypes) {
                    cache.writeQuery<TagListQuery>({
                        query: TagListDocument,
                        data: {
                            getTagListByUsageTypes: [
                                ...currentTags.getTagListByUsageTypes.map(tag => {
                                    if (tag.id === updatedTag.id) {
                                        return updatedTag;
                                    }
                                    return tag;
                                }),
                            ],
                        },
                    });
                }
            }
        },
    });

    const [createTag, { loading: createTagLoading }] = useCreateTagMutation({
        onCompleted: () => {
            if (close) history(`/app-config/tags`);
        },
        update: (cache, response) => {
            const newTag = response.data?.createTag?.resourceCreated;
            if (response.data?.createTag?.success && newTag) {
                const currentTags = cache.readQuery<TagListQuery>({
                    query: TagListDocument,
                });
                if (currentTags?.getTagListByUsageTypes) {
                    cache.writeQuery<TagListQuery>({
                        query: TagListDocument,
                        data: {
                            getTagListByUsageTypes: [...currentTags.getTagListByUsageTypes, newTag],
                        },
                    });
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: TagListDocument,
                variables: {
                    tagListInput: [TagUsageType.Filtering, TagUsageType.PatientRiskScore],
                },
            },
        ],
    });

    const {
        control,
        register,
        reset,
        handleSubmit,
        setValue,
        watch,
        setError,

        formState: { errors },
    } = useForm<TagFormInput>({
        resolver: yupResolver(TagValidation as any),
        defaultValues: formValuesDefault(tagId ?? ''),
    });

    useEffect(() => {
        if (isEditMode) {
            fetchTagById({ variables: { tagInput: { id: tagId } } });
            if (tagData && tagData.tag) {
                setSelectedExpressionId(tagData.tag.expressionId);
                reset(formValuesFromData(tagData.tag));
            }
        }
    }, [fetchTagById, isEditMode, tagId, tagData, reset]);

    const clientSelectionType = watch('clientSelectionType');
    const usageType = watch('usageType');
    const expressionSelected = (id: string, name: string) => {
        setSelectedExpressionId(id);
        if (!isEditMode) setValue('name', name);
        setIsModified(true);
    };

    const onSubmit = (values: any) => {
        if (!selectedExpressionId || selectedExpressionId === '0' || selectedExpressionId === '') {
            TriggerGlobalConfirm({
                callback: () => {
                    setIsModified(true);
                },
                message: 'Please select an Expression',
            });
            return;
        }
        if (
            tagList &&
            ((isEditMode &&
                tagList.getTagListByUsageTypes.filter(
                    t => t.id !== tagId && t.name.toLowerCase() === values.name.toLowerCase(),
                ).length > 0) ||
                (!isEditMode &&
                    tagList.getTagListByUsageTypes.filter(
                        t => t.name.toLowerCase() === values.name.toLowerCase(),
                    ).length > 0))
        ) {
            setError('name', { message: 'Tag Name is already used, please change.' });
            return;
        }
        if (isEditMode && tagId) {
            updateTag({
                variables: {
                    updateTagInput: {
                        id: tagId,
                        data: formValuesToUpdate(values, selectedExpressionId || ''),
                    },
                },
            });
        } else if (tagId) {
            createTag({
                variables: {
                    createTagInput: formValuesToCreate(values, selectedExpressionId || '', tagId),
                },
            });
        }
    };
    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Tag List?`,
                callback: () => {
                    history(`/app-config/tags/`);
                },
            });
        else history(`/app-config/tags/`);
    };
    const onEdit = () => {
        setIsModified(true);
    };
    if (
        tagLoading ||
        createTagLoading ||
        updateTagLoading ||
        preFetchLoading ||
        !preFetchData ||
        tagsLoading
    )
        return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Tag List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader title={title} />
                        <div style={{ display: 'inline-block', paddingRight: '10px' }}>
                            Select Expression *:
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            <ConfigExpressionSelect
                                itemSelected={expressionSelected}
                                inputId={selectedExpressionId ?? null}
                                useType={ExpressionUseType.Tags}
                                canChange={canEdit}
                            />
                        </div>
                        <OutlinedSection title="Tag Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                                disabled={!canEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Apps">
                            <ReactHookFormSelect
                                control={control}
                                name="clientSelectionType"
                                variant="outlined"
                                defaultValue=""
                                label="Selection type *"
                                fullWidth
                                margin="dense"
                                disabled={!canEdit}
                            >
                                {ClientSelectionTypes.map(m => (
                                    <MenuItem key={m} value={m}>
                                        {m}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {clientSelectionType === ClientSelectionType.IncludeCertainApps && (
                                <AutocompleteWithRecordOptions
                                    options={
                                        _.orderBy(preFetchData?.amsApps, 'bundleId').filter(
                                            a => a.productConst === AmsProduct.VbcPlatform,
                                        ) ?? []
                                    }
                                    valueKey="bundleId"
                                    labelKey="bundleId"
                                    control={control}
                                    name="includeApps"
                                    label="Include apps *"
                                    placeholder="Select apps to include..."
                                    required
                                    disabled={!canEdit}
                                    error={Boolean(errors.includeApps)}
                                    helperText={
                                        (errors.includeApps as FieldError | undefined)?.message
                                    }
                                />
                            )}
                            {clientSelectionType === ClientSelectionType.ExcludeCertainApps && (
                                <AutocompleteWithRecordOptions
                                    options={
                                        _.orderBy(preFetchData?.amsApps, 'bundleId').filter(
                                            a => a.productConst === AmsProduct.VbcPlatform,
                                        ) ?? []
                                    }
                                    valueKey="bundleId"
                                    labelKey="bundleId"
                                    control={control}
                                    name="excludeApps"
                                    label="Exclude apps *"
                                    placeholder="Select apps to exclude..."
                                    required
                                    disabled={!canEdit}
                                    error={Boolean(errors.excludeApps)}
                                    helperText={
                                        (errors.excludeApps as FieldError | undefined)?.message
                                    }
                                />
                            )}
                        </OutlinedSection>
                        <ReactHookFormSelect
                            control={control}
                            name="usageType"
                            variant="outlined"
                            defaultValue=""
                            label="Tag usage type *"
                            fullWidth
                            margin="dense"
                            disabled={!canEdit}
                        >
                            {USAGE_TYPES_OPTIONS.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        {usageType === TagUsageType.Filtering && (
                            <Controller
                                name="isPatientFilter"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={e => {
                                                    onChange(e.target.checked);
                                                    onEdit();
                                                }}
                                                disabled={!canEdit}
                                            />
                                        }
                                        label="Is Patient Filter"
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        )}
                        {usageType === TagUsageType.PatientRiskScore && (
                            <TextField
                                variant="outlined"
                                type="number"
                                fullWidth
                                margin="dense"
                                label="Patient risk score points *"
                                {...register('patientRiskScorePoints', { valueAsNumber: true })}
                                error={!!errors.patientRiskScorePoints}
                                helperText={errors.patientRiskScorePoints?.message}
                                onChange={onEdit}
                                disabled={!canEdit}
                            />
                        )}
                        {canEdit && (
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <Button
                                    type="submit"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleSubmit(onSubmit)}
                                >
                                    Save
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setClose(true);
                                        handleSubmit(onSubmit);
                                    }}
                                >
                                    Save &amp; Close
                                </Button>
                            </div>
                        )}
                    </Card>
                </form>
            </Grid>
        </Grid>
    );
};

export default TagEditor;
