import { Checkbox, Grid, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useCallback } from 'react';
import { useAtom } from 'jotai';
import { PatientsHiddenColumnsAtom } from '../Patients.context';
import { VirtualCareViewColumns } from '../types';

type FilterColumnsModalProps = {
    hiddenColumnsAnchorEl: null | HTMLElement;
    setHiddenColumnsAnchorEl: (value: React.SetStateAction<null | HTMLElement>) => void;
    isVirtualCareView?: boolean;
};

export const FilterColumnsModal: React.FC<FilterColumnsModalProps> = ({
    hiddenColumnsAnchorEl,
    setHiddenColumnsAnchorEl,
    isVirtualCareView = false,
}) => {
    const [hiddenColumns, setHiddenColumns] = useAtom(PatientsHiddenColumnsAtom);
    const showColumn = useCallback(
        (column: keyof typeof hiddenColumns) => {
            if (!isVirtualCareView) {
                return true;
            }
            return column in VirtualCareViewColumns;
        },
        [isVirtualCareView],
    );
    return (
        <Grid container spacing={0}>
            <Grid item>
                <Menu
                    open={Boolean(hiddenColumnsAnchorEl)}
                    onClose={() => setHiddenColumnsAnchorEl(null)}
                    anchorEl={hiddenColumnsAnchorEl}
                    style={{ marginTop: '48px' }}
                >
                    {showColumn('invitationCode') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    invitationCode: !state.invitationCode,
                                }));
                                localStorage.setItem(
                                    'invitationCodeHidden',
                                    String(!hiddenColumns.invitationCode),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.invitationCode} />
                            <ListItemText primary="Invitation Code" />
                        </MenuItem>
                    )}
                    {showColumn('externalId') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    externalId: !state.externalId,
                                }));
                                localStorage.setItem(
                                    'externalIdHidden',
                                    String(!hiddenColumns.externalId),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.externalId} />
                            <ListItemText primary="External ID" />
                        </MenuItem>
                    )}
                    {showColumn('firstName') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    firstName: !state.firstName,
                                }));
                                localStorage.setItem(
                                    'firstNameHidden',
                                    String(!hiddenColumns.firstName),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.firstName} />
                            <ListItemText primary="First Name" />
                        </MenuItem>
                    )}
                    {showColumn('lastName') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    lastName: !state.lastName,
                                }));
                                localStorage.setItem(
                                    'lastNameHidden',
                                    String(!hiddenColumns.lastName),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.lastName} />
                            <ListItemText primary="Last Name" />
                        </MenuItem>
                    )}
                    {showColumn('riskScore') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    riskScore: !state.riskScore,
                                }));
                                localStorage.setItem(
                                    'riskScoreHidden',
                                    String(!hiddenColumns.riskScore),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.riskScore} />
                            <ListItemText primary="Risk Score" />
                        </MenuItem>
                    )}
                    {showColumn('createdAt') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    createdAt: !state.createdAt,
                                }));
                                localStorage.setItem(
                                    'createdAtHidden',
                                    String(!hiddenColumns.createdAt),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.createdAt} />
                            <ListItemText primary="Created" />
                        </MenuItem>
                    )}
                    {showColumn('assignedHealthAdvocate') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    assignedHealthAdvocate: !state.assignedHealthAdvocate,
                                }));
                                localStorage.setItem(
                                    'assignedHealthAdvocateHidden',
                                    String(!hiddenColumns.assignedHealthAdvocate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.assignedHealthAdvocate} />
                            <ListItemText primary="Assigned Health Advocate" />
                        </MenuItem>
                    )}
                    {showColumn('openAdvocateTaskCount') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    openAdvocateTaskCount: !state.openAdvocateTaskCount,
                                }));
                                localStorage.setItem(
                                    'openAdvocateTaskCountHidden',
                                    String(!hiddenColumns.openAdvocateTaskCount),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.openAdvocateTaskCount} />
                            <ListItemText primary="Open Tasks" />
                        </MenuItem>
                    )}
                    {showColumn('openCriticalAdvocateTaskCount') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    openCriticalAdvocateTaskCount:
                                        !state.openCriticalAdvocateTaskCount,
                                }));
                                localStorage.setItem(
                                    'openCriticalAdvocateTaskCountHidden',
                                    String(!hiddenColumns.openCriticalAdvocateTaskCount),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.openCriticalAdvocateTaskCount} />
                            <ListItemText primary="Open Critical Tasks" />
                        </MenuItem>
                    )}
                    {showColumn('appRegistrationCompleted') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    appRegistrationCompleted: !state.appRegistrationCompleted,
                                }));
                                localStorage.setItem(
                                    'appRegistrationCompletedHidden',
                                    String(!hiddenColumns.appRegistrationCompleted),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.appRegistrationCompleted} />
                            <ListItemText primary="App Registration Complete" />
                        </MenuItem>
                    )}
                    {showColumn('patientCallStatus') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    patientCallStatus: !state.patientCallStatus,
                                }));
                                localStorage.setItem(
                                    'patientCallStatusHidden',
                                    String(!hiddenColumns.patientCallStatus),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.patientCallStatus} />
                            <ListItemText primary="Call Status" />
                        </MenuItem>
                    )}
                    {showColumn('birthDate') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    birthDate: !state.birthDate,
                                }));
                                localStorage.setItem(
                                    'birthDateHidden',
                                    String(!hiddenColumns.birthDate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.birthDate} />
                            <ListItemText primary="Birth Date" />
                        </MenuItem>
                    )}
                    {showColumn('practice') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    practice: !state.practice,
                                }));
                                localStorage.setItem(
                                    'practiceHidden',
                                    String(!hiddenColumns.practice),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.practice} />
                            <ListItemText primary="Practice" />
                        </MenuItem>
                    )}
                    {showColumn('onboardingCompleteDate') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    onboardingCompleteDate: !state.onboardingCompleteDate,
                                }));
                                localStorage.setItem(
                                    'onboardingCompleteDateHidden',
                                    String(!hiddenColumns.onboardingCompleteDate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.onboardingCompleteDate} />
                            <ListItemText primary="Onboarding Completed" />
                        </MenuItem>
                    )}
                    {showColumn('isPregnant') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    isPregnant: !state.isPregnant,
                                }));
                                localStorage.setItem(
                                    'isPregnantHidden',
                                    String(!hiddenColumns.isPregnant),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.isPregnant} />
                            <ListItemText primary="Is Pregnant" />
                        </MenuItem>
                    )}
                    {showColumn('dueDate') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    dueDate: !state.dueDate,
                                }));
                                localStorage.setItem(
                                    'dueDateHidden',
                                    String(!hiddenColumns.dueDate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.dueDate} />
                            <ListItemText primary="Due Date" />
                        </MenuItem>
                    )}
                    {showColumn('deliveryDate') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    deliveryDate: !state.deliveryDate,
                                }));
                                localStorage.setItem(
                                    'deliveryDateHidden',
                                    String(!hiddenColumns.deliveryDate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.deliveryDate} />
                            <ListItemText primary="Delivery Date" />
                        </MenuItem>
                    )}
                    {showColumn('email') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    email: !state.email,
                                }));
                                localStorage.setItem('emailHidden', String(!hiddenColumns.email));
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.email} />
                            <ListItemText primary="Email" />
                        </MenuItem>
                    )}
                    {showColumn('phoneNumber') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    phoneNumber: !state.phoneNumber,
                                }));
                                localStorage.setItem(
                                    'phoneNumberHidden',
                                    String(!hiddenColumns.phoneNumber),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.phoneNumber} />
                            <ListItemText primary="Phone Number" />
                        </MenuItem>
                    )}
                    {showColumn('id') && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    id: !state.id,
                                }));
                                localStorage.setItem('id', String(!hiddenColumns.id));
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.id} />
                            <ListItemText primary="Patient ID" />
                        </MenuItem>
                    )}
                    {showColumn('affiliates') && isVirtualCareView && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    affiliates: !state.affiliates,
                                }));
                                localStorage.setItem(
                                    'affiliates',
                                    String(!hiddenColumns.affiliates),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.affiliates} />
                            <ListItemText primary="Affiliate(s)" />
                        </MenuItem>
                    )}
                </Menu>
            </Grid>
        </Grid>
    );
};
