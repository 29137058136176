import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    Divider,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowBack, Save } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Controller, DeepMap, FieldError, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    FetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery,
    PreFetchDataForCarePlanPageDocument,
    PreFetchDataForCarePlanPageQuery,
    useCreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
    useFetchWhatsNewTimelineForWhatsNewTimelineBuilderLazyQuery,
    usePreFetchProfileDefsForWhatsNewTimelineBuilderQuery,
    UserProfileValueType,
    useUpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation,
    useGetTimelinesQuery,
    GetTimelinesDocument,
    GetTimelinesQuery,
    WhatsNewTimelineType,
} from '~/schemaTypes';
import { TimelineEnum } from '~/selectors';
import DateEditor from '~/components/DateEditor/DateEditor';
import useStyles from './styles';
import validationSchema from './validationSchema';

type WhatsNewTimeline = NonNullable<
    FetchWhatsNewTimelineForWhatsNewTimelineBuilderQuery['whatsNewTimeline']
>;
type WhatsNewTimelineDescription = NonNullable<WhatsNewTimeline['description']>;

const TimelineBuilder: React.FC = () => {
    const { classes } = useStyles();
    const { id: timelineId } = useParams<{ id: string }>();
    const [showAppointmentType, setShowAppointmentType] = useState(false);
    const isEditMode = timelineId !== 'new';
    const history = useNavigate();
    const [close, setClose] = useState(false);
    const {
        control,
        watch,
        register,
        handleSubmit,
        reset,

        formState: { errors },
    } = useForm<WhatsNewTimeline>({
        // @ts-expect-error RHF V7 limitation #7895 will be fixed in V8 of react-hook-form
        resolver: yupResolver(validationSchema as any),
    });

    const watchedType = watch('type');

    const { data: preFetchData, loading: preFetchLoading } =
        usePreFetchProfileDefsForWhatsNewTimelineBuilderQuery({
            variables: {
                input: { filter: { fields: { valueType: UserProfileValueType.Date } } },
            },
        });
    const { data: timelines, loading: timelinesLoading } = useGetTimelinesQuery();

    const [fetchWhatsNewTimelineById, { loading: whatsNewTimelineLoading }] =
        useFetchWhatsNewTimelineForWhatsNewTimelineBuilderLazyQuery({
            onCompleted: data => {
                if (data.whatsNewTimeline) {
                    const { label, description, fixedTargetDate, targetDateProfileDefId, type } =
                        data.whatsNewTimeline;
                    reset({
                        label,
                        description,
                        fixedTargetDate,
                        targetDateProfileDefId,
                        type,
                    });
                }
            },
        });

    const [createWhatsNewTimeline, { loading: createWhatsNewTimelineLoading }] =
        useCreateWhatsNewTimelineForWhatsNewTimelineBuilderMutation({
            onCompleted: data => {
                if (close) history('/app-config/timelines/');
                else history(`/app-config/timelines/${data.createWhatsNewTimeline?.timeline?.id}`);
            },
            update: (cache, response) => {
                const createdWhatsNewTimeline = response.data?.createWhatsNewTimeline?.timeline;
                if (response.data?.createWhatsNewTimeline?.success && createdWhatsNewTimeline) {
                    const currentWhatsNewTimelines = cache.readQuery<GetTimelinesQuery>({
                        query: GetTimelinesDocument,
                    });
                    const preFetchDataForCarePlanPage =
                        cache.readQuery<PreFetchDataForCarePlanPageQuery>({
                            query: PreFetchDataForCarePlanPageDocument,
                        });
                    if (currentWhatsNewTimelines?.whatsNewTimelinesV2) {
                        cache.writeQuery<GetTimelinesQuery>({
                            query: GetTimelinesDocument,
                            data: {
                                whatsNewTimelinesV2: {
                                    __typename:
                                        currentWhatsNewTimelines.whatsNewTimelinesV2.__typename,
                                    results: [
                                        ...currentWhatsNewTimelines.whatsNewTimelinesV2.results,
                                        createdWhatsNewTimeline,
                                    ],
                                },
                            },
                        });
                    }
                    if (preFetchDataForCarePlanPage) {
                        const { amsApps, tags, whatsNewTimelines } = preFetchDataForCarePlanPage;
                        cache.writeQuery<PreFetchDataForCarePlanPageQuery>({
                            query: PreFetchDataForCarePlanPageDocument,
                            data: {
                                amsApps,
                                tags,
                                whatsNewTimelines: [...whatsNewTimelines, createdWhatsNewTimeline],
                            },
                        });
                    }
                }
            },
        });

    const [updateWhatsNewTimeline, { loading: updateWhatsNewTimelineLoading }] =
        useUpdateWhatsNewTimelineForWhatsNewTimelineBuilderMutation({
            onCompleted: () => {
                if (close) history('/app-config/timelines/');
            },
            update: (cache, response) => {
                const updatedWhatsNewTimeline = response.data?.updateWhatsNewTimeline?.timeline;
                if (response.data?.updateWhatsNewTimeline?.success && updatedWhatsNewTimeline) {
                    const currentWhatsNewTimelines = cache.readQuery<GetTimelinesQuery>({
                        query: GetTimelinesDocument,
                    });
                    if (currentWhatsNewTimelines?.whatsNewTimelinesV2) {
                        cache.writeQuery<GetTimelinesQuery>({
                            query: GetTimelinesDocument,
                            data: {
                                whatsNewTimelinesV2: {
                                    __typename:
                                        currentWhatsNewTimelines.whatsNewTimelinesV2.__typename,
                                    results: [
                                        ...currentWhatsNewTimelines.whatsNewTimelinesV2.results.map(
                                            timeline => {
                                                if (timeline.id === updatedWhatsNewTimeline.id) {
                                                    return updatedWhatsNewTimeline;
                                                }
                                                return timeline;
                                            },
                                        ),
                                    ],
                                },
                            },
                        });
                    }
                    const { label, description, fixedTargetDate, targetDateProfileDefId, type } =
                        updatedWhatsNewTimeline;
                    reset({
                        label,
                        description,
                        fixedTargetDate,
                        targetDateProfileDefId,
                        type,
                    });
                }
            },
        });

    useEffect(() => {
        if (isEditMode) {
            fetchWhatsNewTimelineById({
                variables: { whatsNewTimelineInput: { id: timelineId } },
            });
            setShowAppointmentType(watchedType === WhatsNewTimelineType.Appointment);
        } else {
            setShowAppointmentType(
                timelines?.whatsNewTimelinesV2.results.filter(
                    t => t.type === WhatsNewTimelineType.Appointment,
                ).length === 0,
            );
        }
    }, [fetchWhatsNewTimelineById, watchedType, timelineId, isEditMode, timelines]);

    if (
        whatsNewTimelineLoading ||
        preFetchLoading ||
        createWhatsNewTimelineLoading ||
        updateWhatsNewTimelineLoading ||
        timelinesLoading
    ) {
        return <Loading />;
    }

    const onSubmit = (data: WhatsNewTimeline) => {
        const { label, description, fixedTargetDate, type, targetDateProfileDefId } = data;
        const newTimeline = {
            label: {
                en: label.en,
                es: label.es,
            },
            description: description?.en
                ? { en: description?.en || '', es: description?.es }
                : null,
            type,
            targetDateProfileDefId:
                type === WhatsNewTimelineType.Profile ? targetDateProfileDefId : null,
            fixedTargetDate: type === WhatsNewTimelineType.Fixed ? fixedTargetDate : null,
        };
        if (isEditMode) {
            updateWhatsNewTimeline({
                variables: {
                    whatsNewTimelineUpdateInput: {
                        id: timelineId,
                        data: newTimeline,
                    },
                },
            });
        } else {
            createWhatsNewTimeline({
                variables: {
                    createWhatsNewTimelineInput: {
                        ...newTimeline,
                    },
                },
            });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button component={Link} to="/app-config/timelines" startIcon={<ArrowBack />}>
                    Back to Timelines
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography variant="h6" paragraph>
                        {isEditMode ? 'Edit' : 'Add'} Timeline
                    </Typography>
                    <Divider style={{ marginBottom: 20 }} />
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12}>
                                <ReactHookFormSelect
                                    control={control}
                                    name="type"
                                    variant="outlined"
                                    defaultValue=""
                                    label="Type *"
                                    fullWidth
                                    margin="dense"
                                    disabled={isEditMode}
                                    error={Boolean(errors.type)}
                                    data-test={TimelineEnum.TYPE_DROPDOWN}
                                >
                                    {showAppointmentType && (
                                        <MenuItem
                                            key={WhatsNewTimelineType.Appointment}
                                            value={WhatsNewTimelineType.Appointment}
                                            data-test={WhatsNewTimelineType.Appointment}
                                        >
                                            Appointment
                                        </MenuItem>
                                    )}
                                    <MenuItem
                                        key={WhatsNewTimelineType.Fixed}
                                        value={WhatsNewTimelineType.Fixed}
                                        data-test={WhatsNewTimelineType.Fixed}
                                    >
                                        Fixed Date
                                    </MenuItem>
                                    <MenuItem
                                        key={WhatsNewTimelineType.Profile}
                                        value={WhatsNewTimelineType.Profile}
                                        data-test={WhatsNewTimelineType.Profile}
                                    >
                                        Profile Value
                                    </MenuItem>
                                </ReactHookFormSelect>
                                {errors?.type && (
                                    <FormHelperText error className={classes.helperMessage}>
                                        {errors?.type?.message}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid container item xs={12}>
                                <OutlinedSection title="Label">
                                    <TextField
                                        variant="outlined"
                                        label="English *"
                                        fullWidth
                                        margin="dense"
                                        {...register('label.en')}
                                        error={Boolean(errors.label)}
                                        helperText={errors.label?.en?.message}
                                        data-test={TimelineEnum.LABEL}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Spanish"
                                        fullWidth
                                        margin="dense"
                                        {...register('label.es')}
                                    />
                                </OutlinedSection>
                            </Grid>
                            <Grid container item xs={12}>
                                <OutlinedSection title="Description">
                                    <TextField
                                        variant="outlined"
                                        label="English *"
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin="dense"
                                        {...register('description.en', { required: true })}
                                        error={Boolean(
                                            (
                                                errors.description as DeepMap<
                                                    WhatsNewTimelineDescription,
                                                    FieldError
                                                >
                                            )?.en,
                                        )}
                                        helperText={
                                            (
                                                errors.description as DeepMap<
                                                    WhatsNewTimelineDescription,
                                                    FieldError
                                                >
                                            )?.en?.message
                                        }
                                        data-test={TimelineEnum.DESCRIPTION}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Spanish"
                                        fullWidth
                                        multiline
                                        rows={5}
                                        margin="dense"
                                        {...register('description.es')}
                                    />
                                </OutlinedSection>
                            </Grid>

                            {watchedType === WhatsNewTimelineType.Profile && (
                                <Grid item xs={12}>
                                    <ReactHookFormSelect
                                        control={control}
                                        name="targetDateProfileDefId"
                                        variant="outlined"
                                        defaultValue=""
                                        label="Profile Definition *"
                                        fullWidth
                                        margin="dense"
                                        disabled={isEditMode}
                                        error={Boolean(errors.targetDateProfileDefId)}
                                    >
                                        {preFetchData?.userProfileDefsV2.results.map(
                                            ({ id, label }) => (
                                                <MenuItem key={id} value={id}>
                                                    {label?.en}
                                                </MenuItem>
                                            ),
                                        )}
                                    </ReactHookFormSelect>
                                    {errors?.targetDateProfileDefId && (
                                        <FormHelperText error className={classes.helperMessage}>
                                            {errors?.targetDateProfileDefId?.message as string}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            )}
                            {watchedType === WhatsNewTimelineType.Fixed && (
                                <Grid item xs={12}>
                                    <Controller
                                        name="fixedTargetDate"
                                        defaultValue={null}
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DateEditor
                                                    title="Date"
                                                    setSelectedDate={d => onChange(d)}
                                                    initialDate={value || new Date().toISOString()}
                                                />
                                            );
                                        }}
                                    />
                                    {errors?.fixedTargetDate && (
                                        <FormHelperText error className={classes.helperMessage}>
                                            {errors?.fixedTargetDate?.message as string}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <div style={{ width: '100%', textAlign: 'right' }}>
                                    <Button
                                        onClick={handleSubmit(onSubmit)}
                                        startIcon={<Save />}
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setClose(true);
                                            handleSubmit(onSubmit);
                                        }}
                                        startIcon={<Save />}
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        data-test={TimelineEnum.SAVE_AND_CLOSE}
                                    >
                                        Save &amp; Close
                                    </Button>
                                </div>
                            </Grid>
                        </form>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

export default TimelineBuilder;
