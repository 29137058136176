import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    OrderByDirectionEnum,
    useGetAdvocateTaskCheckListChangesForPatientTranscriptPageQuery,
    useGetChatMessagesForPatientTranscriptQuery,
    useGetPatientNotesForPatientTranscriptPageQuery,
    useGetProfileValueHistoriesByPatientIdForPatientTranscriptQuery,
    useGetSentMessageByPatientIdForPatientTranscriptQuery,
    useGetSurveyResponsesForPatientTranscriptQuery,
    useGetTrackerHistoryForPatientTranscriptQuery,
} from '~/schemaTypes';
import { displayDateWithLocalTimeZoneAbbr } from '~/helpers';
import { TrackerCard } from './TranscriptCards/TrackerCard/TrackerCard';
import { ProfileValueCard } from './TranscriptCards/ProfileValueCard/ProfileValueCard';
import { PatientNoteCard } from './TranscriptCards/PatientNoteCard/PatientNoteCard';
import { AdvocateTaskCard } from './TranscriptCards/AdvocateTaskCard/AdvocateTaskCard';
import { SurveyResponseCard } from './TranscriptCards/SurveyResponseCard/SurveyResponseCard';
import { ChatMessageCard } from './TranscriptCards/ChatMessageCard/ChatMessageCard';
import { SentMessageCard } from './TranscriptCards/SentMessageCard/SentMessageCard';

const CARD_TYPES = [
    'Tracker',
    'ProfileValue',
    'PatientNotes',
    'AdvocateTasks',
    'SurveyResponse',
    'ChatMessages',
    'SentComms',
] as const;

type CardTypes = { [P in (typeof CARD_TYPES)[number]]: boolean };

type RouteParams = {
    id: string;
};

export const PatientTranscript = () => {
    const { id: patientId } = useParams<RouteParams>();
    const [cardTypes, setCardTypes] = useState<CardTypes>(() => {
        const savedCardTypes = localStorage.getItem('patientTranscriptCardTypes');
        return savedCardTypes
            ? JSON.parse(savedCardTypes)
            : {
                  Tracker: true,
                  ProfileValue: true,
                  PatientNotes: true,
              };
    });
    const [dateRange, setDateRange] = useState<string>(() => {
        return localStorage.getItem('patientTranscriptDateRange') || 'lastDay';
    });

    const dateRangeFilter = useMemo(() => {
        const range: { gte: Date | null; lte: Date | null } = {
            gte: null,
            lte: null,
        };

        if (dateRange) {
            const now = new Date();
            switch (dateRange) {
                case 'lastDay':
                    range.gte = new Date(new Date().setDate(now.getDate() - 1));
                    range.lte = now;
                    break;
                case 'last3Days':
                    range.gte = new Date(new Date().setDate(now.getDate() - 3));
                    range.lte = now;
                    break;
                case 'last7Days':
                    range.gte = new Date(new Date().setDate(now.getDate() - 7));
                    range.lte = now;
                    break;
                case 'last30Days':
                    range.gte = new Date(new Date().setDate(now.getDate() - 30));
                    range.lte = now;
                    break;
                // case 'custom':
                //     range.gte = parsedFilters.customStartDate
                //         ? new Date(parsedFilters.customStartDate)
                //         : null;
                //     range.lte = parsedFilters.customEndDate
                //         ? new Date(parsedFilters.customEndDate)
                //         : null;
                //     break;
                default:
                    break;
            }
        }

        return {
            ...(range.gte ? { greaterThanDate: range.gte } : {}),
            ...(range.lte ? { lessThanDate: range.lte } : {}),
        };
    }, [dateRange]);

    const { data: trackerHistory, loading: loadingTrackerHistory } =
        useGetTrackerHistoryForPatientTranscriptQuery({
            variables: {
                input: {
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    patientId,
                    types: ['BloodPressureTrackerRecord', 'GlucoseTrackerRecord'],
                    ...dateRangeFilter,
                },
            },
        });

    const { data: profileValueHistory, loading: loadingProfileValueHistory } =
        useGetProfileValueHistoriesByPatientIdForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: patientNotes, loading: loadingPatientNotes } =
        useGetPatientNotesForPatientTranscriptPageQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: advocateTasks, loading: loadingAdvocateTasks } =
        useGetAdvocateTaskCheckListChangesForPatientTranscriptPageQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
            fetchPolicy: 'no-cache', // Cannot use cache because steps from same task templates have same stepId which causes steps to override each other.
        });

    const { data: surveyResponses, loading: loadingSurveyResponses } =
        useGetSurveyResponsesForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: chatMessages, loading: loadingChatMessages } =
        useGetChatMessagesForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: sentMessages, loading: loadingSentMessages } =
        useGetSentMessageByPatientIdForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const Cards = useMemo(() => {
        return [
            ...(cardTypes.Tracker
                ? trackerHistory?.getTrackerHistoryForPatientTranscript || []
                : []),
            ...(cardTypes.ProfileValue
                ? profileValueHistory?.getProfileValueHistoriesByPatientId || []
                : []),
            ...(cardTypes.PatientNotes
                ? patientNotes?.getPatientNotesForPatientTranscript || []
                : []),
            ...(cardTypes.AdvocateTasks
                ? advocateTasks?.getAdvocateTaskCheckListChangesForPatientTranscript || []
                : []),
            ...(cardTypes.SurveyResponse
                ? surveyResponses?.getSurveyResponsesForPatientTranscript || []
                : []),
            ...(cardTypes.ChatMessages
                ? chatMessages?.getChatMessagesForPatientTranscript || []
                : []),
            ...(cardTypes.SentComms ? sentMessages?.getSentMessageByPatientId || [] : []),
        ].sort((a, b) =>
            new Date(a?.createdAt).getTime() >= new Date(b?.createdAt).getTime() ? -1 : 1,
        );
    }, [
        trackerHistory?.getTrackerHistoryForPatientTranscript,
        profileValueHistory?.getProfileValueHistoriesByPatientId,
        patientNotes?.getPatientNotesForPatientTranscript,
        advocateTasks?.getAdvocateTaskCheckListChangesForPatientTranscript,
        surveyResponses?.getSurveyResponsesForPatientTranscript,
        chatMessages?.getChatMessagesForPatientTranscript,
        sentMessages?.getSentMessageByPatientId,
        cardTypes.Tracker,
        cardTypes.ProfileValue,
        cardTypes.PatientNotes,
        cardTypes.AdvocateTasks,
        cardTypes.SurveyResponse,
        cardTypes.ChatMessages,
        cardTypes.SentComms,
    ]);

    return (
        <div className="gap-2 flex flex-col">
            <div className="flex flex-row items-center justify-between">
                <Typography variant="h5">Patient Transcript</Typography>
                <div className="flex flex-row gap-2">
                    <FormControl fullWidth variant="standard">
                        <Select
                            labelId="card-types-label"
                            id="card-types-select"
                            variant="outlined"
                            multiple
                            value={CARD_TYPES.filter(type => cardTypes[type as keyof CardTypes])}
                            onChange={e => {
                                const selectedTypes = e.target.value as string[];
                                const newCardTypes = CARD_TYPES.reduce((acc, type) => {
                                    acc[type as keyof CardTypes] = selectedTypes.includes(type);
                                    return acc;
                                }, {} as CardTypes);
                                setCardTypes(newCardTypes);
                                localStorage.setItem(
                                    'patientTranscriptCardTypes',
                                    JSON.stringify(newCardTypes),
                                );
                            }}
                            renderValue={values => {
                                if (values.length === 0) {
                                    return <em>Select card types</em>;
                                }
                                if (values.length === 1) {
                                    return values[0];
                                }
                                return `${values[0]}...`;
                            }}
                            displayEmpty
                        >
                            {CARD_TYPES.map(type => (
                                <MenuItem key={type} value={type}>
                                    <Checkbox checked={cardTypes[type as keyof CardTypes]} />
                                    <ListItemText primary={type} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="date-range-label">Date Range</InputLabel>
                        <Select
                            className="w-[150px]"
                            labelId="date-range-label"
                            value={dateRange}
                            label="Date Range"
                            onChange={e => {
                                localStorage.setItem('patientTranscriptDateRange', e.target.value);
                                setDateRange(e.target.value);
                            }}
                        >
                            <MenuItem value="lastDay">Last Day</MenuItem>
                            <MenuItem value="last3Days">Last 3 Days</MenuItem>
                            <MenuItem value="last7Days">Last 7 Days</MenuItem>
                            <MenuItem value="last30Days">Last 30 Days</MenuItem>
                            {/* <MenuItem value="custom">Custom Range</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] h-full w-full p-8 rounded-md">
                {loadingTrackerHistory && cardTypes.Tracker && (
                    <Typography variant="body1">Loading tracker data...</Typography>
                )}
                {loadingProfileValueHistory && cardTypes.ProfileValue && (
                    <Typography variant="body1">Loading profile value data...</Typography>
                )}
                {loadingPatientNotes && cardTypes.PatientNotes && (
                    <Typography variant="body1">Loading patient notes data...</Typography>
                )}
                {loadingAdvocateTasks && cardTypes.AdvocateTasks && (
                    <Typography variant="body1">Loading advocate task data...</Typography>
                )}
                {loadingSurveyResponses && cardTypes.SurveyResponse && (
                    <Typography variant="body1">Loading survey response data...</Typography>
                )}
                {loadingChatMessages && cardTypes.ChatMessages && (
                    <Typography variant="body1">Loading chat message data...</Typography>
                )}
                {loadingSentMessages && cardTypes.SentComms && (
                    <Typography variant="body1">Loading sent comms data...</Typography>
                )}
                {Cards.map(card => (
                    <div className="w-[600px]" key={card?.id}>
                        {card?.__typename === 'TrackerHistory' && <TrackerCard tracker={card} />}
                        {card?.__typename === 'ProfileValueHistory' && (
                            <ProfileValueCard profileValue={card} />
                        )}
                        {card?.__typename === 'PatientNotes' && (
                            <PatientNoteCard patientNote={card} />
                        )}
                        {card?.__typename === 'AdvocateTask' && (
                            <AdvocateTaskCard advocateTask={card} />
                        )}
                        {card?.__typename === 'SurveyResponse' && (
                            <SurveyResponseCard surveyResponse={card} />
                        )}
                        {card?.__typename === 'ChatMessage' && (
                            <ChatMessageCard chatMessage={card} />
                        )}
                        {card?.__typename === 'SentMessage' && (
                            <SentMessageCard sentMessage={card} />
                        )}
                        <p className="flex justify-end italic">
                            {displayDateWithLocalTimeZoneAbbr({
                                isoDateStr: new Date(card?.createdAt).toISOString(),
                            })}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};
