import { makeStyles } from 'tss-react/mui';
import { Launch, PhoneIphone } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import {
    FetchConfigAppsForConfigAppsPageQuery,
    useFetchConfigAppsForConfigAppsPageQuery,
} from '~/schemaTypes';
import { CommonEnum } from '~/selectors';

const useStyles = makeStyles()(() => ({
    root: {
        root: {
            position: 'relative',
            height: '100%',
        },
    },
    logoImg: {
        width: '4em',
        height: '4em',
        borderRadius: '0.5em',
    },
    logoIconWrap: {
        width: '4em',
        height: '4em',
        borderRadius: '0.5em',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: 'rgba(148,181,236,.3)',
        color: '#7f7f7f',
    },
}));

const ConfigApps: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const { data: appsData, loading: appsDataLoading } = useFetchConfigAppsForConfigAppsPageQuery();

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} data-test={CommonEnum.CONFIG_APPS_CONTAINER} ref={tableRef}>
            <MaterialTable
                title="Config Apps"
                icons={tableIcons}
                isLoading={appsDataLoading}
                data={_.cloneDeep(appsData?.amsApps) ?? []}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actions={[
                    {
                        icon: () => <Launch />,
                        tooltip: 'View App Details',
                        onClick: (_e, { bundleId }: any) =>
                            history(`/app-config/summary/${btoa(bundleId)}`),
                    },
                ]}
                columns={[
                    {
                        cellStyle: {
                            width: '10%',
                            textAlign: 'left',
                        },
                        title: 'Logo',
                        field: 'logo',
                        render: ({
                            logo,
                            name,
                        }: FetchConfigAppsForConfigAppsPageQuery['amsApps'][0]) =>
                            logo ? (
                                <img
                                    src={logo}
                                    alt={`${name} app logo`}
                                    className={classes.logoImg}
                                />
                            ) : (
                                <div className={classes.logoIconWrap}>
                                    <PhoneIphone fontSize="large" />
                                </div>
                            ),
                    },
                    { title: 'Name', field: 'fullName', defaultSort: 'asc' },
                ]}
                options={{ pageSize: rowsPerPage }}
                localization={{ header: { actions: '' } }}
            />
        </div>
    );
};

export default ConfigApps;
