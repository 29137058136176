import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
} from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { Save as SaveIcon } from '@mui/icons-material';
import { AppItem, EditModalData } from './types';

const MaintenanceAppEditModal = ({
    app,
    onClose,
    onSubmit,
}: {
    app: AppItem;
    onClose: () => void;
    onSubmit: (data: EditModalData) => void;
}) => {
    const {
        handleSubmit,
        control,
        formState: { isDirty, isValid },
    } = useForm<EditModalData>({
        defaultValues: {
            id: app.id,
            isMaintenance: app.isMaintenance ?? false,
        },
    });

    return (
        <Dialog open>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleWithClose onClose={onClose} id="modalTitle">
                    <div>Edit App Maintenance</div>
                </DialogTitleWithClose>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Controller
                                name="isMaintenance"
                                control={control}
                                render={({ field: props }) => (
                                    <Checkbox
                                        {...props}
                                        checked={props.value}
                                        onChange={e => props.onChange(e.target.checked)}
                                    />
                                )}
                            />
                        }
                        label="Turn on Maintenance mode"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        disabled={!isDirty || !isValid}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default MaintenanceAppEditModal;
