import React, { useState, useMemo } from 'react';
import { Button, Grid, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import VirtualCareSurveyWrapper from '~/views/VirtualCareSurvey/components/VirtualCareSurveyWrapper';
import SuccessScreen from '~/views/VirtualCareSurvey/steps/SuccessScreen';

type PreviewSuccessScreenProps = {
    affiliateName?: string;
    customSuccessText: string;
};

const PreviewSuccessScreen: React.FC<PreviewSuccessScreenProps> = props => {
    const [showPreview, setShowPreview] = useState(false);
    const { affiliateName, customSuccessText } = props;
    const formattedSuccessText = useMemo(
        () => customSuccessText?.replaceAll('\n', '<br>'),
        [customSuccessText],
    );
    return (
        <>
            <Grid item xs={12}>
                <Button variant="text" onClick={() => setShowPreview(true)}>
                    <Typography variant="body1">
                        See what this looks like after a patient verifies their eligibility
                    </Typography>
                </Button>
            </Grid>
            <Dialog open={showPreview} fullScreen>
                <DialogTitleWithClose id="form-dialog-title" onClose={() => setShowPreview(false)}>
                    Preview
                </DialogTitleWithClose>
                <DialogContent dividers>
                    <VirtualCareSurveyWrapper
                        isMobileView={false}
                        showFormDescription
                        isAffiliateView
                        affiliateName={affiliateName}
                    >
                        <SuccessScreen
                            patientId="{patientId}"
                            customSuccessText={formattedSuccessText}
                        />
                    </VirtualCareSurveyWrapper>
                </DialogContent>
                <DialogActions>
                    <Grid item>
                        <Button
                            onClick={() => setShowPreview(false)}
                            color="primary"
                            variant="outlined"
                        >
                            Close
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PreviewSuccessScreen;
