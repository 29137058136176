import React, { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import useStyles from '../styles';

type HeaderProps = {
    showFormDescription: boolean;
    isMobileView: boolean;
    subHeading?: React.ReactNode;
    isAffiliateView: boolean;
};

const VC_FORM_DESCRIPTION = `
Securely provide your health information below. This will help us match you to
the right resources for you and your health experience. After you complete this
form, you can schedule your virtual appointment. We currently accept Cigna
insurance, at no additional cost for Cigna customers.`;

const AFFILIATE_FORM_DESCRIPTION = `
Securely provide your health information below. This will help us match you to
the right resources for you and your health experience. After you complete this form, you can schedule
 your lactation consult. We currently accept Cigna
insurance, at no additional cost for Cigna customers.`;

const Header: React.FC<HeaderProps> = props => {
    const { classes } = useStyles();
    const { showFormDescription, isMobileView, subHeading, isAffiliateView } = props;
    return (
        <Grid className={classes.imageContainer} item xs={12}>
            <Grid item xs={8} md={6} style={{ textAlign: 'center' }}>
                <img src="/logo-with-title.png" alt="logo" className={classes.image} />
            </Grid>
            {subHeading}
            {showFormDescription && (
                <Typography variant={isMobileView ? 'body2' : 'body1'}>
                    {isAffiliateView ? AFFILIATE_FORM_DESCRIPTION : VC_FORM_DESCRIPTION}
                </Typography>
            )}
        </Grid>
    );
};

export default memo(Header);
