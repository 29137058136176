import { Card, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { PhoneIphone, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { JsonViewer } from '@textea/json-viewer';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { AlertSeverity, useFetchConfigAppForConfigAppSummaryPageQuery } from '~/schemaTypes';
import { OnboardingScreenEnum } from '~/selectors';

import { TriggerGlobalAlert } from '~/state';
import ConfigAppSummaryOnboarding from './ConfigAppSummaryOnboarding/ConfigAppSummaryOnboarding';
import ConfigAppSummaryValues from './ConfigAppSummaryValues/ConfigAppSummaryValues';

const useStyles = makeStyles()(_theme => ({
    root: {},
    tabs: {},
    logoImg: {
        width: '5em',
        height: '5em',
        borderRadius: '0.5em',
    },
    logoIconWrap: {
        width: '5em',
        height: '5em',
        borderRadius: '0.5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: 'rgba(148,181,236,.3)',
        color: '#7f7f7f',
    },
}));

const ConfigAppSummary: React.FC = () => {
    const { classes } = useStyles();
    const { bundleId } = useParams<{ bundleId: string }>();
    const appBundleId = atob(bundleId || '');

    const [showConfig, setShowConfig] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const { data: appData, loading: appDataLoading } =
        useFetchConfigAppForConfigAppSummaryPageQuery({
            variables: { input: { bundleId: appBundleId ?? '' } },
        });
    if (appBundleId === undefined) {
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: '[ConfigAppSummary]: Bundle Id required.',
        });
    }
    if (appDataLoading) {
        return <Loading />;
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={showConfig ? 7 : 12}>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Card elevation={2}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        <Grid item xs={4}>
                                            {appData?.amsAppByBundleId.logo ? (
                                                <img
                                                    src={appData?.amsAppByBundleId.logo}
                                                    alt={`${appData?.amsAppByBundleId.name} app logo`}
                                                    className={classes.logoImg}
                                                />
                                            ) : (
                                                <div className={classes.logoIconWrap}>
                                                    <PhoneIphone fontSize="large" />
                                                </div>
                                            )}
                                        </Grid>
                                        <Tooltip
                                            title={
                                                showConfig
                                                    ? 'Hide Config Preview'
                                                    : 'Show Config Preview'
                                            }
                                        >
                                            <IconButton
                                                onClick={() => setShowConfig(current => !current)}
                                                size="large"
                                            >
                                                {showConfig ? (
                                                    <VisibilityOutlined />
                                                ) : (
                                                    <VisibilityOffOutlined />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item xs={4}>
                                            <Typography variant="h5">
                                                {appData?.amsAppByBundleId.name}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {appData?.amsAppByBundleId.bundleId}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" color="primary">
                                            Full Name
                                        </Typography>
                                        <Typography variant="body1">
                                            {appData?.amsAppByBundleId.fullName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            {appData?.amsAppByBundleId && (
                                <Grid item xs={12}>
                                    <Tabs
                                        value={currentTab}
                                        onChange={(e, newValue) => setCurrentTab(newValue)}
                                        aria-label="App Value Select"
                                    >
                                        <Tab label="App Values" />
                                        <Tab
                                            label="App Onboarding Screens"
                                            data-test={OnboardingScreenEnum.APP_ONBOARDING_SCREENS}
                                        />
                                    </Tabs>
                                    {currentTab === 0 && (
                                        <ConfigAppSummaryValues
                                            values={appData.amsAppByBundleId.values}
                                            valueDefs={appData.amsAppByBundleId.defaultValues}
                                            app={appData.amsAppByBundleId}
                                        />
                                    )}
                                    {currentTab === 1 && (
                                        <ConfigAppSummaryOnboarding
                                            app={appData.amsAppByBundleId}
                                            valueDefs={appData.amsAppByBundleId.defaultValues}
                                        />
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {showConfig && (
                    <Grid item xs={12} md={5}>
                        <Card>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Config Preview:</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <JsonViewer
                                        value={{
                                            config: appData?.amsAppByBundleId.config,
                                            tags: { 'West Coast': true },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ConfigAppSummary;
