/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    Card,
    CardHeader,
    Checkbox,
    Dialog,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add as AddIcon, ArrowBack, Save } from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import SortableList, {
    ListItemType,
    SortableListOnChangeProps,
} from '~/components/SortableList/SortableList';
import {
    TagUsageType,
    TrendTrigger,
    TrendTriggerListDocument,
    TrendTriggerListQuery,
    InterventionDateType,
    InterventionInput,
    InterventionListDocument,
    InterventionListQuery,
    useCreateTrendTriggerMutation,
    useDeleteInterventionMutation,
    useFetchTrendTriggerLazyQuery,
    useFetchMessageCenterTemplatesForInterventionQuery,
    useInterventionListQuery,
    usePreFetchAppsTagsQuery,
    useEventTriggerListQuery,
    useUpdateTrendTriggerMutation,
    useTagListQuery,
    useUpdateInterventionMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import _ from 'lodash';
import InterventionsModal from '../InterventionsModal';

type InterventionListItem = NonNullable<InterventionListQuery['interventionsV2']>['results'][0];
type InterventionListProfileValue = NonNullable<InterventionListItem['profileValue']>;

enum TrendTriggerType {
    EventTrigger = 'Event Trigger',
    Tag = 'Tag',
}

const Count = Array.from({ length: 9 }, (_, i) => i + 2);

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
    addInterventionButtonContainer: {
        margin: '20px 10px',
    },
}));

interface TrendTriggerFormInput {
    id?: string;
    name: string;
    eventTriggerId: string | null;
    tagId: string | null;
    trueCount: number;
    period: number;
    contiguous: boolean;
    resetOnRun: boolean;
}

const formValuesFromData = (item: TrendTrigger): TrendTriggerFormInput => {
    return {
        name: item.name,
        eventTriggerId: item.eventTriggerId,
        tagId: item.tagId,
        trueCount: item.trueCount,
        period: Number(item.period),
        contiguous: item.contiguous,
        resetOnRun: item.resetOnRun,
    };
};

const formValuesDefault = (trendTriggerId: string): TrendTriggerFormInput => {
    return {
        id: trendTriggerId,
        name: '',
        eventTriggerId: null,
        tagId: null,
        trueCount: 2,
        period: 1,
        contiguous: false,
        resetOnRun: false,
    };
};

const triggerFromForm = (values: TrendTriggerFormInput) => {
    return {
        name: values.name,
        eventTriggerId: values.eventTriggerId,
        tagId: values.tagId,
        trueCount: values.trueCount,
        period: Number(values.period),
        contiguous: values.contiguous,
        resetOnRun: values.resetOnRun,
    };
};

const TrendTriggerValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    eventTriggerId: Yup.string().nullable(),
    tagId: Yup.string().nullable(),
    trueCount: Yup.number().required('Required').min(2, 'Must be greater than 1'), // because 1 time can trigger common trigger
    period: Yup.number()
        .required('Required')
        .min(1, 'Must be greater than 0')
        .max(720, 'Must be less than 720'), // 720 hours = 30 days
    contiguous: Yup.boolean(),
    resetOnRun: Yup.boolean(),
});

const TrendTriggerEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: urlId } = useParams<{ id: string }>();
    const [showInterventionModal, setShowInterventionModal] = useState(false);
    const [trendTriggerType, setTrendTriggerType] = useState<TrendTriggerType>(
        TrendTriggerType.EventTrigger,
    );
    const [editInterventionId, setEditInterventionId] = useState('');
    const [isModified, setIsModified] = useState(false);
    const isEditMode = urlId !== 'new';
    const [trendTriggerId, setTrendTriggerId] = useState(
        isEditMode ? urlId : new ObjectId().toHexString(),
    );
    const [close, setClose] = useState(false);
    const [interventionPriority, setInterventionPriority] = useState(0);
    const [interventionSortableList, setInterventionSortableList] = useState<ListItemType[]>([]);

    const {
        control,
        register,
        reset,
        resetField,
        handleSubmit,
        formState: { errors },
    } = useForm<TrendTriggerFormInput>({
        resolver: yupResolver(TrendTriggerValidation as any),
        defaultValues: formValuesDefault(trendTriggerId ?? ''),
    });

    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsTagsQuery();

    const [fetchTrendTriggerById, { data: trendTriggerData, loading: trendTriggerLoading }] =
        useFetchTrendTriggerLazyQuery();

    const { data: portalTagsQueryData, loading: portalTagsLoading } = useTagListQuery({
        variables: { tagListInput: [TagUsageType.Trigger] },
    });

    const portalTags = portalTagsQueryData?.getTagListByUsageTypes;

    const { data: eventTriggerData, loading: eventTriggersLoading } = useEventTriggerListQuery({
        variables: { input: {} },
    });

    const eventTriggers = eventTriggerData?.eventTriggersV2.results;

    const { data: messageCenterTemplateList, loading: messageCenterTemplatesLoading } =
        useFetchMessageCenterTemplatesForInterventionQuery();

    const [updateTrendTrigger, { loading: updateTrendTriggerLoading }] =
        useUpdateTrendTriggerMutation({
            onCompleted: () => {
                if (close) history(`/app-config/trendtriggers`);
            },
            update: (cache, response) => {
                const updatedItem = response.data?.updateTrendTrigger?.resourceUpdated;
                if (response.data?.updateTrendTrigger?.success && updatedItem) {
                    const currentItems = cache.readQuery<TrendTriggerListQuery>({
                        query: TrendTriggerListDocument,
                    });
                    if (currentItems?.trendTriggersV2.results) {
                        cache.writeQuery<TrendTriggerListQuery>({
                            query: TrendTriggerListDocument,
                            data: {
                                trendTriggersV2: {
                                    results: [
                                        ...currentItems.trendTriggersV2.results.map(item => {
                                            if (item.id === updatedItem.id) {
                                                return updatedItem;
                                            }
                                            return item;
                                        }),
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        });

    const [createTrendTrigger, { loading: createTrendTriggerLoading }] =
        useCreateTrendTriggerMutation({
            onCompleted: data => {
                if (close) history(`/app-config/trendtriggers`);
                else
                    history(
                        `/app-config/trendtriggers/${data.createTrendTrigger?.resourceCreated?.id}`,
                    );
            },
            update: (cache, response) => {
                const newItem = response.data?.createTrendTrigger?.resourceCreated;
                setTrendTriggerId(newItem?.id);
                if (response.data?.createTrendTrigger?.success && newItem) {
                    const currentItems = cache.readQuery<TrendTriggerListQuery>({
                        query: TrendTriggerListDocument,
                    });
                    if (currentItems?.trendTriggersV2.results) {
                        cache.writeQuery<TrendTriggerListQuery>({
                            query: TrendTriggerListDocument,
                            data: {
                                trendTriggersV2: {
                                    __typename: currentItems.trendTriggersV2.__typename,
                                    results: [...currentItems.trendTriggersV2.results, newItem],
                                },
                            },
                        });
                    }
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: TrendTriggerListDocument,
                    variables: {
                        input: {},
                    },
                },
            ],
        });

    const { data: interventionData } = useInterventionListQuery({
        variables: {
            interventionListInput: { filter: { fields: { trendTriggerId } } },
        },
    });
    const [deleteIntervention] = useDeleteInterventionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: InterventionListDocument,
                variables: {
                    interventionListInput: { filter: { fields: { trendTriggerId } } },
                },
            },
        ],
    });

    const [updateIntervention, { loading: updateInterventionLoading }] =
        useUpdateInterventionMutation();

    const getInterventionData = (intervention: InterventionListItem) => {
        const {
            advocateTaskTemplateId,
            choicesToUpdate,
            messageCenterTemplateId,
            type,
            conditionTypeId,
            profileDefId,
            profileValue,
            dateType,
            tagId,
        }: InterventionInput = intervention;

        const { bool, num, str, date, choices, choiceId }: any = profileValue;

        return {
            advocateTaskTemplateId,
            choicesToUpdate,
            messageCenterTemplateId,
            type,
            conditionTypeId,
            profileDefId,
            profileValue: { bool, num, str, date, choices, choiceId },
            dateType,
            tagId,
            priority: interventionSortableList.findIndex(item => item.id === intervention.id),
        };
    };

    const updateInterventions = async () => {
        const interventions: InterventionListQuery['interventionsV2']['results'] | undefined =
            interventionData?.interventionsV2?.results;
        if (!interventions) return;
        await Promise.all(
            interventions.map(intervention => {
                return updateIntervention({
                    variables: {
                        updateInterventionInput: {
                            id: intervention.id,
                            data: getInterventionData(intervention),
                        },
                    },
                });
            }),
        );
    };

    const onSubmit = async (values: TrendTriggerFormInput) => {
        const data = triggerFromForm(values);
        if (isEditMode && trendTriggerId) {
            await updateTrendTrigger({
                variables: {
                    input: {
                        id: trendTriggerId,
                        data: {
                            ...data,
                        },
                    },
                },
            });
            await updateInterventions();
        } else {
            await createTrendTrigger({
                variables: {
                    input: {
                        ...data,
                    },
                },
            });
            await updateInterventions();
        }
        setIsModified(false);
    };

    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Trend Trigger List?`,
                callback: () => {
                    history(`/app-config/trendtriggers/`);
                },
            });
        else history(`/app-config/trendtriggers/`);
    };

    const onEdit = () => {
        setIsModified(true);
    };

    const handleAddIntervention = () => {
        setInterventionPriority(interventionSortableList.length);
        setShowInterventionModal(true);
    };

    const handleEditIntervention = (listItem: ListItemType, index: number) => {
        setShowInterventionModal(true);
        setInterventionPriority(index);
        setEditInterventionId(listItem.id as string);
    };

    const handleChangeInterventions = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        const interventionistClone = [...interventionSortableList];
        const element = interventionistClone[startIndex];
        interventionistClone.splice(startIndex, 1);
        interventionistClone.splice(endIndex, 0, element);
        setInterventionSortableList(interventionistClone);
    };

    const handleDeleteIntervention = (id: string, index: number) => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Intervention?',
            callback: async () => {
                await deleteIntervention({ variables: { input: { id } } });
                setInterventionSortableList(
                    interventionSortableList.filter((_, groupIndex) => groupIndex !== index),
                );
            },
        });
    };

    const handleDeleteInterventionItemClick = (index: number) => {
        handleDeleteIntervention(interventionSortableList[index].id as string, index);
    };

    const formatTargetDate = (dateString: string) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');

        return `${month}/${day}/${year}`;
    };

    const renderTarget = useCallback(
        ({
            conditionType,
            profileDef,
            messageCenterTemplateId,
            messageTemplateId,
            messageTemplate,
            profileValue,
            dateType,
            advocateTaskTemplate,
        }: InterventionListItem): string => {
            const interventionProfileValue = { ...profileValue };
            if (conditionType) {
                return conditionType.name.en;
            }
            if (messageTemplateId) {
                return messageTemplate?.name ?? messageTemplateId;
            }
            if (messageCenterTemplateId) {
                return (
                    messageCenterTemplateList?.messageCenterTemplates.filter(
                        template => template.id === messageCenterTemplateId,
                    )?.[0]?.title ?? ''
                );
            }
            if (advocateTaskTemplate) {
                return advocateTaskTemplate.label;
            }

            if (!interventionProfileValue || !profileDef || !profileDef.choices) {
                return '';
            }

            const { choiceId, date, str, num, bool } = interventionProfileValue;
            const { valueType } = profileDef;
            const profileDefName = profileDef?.name;
            // Check if profile value is a string
            if (str) {
                return `${profileDefName}: ${str}`;
            }
            // Check if profile value is a number
            if (num) {
                return `${profileDefName}: ${num}`;
            }
            // Check if profile value is a date and is Today
            if (dateType === InterventionDateType.Today && valueType === 'date') {
                return `${profileDefName}: Today`;
            }
            // Check if profile value is a boolean
            if (bool !== null && dateType !== InterventionDateType.Fixed) {
                return `${profileDefName}: ${bool ? 'Yes' : 'No'}`;
            }
            interventionProfileValue.bool = null;
            // if the profile value is a fixed date
            try {
                const [[selectedKey]] = Object.entries(interventionProfileValue).filter(
                    ([, value]) => value !== 'InterventionProfileValue' && value !== null,
                );

                if (!choiceId) {
                    const value =
                        interventionProfileValue[selectedKey as keyof InterventionListProfileValue];
                    return `${profileDefName}: ${date ? formatTargetDate(value as string) : value}`;
                }
            } catch (e) {
                throw new Error('Invalid profile value');
            }

            const [selectedChoice] = profileDef.choices.filter(choice => choice?.id === choiceId);
            const choiceLabel = selectedChoice?.label?.en;

            return `${profileDefName}: ${choiceLabel}`;
        },
        [messageCenterTemplateList?.messageCenterTemplates],
    );

    const getInterventionSortableList = useCallback(
        (interventionData: InterventionListQuery | undefined): ListItemType[] => {
            const data = interventionData?.interventionsV2?.results;
            return data
                ? data
                      .map(item => ({
                          id: item.id,
                          label: {
                              en: `Type: ${item.type}. Target: ${renderTarget(item)}`,
                              es: item.type,
                          },
                          priority: item.priority as number,
                      }))
                      .sort((prev, next) => (prev.priority > next.priority ? 1 : -1))
                : [];
        },
        [renderTarget],
    );

    useEffect(() => {
        if (trendTriggerType === TrendTriggerType.EventTrigger) {
            resetField('tagId');
        } else {
            resetField('eventTriggerId');
        }
    }, [resetField, trendTriggerType]);

    useEffect(() => {
        if (isEditMode) {
            fetchTrendTriggerById({ variables: { input: { id: trendTriggerId } } });
            if (trendTriggerData && trendTriggerData.trendTrigger) {
                reset(formValuesFromData(trendTriggerData.trendTrigger));
                if (trendTriggerData.trendTrigger.tagId) {
                    setTrendTriggerType(TrendTriggerType.Tag);
                } else {
                    setTrendTriggerType(TrendTriggerType.EventTrigger);
                }
            }
            setInterventionSortableList(getInterventionSortableList(interventionData));
        }
    }, [
        fetchTrendTriggerById,
        isEditMode,
        trendTriggerId,
        trendTriggerData,
        reset,
        getInterventionSortableList,
        interventionData,
    ]);

    if (
        trendTriggerLoading ||
        updateTrendTriggerLoading ||
        preFetchLoading ||
        messageCenterTemplatesLoading ||
        !preFetchData ||
        createTrendTriggerLoading ||
        portalTagsLoading ||
        eventTriggersLoading
    )
        return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Trend Trigger List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader
                            title={`${
                                isEditMode ? 'Edit' : 'Create'
                            } Trend Trigger and Interventions`}
                        />
                        <OutlinedSection title="Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                            />
                        </OutlinedSection>
                        <Grid item xs={12}>
                            <Autocomplete
                                size="small"
                                onChange={(_, val) => {
                                    setTrendTriggerType(val || TrendTriggerType.EventTrigger);
                                }}
                                getOptionLabel={selected => selected}
                                options={Object.values(TrendTriggerType)}
                                isOptionEqualToValue={(option, val) => option === val}
                                value={trendTriggerType}
                                disabled={isEditMode}
                                renderInput={params => (
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        placeholder="Select Type"
                                        label="Type"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        {trendTriggerType === TrendTriggerType.EventTrigger && (
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="eventTriggerId"
                                    defaultValue=""
                                    render={({ field: { value, onChange } }) => (
                                        <Autocomplete
                                            size="small"
                                            onChange={(_, val) => {
                                                onChange(val);
                                            }}
                                            options={
                                                _.sortBy(eventTriggers, 'name')?.map(
                                                    (item: any) => item.id,
                                                ) ?? []
                                            }
                                            getOptionLabel={id =>
                                                eventTriggers?.find((item: any) => item.id === id)
                                                    ?.name ?? ''
                                            }
                                            disabled={isEditMode}
                                            isOptionEqualToValue={(option, val) => option === val}
                                            value={value}
                                            renderInput={params => (
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...params}
                                                    placeholder="Select Event Trigger"
                                                    label="Event Trigger"
                                                    error={!!errors.eventTriggerId}
                                                    helperText={errors.eventTriggerId?.message}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        {trendTriggerType === TrendTriggerType.Tag && (
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="tagId"
                                    defaultValue=""
                                    render={({ field: { value, onChange } }) => (
                                        <Autocomplete
                                            size="small"
                                            onChange={(_, val) => {
                                                onChange(val);
                                            }}
                                            options={
                                                _.sortBy(portalTags, 'name')?.map(
                                                    (item: any) => item.id,
                                                ) ?? []
                                            }
                                            getOptionLabel={id =>
                                                portalTags?.find((item: any) => item.id === id)
                                                    ?.name ?? ''
                                            }
                                            disabled={isEditMode}
                                            isOptionEqualToValue={(option, val) => option === val}
                                            value={value}
                                            renderInput={params => (
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...params}
                                                    placeholder="Select Tag"
                                                    label="Tag"
                                                    error={!!errors.tagId}
                                                    helperText={errors.tagId?.message}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            <ReactHookFormSelect
                                control={control}
                                name="trueCount"
                                variant="outlined"
                                label="Total Count of Trend items *"
                                margin="dense"
                                fullWidth
                                defaultValue=""
                            >
                                {Count.map(i => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Period Hours *"
                                margin="dense"
                                fullWidth
                                {...register('period')}
                                style={{ marginRight: '10px' }}
                                InputProps={{
                                    inputProps: { min: 0, step: 1, max: 720 },
                                }}
                                error={!!errors.period}
                                helperText={errors.period?.message}
                                defaultValue={1}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="contiguous"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        label="Contiguous?"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                id="contiguous"
                                                checked={value === true}
                                                onChange={e => {
                                                    onChange(e.target.checked);
                                                    onEdit();
                                                }}
                                            />
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="resetOnRun"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        label="Reset on Run?"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                id="resetOnRun"
                                                checked={value === true}
                                                onChange={e => {
                                                    onChange(e.target.checked);
                                                    onEdit();
                                                }}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {isEditMode && (
                            <Grid item className={classes.addInterventionButtonContainer}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleAddIntervention()}
                                    startIcon={<AddIcon />}
                                >
                                    Add Intervention
                                </Button>
                            </Grid>
                        )}
                        {!updateInterventionLoading &&
                        interventionSortableList &&
                        interventionSortableList.length ? (
                            <Grid item>
                                <OutlinedSection title="Interventions">
                                    <SortableList
                                        list={interventionSortableList}
                                        droppableId="interventionOrder"
                                        onChange={handleChangeInterventions}
                                        onClick={handleEditIntervention}
                                        onDelete={handleDeleteInterventionItemClick}
                                    />
                                </OutlinedSection>
                            </Grid>
                        ) : null}

                        <div style={{ width: '100%', marginTop: '10px', textAlign: 'right' }}>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    setClose(true);
                                    handleSubmit(onSubmit);
                                }}
                            >
                                Save &amp; Close
                            </Button>
                        </div>
                    </Card>
                </form>
            </Grid>
            <Dialog scroll="body" open={showInterventionModal} aria-labelledby="form-dialog-title">
                <InterventionsModal
                    setOpen={setShowInterventionModal}
                    setEditIntervention={setEditInterventionId}
                    id={editInterventionId}
                    trendTriggerId={trendTriggerId}
                    interventionPriority={interventionPriority}
                />
            </Dialog>
        </Grid>
    );
};

export default TrendTriggerEditor;
