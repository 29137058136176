import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, Delete as DeleteIcon, Search } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
    AlertSeverity,
    CreateNewOrderMutation,
    Patient,
    useCreateNewOrderMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import PatientSearchModal from '~/components/PatientsSearchModal';

import OrderModal from '../OrderModal/OrderModal';
import DiagnosesModal from './DiagnosesModal';
import { NOTES } from './enums/NOTES';
import { PROCEDURES } from './enums/PROCEDURES';
import { PROCEDURES_DESCRIPTION } from './enums/PROCEDURES_DESCRIPTION';
import { STATUS } from './enums/STATUS';
import { generateFormSchema, NewOrderSections } from './validations';

interface IFontSizeOption {
    type: string;
    label: string;
}

export interface INewDiagnose {
    id: string;
    code: string;
    codeSet: string;
    type: string;
    name: string;
    dateTime: string;
}

export interface AddPatientFormInput {
    providerNPI: string;
    comments: string;
    providerFirstName: string;
    providerLastName: string;
    prescribeDate: string;
}

interface INewOrderProps {
    patient?: Patient | null;
}

const useStyles = (params: { diagnoses: INewDiagnose[] }) =>
    makeStyles()(theme => ({
        titleWrapper: {
            fontSize: '20px',
        },
        searchPatient: {
            marginRight: theme.spacing(1),
        },
        selectedPatient: {
            color: 'gray',
            border: '1px solid gray',
            borderRadius: '3px',
            padding: '5px',
            boxSizing: 'border-box',
            fontWeight: 600,
        },
        tableHead: {
            backgroundColor: theme.palette.common.black,
            '& > tr > th': {
                color: theme.palette.common.white,
                border: `1px solid ${theme.palette.common.white}`,
            },
        },
        notes: {
            padding: 0,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        addDiagnoseBtn: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
        },
        diagnosesWrap: {
            width: params.diagnoses.length > 0 ? '100%' : '80%',
            marginTop: theme.spacing(2),
            paddingLeft: '10px',
        },
        diagnosesTitle: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '20px',
        },
        form: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    }));

const selectOptions: IFontSizeOption[] = [
    {
        type: 'regular',
        label: 'Regular',
    },
    {
        type: 'large',
        label: 'Large',
    },
];

const CreateNewOrder: React.FC<INewOrderProps> = ({ patient = null }) => {
    const history = useNavigate();

    const [showPatientSearchModal, setShowPatientSearchModal] = useState<boolean>(false);
    const [showDiagnosesModal, setShowDiagnosesModal] = useState<boolean>(false);
    const [createdOrderData, setCreatedOrderData] = useState<CreateNewOrderMutation | null>(null);

    const [patientsList, setPatientsList] = useState<Patient[]>([]);
    const [totalPatientsCount, setTotalPatientsCount] = useState<number>(0);
    const [patientsNotFound, setPatientsNotFound] = useState<boolean>(false);

    const [selectedPatient, setSelectedPatient] = useState<Patient | null>(patient);
    const [isBpCuffDeviceChecked, setIsBpCuffDeviceChecked] = useState<boolean>(false);
    const [isGlucometerDeviceChecked, setIsGlucometerDeviceChecked] = useState<boolean>(false);
    const [fontSizeOption, setFontSizeOption] = useState<IFontSizeOption>(selectOptions[0]);
    const [diagnoses, setDiagnoses] = useState<INewDiagnose[]>([]);

    const { classes } = useStyles({ diagnoses })();

    const patientSearchMethods = useForm<AddPatientFormInput>({
        resolver: yupResolver(generateFormSchema(NewOrderSections.PatientAdditionalInfo) as any),
    });
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = patientSearchMethods;

    const resetFullState = () => {
        setPatientsList([]);
        setTotalPatientsCount(0);
        setPatientsNotFound(false);
        setSelectedPatient(null);
        setIsBpCuffDeviceChecked(false);
        setIsGlucometerDeviceChecked(false);
        setFontSizeOption(selectOptions[0]);
        setDiagnoses([]);
    };

    const [createNewOrder, { loading: createNewOrderLoading }] = useCreateNewOrderMutation({
        onCompleted: data => {
            setCreatedOrderData(data);
            resetFullState();
            reset();
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: 'Order has been created',
            });
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
    });

    useEffect(() => {
        if (!showPatientSearchModal) {
            setPatientsList([]);
        }
    }, [showPatientSearchModal]);

    const handleSelect = (type: string) => {
        const selectedOption = selectOptions.find(option => option.type === type);
        if (selectedOption) {
            setFontSizeOption(selectedOption);
        }
    };

    const addNewDiagnose = (diagnose: INewDiagnose) => {
        setDiagnoses(prev => [...prev, diagnose]);
    };

    const deleteDiagnose = (diagnoseId: string) => {
        setDiagnoses(diagnoses => diagnoses.filter(diagnose => diagnose.id !== diagnoseId));
    };

    const onSubmit = ({
        providerNPI,
        providerFirstName,
        providerLastName,
        comments,
        prescribeDate,
    }: AddPatientFormInput) => {
        if (!selectedPatient) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Please select a patient',
            });
            return;
        }
        const CUFF_SIZE_VALUE = `${NOTES.CUFF_SIZE}${fontSizeOption.label}`;

        const notes = [
            isBpCuffDeviceChecked ? NOTES.BP_CUFF : null,
            isGlucometerDeviceChecked ? NOTES.GLUCOMETER : null,
            CUFF_SIZE_VALUE,
        ].filter(note => note !== null);

        const mappedDiagnoses = diagnoses.length
            ? diagnoses.map(diagnose => ({
                  name: diagnose.name,
                  code: diagnose.code,
                  type: diagnose.type,
                  codeset: diagnose.codeSet,
                  documentedDateTime: moment.utc(diagnose.dateTime).toISOString(),
              }))
            : [];

        createNewOrder({
            variables: {
                input: {
                    patientId: selectedPatient?.id,
                    status: STATUS.NEW,
                    isDeviceOrder: true,
                    providerNPI,
                    providerFirstName,
                    providerLastName,
                    procedure: PROCEDURES.WFH101,
                    procedureDescription: PROCEDURES_DESCRIPTION[PROCEDURES.WFH101],
                    notes,
                    diagnoses: mappedDiagnoses,
                    comments,
                    transactionDateTime: moment.utc(prescribeDate).toISOString(),
                },
            },
        });
    };

    const handleShowPatientSearchModal = () => {
        setShowPatientSearchModal(true);
    };

    const handleShowDiagnosesModal = () => {
        setShowDiagnosesModal(true);
    };

    const closeCreatedOrderModal = () => {
        setCreatedOrderData(null);
        history(patient ? `/portal/patients/${patient.id}/orders` : '/portal/orders');
    };

    return (
        <>
            <Grid>
                <Grid item className={classes.titleWrapper}>
                    <Typography paragraph variant="h3">
                        Add New Order
                    </Typography>
                </Grid>
                {!patient && (
                    <Grid container alignItems="center">
                        <Grid item className={classes.searchPatient}>
                            <Typography>Patient Name</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleShowPatientSearchModal} size="large">
                                <Search />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid>
                {selectedPatient ? (
                    <Typography className={classes.selectedPatient}>
                        {selectedPatient.firstName} {selectedPatient.lastName}{' '}
                        {moment(selectedPatient.birthDate).format('(MM-DD-YYYY)')}
                    </Typography>
                ) : null}
            </Grid>
            <Grid container wrap="wrap" alignItems="center" spacing={3} className={classes.notes}>
                <Grid item>
                    <FormControlLabel
                        label="BP Cuff Order"
                        labelPlacement="start"
                        control={
                            <Checkbox
                                id="isBpCuffOrder"
                                name="isBpCuffOrder"
                                checked={isBpCuffDeviceChecked}
                                onChange={e => setIsBpCuffDeviceChecked(e.target.checked)}
                            />
                        }
                    />
                </Grid>

                <Grid>
                    <FormControl variant="outlined" fullWidth>
                        <Select
                            variant="outlined"
                            defaultValue={selectOptions[0].type}
                            onChange={({ target }) => {
                                handleSelect(String(target.value));
                            }}
                        >
                            {selectOptions.map(({ type, label }) => (
                                <MenuItem key={type} value={type}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControlLabel
                        label="Glucometer Order"
                        labelPlacement="start"
                        control={
                            <Checkbox
                                id="isGlucometerOrderChecked"
                                name="isGlucometerOrderChecked"
                                checked={isGlucometerDeviceChecked}
                                onChange={e => setIsGlucometerDeviceChecked(e.target.checked)}
                            />
                        }
                    />
                </Grid>
            </Grid>

            <Grid>
                <Grid container alignItems="center" spacing={3} className={classes.diagnosesWrap}>
                    <Grid item className={classes.diagnosesTitle}>
                        <Typography>Diagnoses</Typography>
                    </Grid>
                    <Grid item className={classes.addDiagnoseBtn}>
                        <Add
                            className={classes.addDiagnoseBtn}
                            fontSize="large"
                            onClick={handleShowDiagnosesModal}
                        />
                    </Grid>
                </Grid>

                {diagnoses.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell align="center" />
                                    <TableCell align="center">Code</TableCell>
                                    <TableCell align="center">Code Set</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Type</TableCell>
                                    <TableCell align="center">Documented Date Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {diagnoses.map(diagnose => {
                                    const dateTime = moment
                                        .utc(diagnose.dateTime)
                                        .local()
                                        .format('YYYY-MM-DD HH:mm:ss');

                                    return (
                                        <TableRow key={diagnose.id}>
                                            <TableCell align="center">
                                                <DeleteIcon
                                                    onClick={() => deleteDiagnose(diagnose.id)}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{diagnose.code}</TableCell>
                                            <TableCell align="center">{diagnose.codeSet}</TableCell>
                                            <TableCell align="center">{diagnose.name}</TableCell>
                                            <TableCell align="center">{diagnose.type}</TableCell>
                                            <TableCell align="center">{dateTime}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>

            <Grid className={classes.form}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid>
                        <TextField
                            id="outlined-multiline-static"
                            label="Comments"
                            multiline
                            rows={10}
                            fullWidth
                            variant="outlined"
                            {...register('comments')}
                            error={!!errors.comments}
                            helperText={errors.comments?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            id="ProviderNPI"
                            label="Provider NPI"
                            type="text"
                            margin="dense"
                            fullWidth
                            error={!!errors.providerNPI}
                            helperText={errors.providerNPI?.message}
                            {...register('providerNPI')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            id="ProviderFirstName"
                            label="Provider First Name"
                            type="text"
                            margin="dense"
                            fullWidth
                            error={!!errors.providerFirstName}
                            helperText={errors.providerFirstName?.message}
                            {...register('providerFirstName')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            id="ProviderLastName"
                            label="Provider Last Name"
                            type="text"
                            margin="dense"
                            fullWidth
                            error={!!errors.providerLastName}
                            helperText={errors.providerLastName?.message}
                            {...register('providerLastName')}
                        />
                    </Grid>

                    <Grid>
                        <Controller
                            control={control}
                            name="prescribeDate"
                            render={({ field }) => (
                                <DateTimePicker
                                    {...field}
                                    label="Prescribe Date"
                                    ampm={false}
                                    minutesStep={5}
                                    format="MM/dd/yyyy hh:mm"
                                />
                            )}
                        />
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined">
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={createNewOrderLoading}
                            >
                                Create
                            </Button>
                        </FormControl>
                    </Grid>
                </form>
            </Grid>

            <Dialog scroll="paper" open={showPatientSearchModal} fullWidth>
                <PatientSearchModal
                    patientsList={patientsList}
                    totalPatientsCount={totalPatientsCount}
                    selectedPatient={selectedPatient}
                    setPatientsList={setPatientsList}
                    setShowPatientSearchModal={setShowPatientSearchModal}
                    setTotalPatientsCount={setTotalPatientsCount}
                    setSelectedPatient={setSelectedPatient}
                    patientsNotFound={patientsNotFound}
                    setPatientsNotFound={setPatientsNotFound}
                />
            </Dialog>

            <Dialog scroll="paper" open={showDiagnosesModal} fullWidth>
                <DiagnosesModal
                    setShowDiagnosesModal={setShowDiagnosesModal}
                    addNewDiagnose={addNewDiagnose}
                />
            </Dialog>

            {createdOrderData && (
                <Dialog scroll="paper" open={Boolean(createdOrderData)} fullWidth maxWidth="lg">
                    <OrderModal
                        setOpen={closeCreatedOrderModal}
                        orderId={createdOrderData?.createOrder?.resourceCreated?.id}
                    />
                </Dialog>
            )}
        </>
    );
};

export default CreateNewOrder;
