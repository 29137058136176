import { NetworkStatus } from '@apollo/client';
import {
    faFileExport,
    faInfoCircle,
    faPenToSquare,
    faRectangleList,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Autocomplete, Button, Checkbox, Collapse, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { useUserPermissions } from '~/hooks';
import {
    AmsProduct,
    TagUsageType,
    TimelineViewerEntryType,
    TimeUnit,
    useGetTimelinesLazyQuery,
    usePreFetchAppsLazyQuery,
    useTagListLazyQuery,
    useTimelineViewerFetchLazyQuery,
} from '~/schemaTypes';

import { ListItem } from '../Articles/Editors/ArticleEditor';
import { EditTimelineModal } from './components/EditTimelineModal';
import { getTimelineWorkbook } from './components/TimelineExportExcel';
import { ViewTimelineModal } from './components/ViewTimelineModal';

const useStyles = makeStyles()({
    select: {
        padding: '5px',
        margin: '5px',
        border: 'Solid 1px #ccc',
    },
    accordian: {
        cursor: 'pointer',
    },
    loader: {
        textAlign: 'center',
    },
    export: {
        textAlign: 'right',
    },
    tableCell: { verticalAlign: 'top', border: '1px solid #ccc', padding: '3px' },
});

type selectedTimeline = {
    timelineId: string;
    name: string;
    zeroDate?: string;
    offset: number;
};

export type selectedItem = {
    type: TimelineViewerEntryType;
    itemId: string;
    groupId?: string;
    templateId?: string;
    offsetDays?: number;
    offsetMonths?: number;
    offsetYears?: number;
    duration?: number;
    durationTimeUnit: TimeUnit;
    description: string;
    timelineId: string;
    timelineName: string;
};

type filterSettings = {
    timelines: selectedTimeline[];
    types: TimelineViewerEntryType[];
    tags: ListItem[];
    appBundleId?: string;
    limit: number;
};

const saveSettings = (settings: filterSettings) => {
    sessionStorage.setItem('timelineFilters', JSON.stringify(settings));
};

const getSettings = (): filterSettings => {
    const settings = sessionStorage.getItem('timelineFilters');
    if (settings) return JSON.parse(settings);
    return { timelines: [], types: [], tags: [], appBundleId: undefined, limit: 50 };
};

export const formatOffset = (days: number, months: number, years: number): string => {
    const values: string[] = [];
    if (days !== 0 || days + months + years === 0)
        values.push(`${days} day${Math.abs(days) === 1 ? '' : 's'}`);
    if (months !== 0) values.push(`${months} month${Math.abs(months) === 1 ? '' : 's'}`);
    if (years !== 0) values.push(`${years} year${Math.abs(years) === 1 ? '' : 's'}`);
    return values.join(',');
};
export const formatDuration = (duration?: number | null, timeUnit?: TimeUnit | null): string => {
    if (duration && timeUnit) {
        return `${duration} ${
            duration === 1 ? timeUnit.substring(0, timeUnit.length - 1) : timeUnit
        }`;
    }
    return '';
};
export const formatEffectiveDate = (effectiveDate: Date): string => {
    if (effectiveDate) {
        effectiveDate = typeof effectiveDate === 'string' ? new Date(effectiveDate) : effectiveDate;
        return `${moment(effectiveDate).format('MM/DD/YYYY')}`;
    }
    return '';
};

const TimelineViewer: React.FC = () => {
    const { classes } = useStyles();
    const [getData, { data: viewerData, loading, refetch, networkStatus }] =
        useTimelineViewerFetchLazyQuery({ notifyOnNetworkStatusChange: true });
    const [getTimelines, { data: timelineData, loading: timelinesLoading }] =
        useGetTimelinesLazyQuery();
    const [getApps, { data: appData, loading: appsLoading }] = usePreFetchAppsLazyQuery({
        fetchPolicy: 'network-only',
    });
    const [getTags, { data: tagData, loading: tagsLoading }] = useTagListLazyQuery();
    const [tagList, setTagList] = useState<ListItem[]>([]);
    const [filterOpen, setFilterOpen] = useState<boolean>(true);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [viewModalOpen, setViewModalOpen] = useState<boolean>(false);
    const [selectedTimelines, setSelectedTimelines] = useState<selectedTimeline[]>([]);
    const [selectedTimelineId, setSelectedTimelineId] = useState<string>();
    const [selectedAppId, setSelectedAppId] = useState<string>();
    const [selectedTypes, setSelectedTypes] = useState<TimelineViewerEntryType[]>([]);
    const [newType, setNewType] = useState<TimelineViewerEntryType | undefined>();
    const [limit, setLimit] = useState<number>(50);
    const [selectedItem, setSelectedItem] = useState<selectedItem | undefined>();
    const canFilter = (): boolean => {
        if (selectedTimelines.length > 0 && selectedTypes.length > 0) return true;
        return false;
    };
    const { pagePermissions } = useUserPermissions();
    const newAllowed =
        pagePermissions?.AdvocateTaskTemplates.Edit ||
        pagePermissions?.ArticlePromotions.Edit ||
        pagePermissions?.CarePlans.Edit;
    const history = useNavigate();
    useEffect(() => {
        getTimelines();
        getApps();
        getTags({
            variables: { tagListInput: [TagUsageType.Filtering, TagUsageType.PatientRiskScore] },
        });
        const settings = getSettings();
        setSelectedTimelines(settings.timelines);
        setSelectedTypes(settings.types);
        setTagList(settings.tags);
        setLimit(settings.limit);
        setSelectedAppId(settings.appBundleId);
    }, [getTimelines, getApps, getTags]);
    const handleExpandClick = () => {
        setFilterOpen(!filterOpen);
    };
    const handleFilterClick = () => {
        setFilterOpen(false);
        const query = {
            variables: {
                input: {
                    timelines: selectedTimelines.map(t => ({
                        offset: t.offset,
                        timeline: t.timelineId,
                        zeroDate: t.zeroDate,
                    })),
                    types: selectedTypes,
                    tags: tagList.map(t => t.id),
                    appBundleId: selectedAppId,
                    limit,
                },
            },
        };
        saveSettings({
            timelines: selectedTimelines,
            types: selectedTypes,
            tags: tagList,
            appBundleId: selectedAppId,
            limit,
        });
        getData(query);
    };
    const handleAddTimeline = () => {
        if (selectedTimelineId && timelineData) {
            const timeLine = timelineData.whatsNewTimelinesV2.results.find(
                t => t.id === selectedTimelineId,
            );
            if (timeLine) {
                const newTimeline = {
                    timelineId: timeLine.id,
                    name: timeLine.label.en,
                    offset: -50,
                };
                setSelectedTimelines(selectedTimelines.concat(newTimeline));
            }
        }
        setSelectedTimelineId('');
    };
    const handleRemoveTimeline = (id: string) => {
        setSelectedTimelines(selectedTimelines.filter(t => t.timelineId !== id));
    };
    const handleDateChange = (id: string, date: string) => {
        const timeline = selectedTimelines.find(t => t.timelineId === id);
        if (timeline) {
            timeline.zeroDate = date;
            setSelectedTimelines(
                selectedTimelines.filter(t => t.timelineId !== id).concat(timeline),
            );
        }
    };
    const handleOffsetChange = (id: string, offset: number) => {
        const timeline = selectedTimelines.find(t => t.timelineId === id);
        if (timeline) {
            timeline.offset = offset;
            setSelectedTimelines(
                selectedTimelines.filter(t => t.timelineId !== id).concat(timeline),
            );
        }
    };
    const handleTypeSelect = (type: TimelineViewerEntryType, checked: boolean) => {
        if (checked) {
            setSelectedTypes(selectedTypes.concat(type));
        } else {
            setSelectedTypes(selectedTypes.filter(t => t !== type));
        }
    };
    const showItem = (item: selectedItem) => {
        setSelectedItem(item);
        setViewModalOpen(true);
    };
    const editItem = (item: selectedItem) => {
        setSelectedItem(item);
        setEditModalOpen(true);
    };
    const handleModalSave = () => {
        if (refetch) refetch();
        setEditModalOpen(false);
    };
    const openNewEditor = () => {
        if (newType) {
            switch (newType) {
                case TimelineViewerEntryType.Promotion:
                    history('/app-config/articlepromotions/new/');
                    break;
                case TimelineViewerEntryType.Hatask:
                    history('/app-config/advocate-task-templates/new');
                    break;
                case TimelineViewerEntryType.Highlight:
                    history('/app-config/highlights/new');
                    break;
                case TimelineViewerEntryType.Todo:
                    history('/app-config/care-plans/');
                    break;
                default:
                    break;
            }
        }
    };
    const handleExport = () => {
        if (viewerData?.timelineViewerFetch.results)
            getTimelineWorkbook(
                viewerData?.timelineViewerFetch.results.map(t => {
                    const dataRow = [];
                    let appList = 'All Apps';
                    if (t.includeApps && t.includeApps.length > 0) {
                        appList = `Included: ${t.includeApps.join()}`;
                    }
                    if (t.excludeApps && t.excludeApps.length > 0) {
                        appList = `Excluded: ${t.excludeApps.join()}`;
                    }
                    dataRow.push(t.type);
                    dataRow.push(
                        timelineData?.whatsNewTimelinesV2.results.find(l => l.id === t.timelineId)
                            ?.label.en,
                    );
                    dataRow.push(
                        formatOffset(t.offsetDays || 0, t.offsetMonths || 0, t.offsetYears || 0),
                    );
                    dataRow.push(formatEffectiveDate(t.effectiveDate));
                    dataRow.push(formatDuration(t.duration, t.durationTimeUnit as TimeUnit));
                    dataRow.push(t.priority);
                    dataRow.push(
                        t.tags
                            ?.map(
                                tId =>
                                    tagData?.getTagListByUsageTypes.find(t => t.id === tId)?.name,
                            )
                            .join(),
                    );
                    dataRow.push(appList);
                    dataRow.push(t.contentDescription);
                    return dataRow;
                }),
            );
    };
    if (timelinesLoading || appsLoading || tagsLoading) return <Loading />;
    return (
        <>
            {selectedItem && (
                <>
                    <EditTimelineModal
                        isOpen={editModalOpen}
                        onCancel={() => setEditModalOpen(false)}
                        onSave={() => handleModalSave()}
                        item={selectedItem}
                    />
                    <ViewTimelineModal
                        isOpen={viewModalOpen}
                        onClose={() => setViewModalOpen(false)}
                        item={selectedItem}
                        canEdit={pagePermissions?.AdminTimelineViewer.Edit ?? false}
                    />
                </>
            )}
            <h2>Timeline Viewer</h2>
            {newAllowed && (
                <div className={classes.export}>
                    <select onChange={e => setNewType(e.target.value as TimelineViewerEntryType)}>
                        <option value="">Select Type</option>
                        {pagePermissions.ArticlePromotions.Edit && (
                            <option value={TimelineViewerEntryType.Promotion}>
                                Article Promotion
                            </option>
                        )}
                        {pagePermissions.CarePlans.Edit && (
                            <option value={TimelineViewerEntryType.Todo}>Care Plan</option>
                        )}
                        {pagePermissions.AdvocateTaskTemplates.Edit && (
                            <option value={TimelineViewerEntryType.Hatask}>HA Task</option>
                        )}
                        {pagePermissions.Highlights.Edit && (
                            <option value={TimelineViewerEntryType.Highlight}>Highlight</option>
                        )}
                    </select>
                    <Button onClick={openNewEditor} title="Add New Item">
                        <FontAwesomeIcon icon={faPenToSquare} /> Add New Item
                    </Button>
                </div>
            )}
            <div>
                <OutlinedSection title="Filters">
                    <Accordion
                        expanded={filterOpen}
                        onClick={handleExpandClick}
                        aria-expanded={filterOpen}
                        style={{ width: '100px' }}
                    >
                        {filterOpen && (
                            <span className={classes.accordian}>
                                <ExpandLess style={{ marginLeft: '10px' }} />
                                Hide
                            </span>
                        )}
                        {!filterOpen && (
                            <span className={classes.accordian}>
                                <ExpandMore style={{ marginLeft: '10px' }} />
                                Show
                            </span>
                        )}
                    </Accordion>
                    <Collapse in={filterOpen}>
                        <OutlinedSection title="Timelines">
                            {timelineData && (
                                <div>
                                    <select
                                        onChange={e => setSelectedTimelineId(e.target.value)}
                                        value={selectedTimelineId}
                                    >
                                        <option value="" key="0">
                                            Select...
                                        </option>
                                        {_.sortBy(
                                            timelineData.whatsNewTimelinesV2.results.filter(
                                                t =>
                                                    selectedTimelines.find(
                                                        x => x.timelineId === t.id,
                                                    ) === undefined,
                                            ),
                                            'label.en',
                                        ).map(t => (
                                            <option key={t.id} value={t.id}>
                                                {t.label.en}
                                            </option>
                                        ))}
                                    </select>
                                    {selectedTimelines.length < 3 && (
                                        <Button
                                            style={{ marginLeft: '15px' }}
                                            variant="outlined"
                                            onClick={handleAddTimeline}
                                        >
                                            Add Timeline Filter
                                        </Button>
                                    )}
                                </div>
                            )}
                            {selectedTimelines &&
                                _.sortBy(selectedTimelines, 'name').map(t => (
                                    <div key={t.timelineId}>
                                        <span style={{ marginLeft: '15px' }}>
                                            <Button
                                                onClick={() => handleRemoveTimeline(t.timelineId)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </span>
                                        {t.name}
                                        <span style={{ marginLeft: '15px' }}>
                                            <input
                                                type="date"
                                                value={t.zeroDate || ''}
                                                onChange={e =>
                                                    handleDateChange(t.timelineId, e.target.value)
                                                }
                                            />
                                        </span>
                                        <span style={{ marginLeft: '15px' }}>
                                            Offset (days):{' '}
                                            <input
                                                type="number"
                                                onChange={e =>
                                                    handleOffsetChange(
                                                        t.timelineId,
                                                        parseInt(e.target.value, 10),
                                                    )
                                                }
                                                value={t.offset}
                                                style={{ width: '50px' }}
                                            />
                                        </span>
                                    </div>
                                ))}
                        </OutlinedSection>
                        <OutlinedSection title="Content types">
                            <span className={classes.select}>
                                Article Promotions
                                <Checkbox
                                    value={TimelineViewerEntryType.Promotion}
                                    checked={
                                        selectedTypes.find(
                                            t => t === TimelineViewerEntryType.Promotion,
                                        ) !== undefined
                                    }
                                    onChange={e =>
                                        handleTypeSelect(
                                            TimelineViewerEntryType.Promotion,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </span>
                            <span className={classes.select}>
                                Highlights
                                <Checkbox
                                    value={TimelineViewerEntryType.Highlight}
                                    checked={
                                        selectedTypes.find(
                                            t => t === TimelineViewerEntryType.Highlight,
                                        ) !== undefined
                                    }
                                    onChange={e =>
                                        handleTypeSelect(
                                            TimelineViewerEntryType.Highlight,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </span>
                            <span className={classes.select}>
                                Care Plan Todos
                                <Checkbox
                                    value={TimelineViewerEntryType.Todo}
                                    checked={
                                        selectedTypes.find(
                                            t => t === TimelineViewerEntryType.Todo,
                                        ) !== undefined
                                    }
                                    onChange={e =>
                                        handleTypeSelect(
                                            TimelineViewerEntryType.Todo,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </span>
                            <span className={classes.select}>
                                HA Tasks
                                <Checkbox
                                    value={TimelineViewerEntryType.Hatask}
                                    checked={
                                        selectedTypes.find(
                                            t => t === TimelineViewerEntryType.Hatask,
                                        ) !== undefined
                                    }
                                    onChange={e =>
                                        handleTypeSelect(
                                            TimelineViewerEntryType.Hatask,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </span>
                        </OutlinedSection>
                        <OutlinedSection title="Limits">
                            <span style={{ marginLeft: '15px' }}>
                                Maximum number of items to show:{' '}
                                <input
                                    type="number"
                                    onChange={e => setLimit(parseInt(e.target.value, 10))}
                                    value={limit}
                                    min="10"
                                    max="200"
                                    step="10"
                                    style={{ width: '50px' }}
                                />
                            </span>
                        </OutlinedSection>
                        <OutlinedSection title="Apps">
                            {appData && (
                                <div>
                                    <select
                                        value={selectedAppId}
                                        onChange={e => setSelectedAppId(e.target.value)}
                                    >
                                        <option value="" key="0">
                                            All apps
                                        </option>
                                        {_.sortBy(
                                            appData.amsApps.filter(
                                                a => a.productConst === AmsProduct.VbcPlatform,
                                            ),
                                            'bundleId',
                                        ).map(t => (
                                            <option key={t.bundleId} value={t.bundleId}>
                                                {t.bundleId.replace('com.wildflowerhealth.', '')}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </OutlinedSection>
                        {tagData && (
                            <OutlinedSection title="Tags">
                                <Autocomplete
                                    multiple
                                    value={tagList}
                                    options={
                                        tagData &&
                                        _.sortBy(tagData.getTagListByUsageTypes, 'name')
                                            .slice()
                                            .map(tag => ({ id: tag.id, name: tag.name }))
                                    }
                                    onChange={(_, val) => {
                                        setTagList(val);
                                    }}
                                    getOptionLabel={tag => tag.name}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                        />
                                    )}
                                />
                            </OutlinedSection>
                        )}
                        {canFilter() && (
                            <Button variant="outlined" onClick={handleFilterClick}>
                                Apply Filters
                            </Button>
                        )}
                    </Collapse>
                </OutlinedSection>
                {(loading || networkStatus === NetworkStatus.refetch) && (
                    <div className={classes.loader}>
                        <div>
                            <Loading />
                        </div>
                        <h4>Collating items by date, please wait...</h4>
                    </div>
                )}
                {viewerData && networkStatus !== NetworkStatus.refetch && (
                    <>
                        {pagePermissions && pagePermissions.AdminTimelineViewer.Export && (
                            <div className={classes.export}>
                                <Button onClick={handleExport} title="Export">
                                    <FontAwesomeIcon icon={faFileExport} /> Export to Excel
                                </Button>
                            </div>
                        )}
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                                <tr key="0">
                                    <td>Type</td>
                                    <td>Timeline</td>
                                    <td>Offset</td>
                                    <td>Available</td>
                                    <td>Duration</td>
                                    <td>Priority</td>
                                    <td>Tags</td>
                                    <td>Apps</td>
                                    <td>Details</td>
                                    <td>Actions</td>
                                </tr>
                                {viewerData.timelineViewerFetch.results.map(d => (
                                    <tr key={d.itemId + d.groupId + d.templateId}>
                                        <td className={classes.tableCell}>{d.type}</td>
                                        <td className={classes.tableCell}>
                                            {
                                                timelineData?.whatsNewTimelinesV2.results.find(
                                                    t => t.id === d.timelineId,
                                                )?.label.en
                                            }
                                        </td>
                                        <td className={classes.tableCell}>
                                            {formatOffset(
                                                d.offsetDays || 0,
                                                d.offsetMonths || 0,
                                                d.offsetYears || 0,
                                            )}
                                        </td>
                                        <td className={classes.tableCell}>
                                            {formatEffectiveDate(d.effectiveDate)}
                                        </td>
                                        <td className={classes.tableCell}>
                                            {formatDuration(
                                                d.duration,
                                                d.durationTimeUnit as TimeUnit,
                                            )}
                                        </td>
                                        <td className={classes.tableCell}>{d.priority}</td>
                                        <td className={classes.tableCell}>
                                            {d.tags?.map(tId => (
                                                <div>
                                                    {
                                                        tagData?.getTagListByUsageTypes.find(
                                                            t => t.id === tId,
                                                        )?.name
                                                    }
                                                </div>
                                            ))}
                                        </td>
                                        <td className={classes.tableCell}>
                                            {d.includeApps?.length === 0 &&
                                                d.excludeApps?.length === 0 &&
                                                'All Apps'}
                                            {d.includeApps && d.includeApps.length > 0 && (
                                                <div>
                                                    Included
                                                    {d.includeApps?.map(a => (
                                                        <div>
                                                            {a?.replace(
                                                                'com.wildflowerhealth.',
                                                                '',
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {d.excludeApps && d.excludeApps.length > 0 && (
                                                <div>
                                                    Excluded
                                                    {d.excludeApps?.map(a => (
                                                        <div>
                                                            {a?.replace(
                                                                'com.wildflowerhealth.',
                                                                '',
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </td>
                                        <td className={classes.tableCell}>
                                            {d.notificationText && (
                                                <FontAwesomeIcon
                                                    style={{
                                                        fontSize: '1.2em',
                                                        color: 'green',
                                                        marginRight: '5px',
                                                    }}
                                                    icon={faInfoCircle}
                                                    title={`Notification: ${d.notificationText}`}
                                                />
                                            )}
                                            {d.contentDescription}
                                        </td>
                                        <td className={classes.tableCell}>
                                            {pagePermissions &&
                                                ((d.type === TimelineViewerEntryType.Hatask &&
                                                    pagePermissions.AdvocateTaskTemplates.Edit) ||
                                                    (d.type === TimelineViewerEntryType.Highlight &&
                                                        pagePermissions.WhatsNewInstances.Edit) ||
                                                    (d.type === TimelineViewerEntryType.Todo &&
                                                        pagePermissions.CarePlans.Edit) ||
                                                    (d.type === TimelineViewerEntryType.Promotion &&
                                                        pagePermissions.ArticlePromotions
                                                            .Edit)) && (
                                                    <Button
                                                        onClick={() =>
                                                            editItem({
                                                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                                type: d.type!,
                                                                itemId: d.itemId,
                                                                groupId: d.groupId,
                                                                templateId: d.templateId,
                                                                offsetDays: d.offsetDays || 0,
                                                                offsetMonths: d.offsetMonths || 0,
                                                                offsetYears: d.offsetYears || 0,
                                                                duration: d.duration || 0,
                                                                durationTimeUnit:
                                                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                                    d.durationTimeUnit! as TimeUnit,
                                                                description:
                                                                    d.contentDescription || '',
                                                                timelineId: d.timelineId,
                                                                timelineName:
                                                                    timelineData?.whatsNewTimelinesV2.results.find(
                                                                        t => t.id === d.timelineId,
                                                                    )?.label.en || '',
                                                            })
                                                        }
                                                        title="Edit"
                                                    >
                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                    </Button>
                                                )}
                                            <Button
                                                onClick={() =>
                                                    showItem({
                                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                        type: d.type!,
                                                        itemId: d.itemId,
                                                        groupId: d.groupId,
                                                        templateId: d.templateId,
                                                        offsetDays: d.offsetDays || 0,
                                                        offsetMonths: d.offsetMonths || 0,
                                                        offsetYears: d.offsetYears || 0,
                                                        duration: d.duration || 0,
                                                        durationTimeUnit:
                                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                            d.durationTimeUnit! as TimeUnit,
                                                        description: d.contentDescription || '',
                                                        timelineId: d.timelineId,
                                                        timelineName:
                                                            timelineData?.whatsNewTimelinesV2.results.find(
                                                                t => t.id === d.timelineId,
                                                            )?.label.en || '',
                                                    })
                                                }
                                                title="Details"
                                            >
                                                <FontAwesomeIcon icon={faRectangleList} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </>
    );
};
export default TimelineViewer;
