import React from 'react';
import { format, toDate } from 'date-fns-tz';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Grid, Typography, Link, Divider, Card } from '@mui/material';
import {
    useAffiliateCarePatientQuery,
    useAffiliateCareSubmittedClaimsV2Query,
    OrderByDirectionEnum,
} from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import useUserPermissions from '~/hooks/useUserPermissions';
import Claims from './Claims';

type RouteParams = {
    id: string;
};

type PatientProps = {
    affiliateId?: string;
    portalView?: boolean;
};

const Patient: React.FC<PatientProps> = props => {
    const { affiliateId, portalView = false } = props;
    const { id } = useParams<RouteParams>();
    const { userAffiliateId } = useAffiliateCareView();
    const { pagePermissions } = useUserPermissions();
    const { data: patient, loading: patientLoading } = useAffiliateCarePatientQuery({
        variables: {
            input: {
                id,
            },
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const { data: claims, loading: claimsLoading } = useAffiliateCareSubmittedClaimsV2Query({
        variables: {
            input: {
                filter: {
                    fields: {
                        affiliateId: portalView ? affiliateId : userAffiliateId,
                        patientId: id,
                    },
                },
                orderBy: {
                    field: 'createdAt',
                    order: OrderByDirectionEnum.Desc,
                },
            },
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    if (patientLoading || claimsLoading) {
        return <Loading subtitle="Loading patient data" />;
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Breadcrumbs>
                    <Link
                        to={
                            portalView
                                ? `/app-config/vcaffiliates/patients/${affiliateId}`
                                : '/affiliate-care-portal/patients'
                        }
                        component={RouterLink}
                        underline="hover"
                        color="inherit"
                    >
                        <Typography variant="h6">Patients</Typography>
                    </Link>
                    <Typography>{patient?.patient?.fullName}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid container spacing={2} item>
                <Grid item xs={12}>
                    <Card style={{ marginBottom: 0 }}>
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item xs={4}>
                                <Typography variant="h6">Patient Info</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography color="primary" gutterBottom variant="body1">
                                    Full Name:
                                </Typography>
                                <Typography variant="subtitle1">
                                    {patient?.patient?.fullName}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color="primary" gutterBottom variant="body1">
                                    Email:
                                </Typography>
                                <Typography variant="subtitle1">
                                    {patient?.patient?.email}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography color="primary" gutterBottom variant="body1">
                                    DOB:
                                </Typography>
                                <Typography variant="subtitle1">
                                    {patient?.patient?.birthDate &&
                                        format(
                                            toDate(patient?.patient?.birthDate.split('T')[0]),
                                            'MM/dd/yyyy',
                                        )}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography color="primary" gutterBottom variant="body1">
                                    Date Created:
                                </Typography>
                                <Typography variant="subtitle1">
                                    {patient?.patient?.createdAt &&
                                        format(
                                            toDate(patient?.patient?.createdAt.split('T')[0]),
                                            'MM/dd/yyyy',
                                        )}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color="primary" gutterBottom variant="body1">
                                    Phone Number:
                                </Typography>
                                <Typography variant="subtitle1">
                                    {patient?.patient?.virtualCarePhoneNumber ??
                                        patient?.patient?.phoneNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography color="primary" gutterBottom variant="body1">
                                    Address:
                                </Typography>
                                <Typography variant="subtitle1">
                                    {patient?.patient?.mailingAddress?.street1 && (
                                        <Typography noWrap>
                                            {patient?.patient?.mailingAddress?.street1}
                                        </Typography>
                                    )}
                                    {patient?.patient?.mailingAddress?.street2 && (
                                        <Typography noWrap>
                                            {patient?.patient?.mailingAddress?.street2}
                                        </Typography>
                                    )}
                                    {patient?.patient?.mailingAddress?.city &&
                                    patient?.patient?.mailingAddress?.state &&
                                    patient?.patient?.mailingAddress?.code ? (
                                        <Typography noWrap>
                                            {patient?.patient?.mailingAddress?.city},{' '}
                                            {patient?.patient?.mailingAddress?.state}{' '}
                                            {patient?.patient?.mailingAddress?.code}{' '}
                                        </Typography>
                                    ) : (
                                        patient?.patient?.mailingAddress?.city ||
                                        patient?.patient?.mailingAddress?.state
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    {(pagePermissions?.VirtualCareClaims.Read || portalView) && (
                        <Grid container spacing={1}>
                            <Grid item xs={12} container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography color="primary" variant="h6" align="center">
                                        Patient Claims
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent="center"
                                alignItems="center"
                                style={{ minHeight: 200 }}
                            >
                                <Claims claims={claims} portalView={portalView} />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Patient;
