/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    Card,
    CardHeader,
    Dialog,
    Grid,
    MenuItem,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add as AddIcon, ArrowBack, Save } from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import SortableList, {
    ListItemType,
    SortableListOnChangeProps,
} from '~/components/SortableList/SortableList';
import {
    ExpressionUseType,
    InterventionDateType,
    InterventionInput,
    InterventionListDocument,
    InterventionListQuery,
    TagInput,
    TagListDocument,
    TagListQuery,
    TagUpdateInput,
    TagUsageType,
    useCreateTagMutation,
    useDeleteInterventionMutation,
    useFetchMessageCenterTemplatesForInterventionQuery,
    useFetchTagLazyQuery,
    useInterventionListQuery,
    usePreFetchAppsQuery,
    useProfileDefsListQuery,
    useTagListQuery,
    useUpdateInterventionMutation,
    useUpdateTagMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

import ConfigExpressionSelect from '~/views/ConfigDashboard/ConfigExpressions/components/ConfigExpressionSelect';
import { ClientSelectionType, ClientSelectionTypes } from '~/enums/enums';
import _ from 'lodash';
import InterventionsModal from '../InterventionsModal';

type InterventionListItem = NonNullable<InterventionListQuery['interventionsV2']>['results'][0];
type InterventionListProfileValue = NonNullable<InterventionListItem['profileValue']>;
interface ListItem {
    id: string;
    name: string;
}

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
    addInterventionButtonContainer: {
        margin: '20px 10px',
    },
}));

const toClientSelectionType = ({ excludeApps, includeApps }: TagInput): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};
// TODO: Fix id trying to be used on a type that doesn't have it
interface TagTriggerFormInput {
    id?: string;
    name: string;
    expressionId: string;
    clientSelectionType: ClientSelectionType;
    includeApps?: string[];
    excludeApps?: string[];
}

const formValuesFromData = (instance: TagInput): TagTriggerFormInput => {
    return {
        // id: instance.id,
        name: instance.name,
        expressionId: instance.expressionId ?? '',
        clientSelectionType: toClientSelectionType(instance),
        includeApps: instance.includeApps ?? [],
        excludeApps: instance.excludeApps ?? [],
    };
};

const formValuesDefault = (tagId: string): TagTriggerFormInput => {
    return {
        id: tagId,
        name: '',
        expressionId: '',
        clientSelectionType: ClientSelectionType.IncludeAllApps,
        includeApps: [],
        excludeApps: [],
    };
};

const TagTriggerValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    clientSelectionType: Yup.mixed().oneOf(ClientSelectionTypes).required(),
    includeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            if (clientSelectionType === ClientSelectionType.IncludeCertainApps) {
                return schema.min(1, 'Must select at least one app to include').required();
            }
            return schema;
        }),
    excludeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            if (clientSelectionType === ClientSelectionType.ExcludeCertainApps) {
                return schema.min(1, 'Must select at least one app to exclude').required();
            }
            return schema;
        }),
});
const toIncludeOrExclude = ({
    clientSelectionType,
    includeApps,
    excludeApps,
}: TagTriggerFormInput) => {
    switch (clientSelectionType) {
        case ClientSelectionType.IncludeCertainApps:
            return {
                includeApps: includeApps ?? [],
                excludeApps: [],
            };
        case ClientSelectionType.ExcludeCertainApps:
            return {
                includeApps: [],
                excludeApps: excludeApps ?? [],
            };
        case ClientSelectionType.IncludeAllApps:
        default:
            return {
                includeApps: [],
                excludeApps: [],
            };
    }
};
export const formValuesToCreate = (
    form: TagTriggerFormInput,
    expressionId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tagId: string,
): TagInput => {
    const { clientSelectionType, includeApps, excludeApps, id, ...rest } = form;

    return {
        ...rest,
        expressionId,
        ...toIncludeOrExclude(form),
        usageType: TagUsageType.Trigger,
        // id: tagId,
    };
};

export const formValuesToUpdate = (
    form: TagTriggerFormInput,
    expressionId: string,
): TagUpdateInput => {
    const { clientSelectionType, includeApps, excludeApps, ...rest } = form;

    return {
        ...rest,
        expressionId,
        ...toIncludeOrExclude(form),
        usageType: TagUsageType.Trigger,
    };
};
const TagTriggerEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: urlId } = useParams<{ id: string }>();
    const [showInterventionModal, setShowInterventionModal] = useState(false);
    const [editInterventionId, setEditInterventionId] = useState('');
    const [isModified, setIsModified] = useState(false);
    const isEditMode = urlId !== 'new';
    const [tagId] = useState(isEditMode ? urlId : new ObjectId().toHexString());
    const [close, setClose] = useState(false);
    const [selectedExpressionId, setSelectedExpressionId] = useState<string | null>();
    const [interventionPriority, setInterventionPriority] = useState(0);
    const [interventionSortableList, setInterventionSortableList] = useState<ListItemType[]>([]);
    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsQuery();
    const { data: profileDefData, loading: profileDataLoading } = useProfileDefsListQuery();
    const [fetchTagById, { data: tagData, loading: tagLoading }] = useFetchTagLazyQuery();
    const [profileDefList, setProfileDefList] = useState<ListItem[]>([]);
    const { data: tagList, loading: tagsLoading } = useTagListQuery({
        variables: { tagListInput: [TagUsageType.Trigger] },
    });

    const { data: messageCenterTemplateList, loading: messageCenterTemplatesLoading } =
        useFetchMessageCenterTemplatesForInterventionQuery();

    const [updateTag, { loading: updateTagLoading }] = useUpdateTagMutation({
        onCompleted: () => {
            if (close) history(`/app-config/triggers`);
        },
        update: (cache, response) => {
            const updatedTag = response.data?.updateTag?.resourceUpdated;
            if (response.data?.updateTag?.success && updatedTag) {
                const currentTags = cache.readQuery<TagListQuery>({
                    query: TagListDocument,
                });
                if (currentTags?.getTagListByUsageTypes) {
                    cache.writeQuery<TagListQuery>({
                        query: TagListDocument,
                        data: {
                            getTagListByUsageTypes: [
                                ...currentTags.getTagListByUsageTypes.map(tag => {
                                    if (tag.id === updatedTag.id) {
                                        return updatedTag;
                                    }
                                    return tag;
                                }),
                            ],
                        },
                    });
                }
            }
        },
    });

    const [createTag, { loading: createTagLoading }] = useCreateTagMutation({
        onCompleted: data => {
            if (close) history(`/app-config/triggers`);
            else history(`/app-config/triggers/${data.createTag?.resourceCreated?.id}`);
        },
        update: (cache, response) => {
            const newTag = response.data?.createTag?.resourceCreated;
            if (response.data?.createTag?.success && newTag) {
                const currentTags = cache.readQuery<TagListQuery>({
                    query: TagListDocument,
                });
                if (currentTags?.getTagListByUsageTypes) {
                    cache.writeQuery<TagListQuery>({
                        query: TagListDocument,
                        data: {
                            getTagListByUsageTypes: [...currentTags.getTagListByUsageTypes, newTag],
                        },
                    });
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: TagListDocument,
                variables: {
                    tagListInput: [TagUsageType.Trigger],
                },
            },
        ],
    });

    const { data: interventionData } = useInterventionListQuery({
        variables: {
            interventionListInput: { filter: { fields: { tagId } } },
        },
    });

    const [deleteIntervention] = useDeleteInterventionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: InterventionListDocument,
                variables: {
                    interventionListInput: { filter: { fields: { tagId } } },
                },
            },
        ],
    });

    const [updateIntervention, { loading: updateInterventionLoading }] =
        useUpdateInterventionMutation();

    const {
        control,
        register,
        reset,
        handleSubmit,
        setValue,
        watch,
        setError,

        formState: { errors },
    } = useForm<TagTriggerFormInput>({
        resolver: yupResolver(TagTriggerValidation as any),
        defaultValues: formValuesDefault(tagId ?? ''),
    });

    const clientSelectionType = watch('clientSelectionType');

    const expressionSelected = (id: string, name: string) => {
        setSelectedExpressionId(id);
        if (!isEditMode) setValue('name', name);
        setIsModified(true);
    };

    const getInterventionData = (intervention: InterventionListItem) => {
        const {
            advocateTaskTemplateId,
            choicesToUpdate,
            messageCenterTemplateId,
            type,
            conditionTypeId,
            profileDefId,
            profileValue,
            dateType,
            tagId,
        }: InterventionInput = intervention;

        const { bool, num, str, date, choices, choiceId }: any = profileValue;

        return {
            advocateTaskTemplateId,
            choicesToUpdate,
            messageCenterTemplateId,
            type,
            conditionTypeId,
            profileDefId,
            profileValue: { bool, num, str, date, choices, choiceId },
            dateType,
            tagId,
            priority: interventionSortableList.findIndex(item => item.id === intervention.id),
        };
    };

    const updateInterventions = async () => {
        const interventions: InterventionListQuery['interventionsV2']['results'] | undefined =
            interventionData?.interventionsV2?.results;
        if (!interventions) return;

        await Promise.all(
            interventions.map(intervention => {
                return updateIntervention({
                    variables: {
                        updateInterventionInput: {
                            id: intervention.id,
                            data: getInterventionData(intervention),
                        },
                    },
                });
            }),
        );
    };

    const onSubmit = async (values: any) => {
        if (!selectedExpressionId || selectedExpressionId === '0' || selectedExpressionId === '') {
            TriggerGlobalConfirm({
                callback: () => {
                    setIsModified(true);
                },
                message: 'Please select an Expression',
            });
            return;
        }
        if (
            tagList &&
            ((isEditMode &&
                tagList.getTagListByUsageTypes.filter(
                    t => t.id !== tagId && t.name.toLowerCase() === values.name.toLowerCase(),
                ).length > 0) ||
                (!isEditMode &&
                    tagList.getTagListByUsageTypes.filter(
                        t => t.name.toLowerCase() === values.name.toLowerCase(),
                    ).length > 0))
        ) {
            setError('name', { message: 'Trigger Name is already used, please change.' });
            return;
        }
        if (isEditMode && tagId) {
            const tagToUpdate = {
                ...formValuesToUpdate(values, selectedExpressionId || ''),
                changedProfileDefIds: profileDefList.map(p => p.id),
            };
            await updateTag({
                variables: {
                    updateTagInput: {
                        id: tagId,
                        data: tagToUpdate,
                    },
                },
            });
            await updateInterventions();
        } else if (tagId) {
            await createTag({
                variables: {
                    createTagInput: {
                        ...formValuesToCreate(values, selectedExpressionId || '', tagId),
                        changedProfileDefIds: profileDefList.map(p => p.id),
                    },
                },
            });
            await updateInterventions();
        }
    };

    const onNavigateAway = () => {
        if (!isEditMode) {
            interventionData?.interventionsV2?.results.map((item: any) =>
                deleteIntervention({ variables: { input: { id: item.id } } }),
            );
        }

        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Trigger List?`,
                callback: () => {
                    history(`/app-config/triggers/`);
                },
            });
        else history(`/app-config/triggers/`);
    };

    const onEdit = () => {
        setIsModified(true);
    };

    const handleAddIntervention = () => {
        setInterventionPriority(interventionSortableList.length);
        setShowInterventionModal(true);
    };

    const handleEditIntervention = (listItem: ListItemType, index: number) => {
        setShowInterventionModal(true);
        setInterventionPriority(index);
        setEditInterventionId(listItem.id as string);
    };

    const handleChangeInterventions = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        const interventionistClone = [...interventionSortableList];
        const element = interventionistClone[startIndex];
        interventionistClone.splice(startIndex, 1);
        interventionistClone.splice(endIndex, 0, element);
        setInterventionSortableList(interventionistClone);
    };

    const handleDeleteIntervention = (id: string, index: number) => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Intervention?',
            callback: async () => {
                await deleteIntervention({ variables: { input: { id } } });
                setInterventionSortableList(
                    interventionSortableList.filter((_, groupIndex) => groupIndex !== index),
                );
            },
        });
    };

    const handleDeleteInterventionItemClick = (index: number) => {
        handleDeleteIntervention(interventionSortableList[index].id as string, index);
    };

    const formatTargetDate = (dateString: string) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');

        return `${month}/${day}/${year}`;
    };

    const renderTarget = useCallback(
        ({
            conditionType,
            profileDef,
            messageCenterTemplateId,
            messageTemplateId,
            messageTemplate,
            profileValue,
            dateType,
            advocateTaskTemplate,
        }: InterventionListItem): string => {
            const interventionProfileValue = { ...profileValue };
            if (conditionType) {
                return conditionType.name.en;
            }
            if (messageCenterTemplateId) {
                return (
                    messageCenterTemplateList?.messageCenterTemplates.filter(
                        template => template.id === messageCenterTemplateId,
                    )?.[0]?.title ?? ''
                );
            }
            if (messageTemplateId) {
                return messageTemplate?.name ?? messageTemplateId;
            }
            if (advocateTaskTemplate) {
                return advocateTaskTemplate.label;
            }

            if (!interventionProfileValue || !profileDef || !profileDef.choices) {
                return '';
            }

            const { choiceId, date, str, num, bool } = interventionProfileValue;
            const { valueType } = profileDef;
            const profileDefName = profileDef?.name;
            // Check if profile value is a string
            if (str) {
                return `${profileDefName}: ${str}`;
            }
            // Check if profile value is a number
            if (num) {
                return `${profileDefName}: ${num}`;
            }
            // Check if profile value is a date and is Today
            if (dateType === InterventionDateType.Today && valueType === 'date') {
                return `${profileDefName}: Today`;
            }
            // Check if profile value is a boolean
            if (bool !== null && dateType !== InterventionDateType.Fixed) {
                return `${profileDefName}: ${bool ? 'Yes' : 'No'}`;
            }
            interventionProfileValue.bool = null;
            // if the profile value is a fixed date
            try {
                const [[selectedKey]] = Object.entries(interventionProfileValue).filter(
                    ([, value]) => value !== 'InterventionProfileValue' && value !== null,
                );

                if (!choiceId) {
                    const value =
                        interventionProfileValue[selectedKey as keyof InterventionListProfileValue];
                    return `${profileDefName}: ${date ? formatTargetDate(value as string) : value}`;
                }
            } catch (e) {
                throw new Error('Invalid profile value');
            }

            const [selectedChoice] = profileDef.choices.filter(choice => choice?.id === choiceId);
            const choiceLabel = selectedChoice?.label?.en;

            return `${profileDefName}: ${choiceLabel}`;
        },
        [messageCenterTemplateList?.messageCenterTemplates],
    );

    const getInterventionSortableList = useCallback(
        (interventionData: InterventionListQuery | undefined): ListItemType[] => {
            const data = interventionData?.interventionsV2?.results;
            return data
                ? data
                      .map(item => ({
                          id: item.id,
                          label: {
                              en: `Type: ${item.type}. Target: ${renderTarget(item)}`,
                              es: item.type,
                          },
                          priority: item.priority as number,
                      }))
                      .sort((prev, next) => (prev.priority > next.priority ? 1 : -1))
                : [];
        },
        [renderTarget],
    );

    useEffect(() => {
        if (isEditMode) {
            fetchTagById({ variables: { tagInput: { id: tagId } } });
            if (tagData && tagData.tag) {
                setSelectedExpressionId(tagData.tag.expressionId);
                const profileDefs: ListItem[] = [];
                if (tagData.tag.changedProfileDefIds) {
                    for (const id of tagData.tag.changedProfileDefIds) {
                        const profile = profileDefData?.userProfileDefs.find(p => p.id === id);
                        if (profile) profileDefs.push({ id, name: profile.name });
                    }
                }
                setProfileDefList(profileDefs);
                reset(formValuesFromData(tagData.tag));
            }
            setInterventionSortableList(getInterventionSortableList(interventionData));
        }
    }, [
        fetchTagById,
        interventionData,
        isEditMode,
        tagId,
        tagData,
        reset,
        urlId,
        getInterventionSortableList,
        profileDefData?.userProfileDefs,
    ]);

    if (
        tagLoading ||
        createTagLoading ||
        updateTagLoading ||
        preFetchLoading ||
        messageCenterTemplatesLoading ||
        !preFetchData ||
        tagsLoading ||
        profileDataLoading
    )
        return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Trigger List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader
                            title={`${isEditMode ? 'Edit' : 'Create'} Trigger and Interventions`}
                        />
                        <div style={{ display: 'inline-block', paddingRight: '10px' }}>
                            Select Expression *:
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            <ConfigExpressionSelect
                                itemSelected={expressionSelected}
                                inputId={selectedExpressionId ?? null}
                                useType={ExpressionUseType.Tags}
                                canChange
                            />
                        </div>
                        <OutlinedSection title="Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Apps">
                            <ReactHookFormSelect
                                control={control}
                                name="clientSelectionType"
                                variant="outlined"
                                defaultValue=""
                                label="Selection type *"
                                fullWidth
                                margin="dense"
                            >
                                {ClientSelectionTypes.map(m => (
                                    <MenuItem key={m} value={m}>
                                        {m}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {clientSelectionType === ClientSelectionType.IncludeCertainApps && (
                                <AutocompleteWithRecordOptions
                                    options={_.sortBy(preFetchData?.amsApps, 'bundleId') ?? []}
                                    valueKey="bundleId"
                                    labelKey="bundleId"
                                    control={control}
                                    name="includeApps"
                                    label="Include apps *"
                                    placeholder="Select apps to include..."
                                    required
                                    error={Boolean(errors.includeApps)}
                                    helperText={
                                        (errors.includeApps as FieldError | undefined)?.message
                                    }
                                />
                            )}
                            {clientSelectionType === ClientSelectionType.ExcludeCertainApps && (
                                <AutocompleteWithRecordOptions
                                    options={_.sortBy(preFetchData?.amsApps, 'bundleId') ?? []}
                                    valueKey="bundleId"
                                    labelKey="bundleId"
                                    control={control}
                                    name="excludeApps"
                                    label="Exclude apps *"
                                    placeholder="Select apps to exclude..."
                                    required
                                    error={Boolean(errors.excludeApps)}
                                    helperText={
                                        (errors.excludeApps as FieldError | undefined)?.message
                                    }
                                />
                            )}
                        </OutlinedSection>
                        <OutlinedSection title="Select Profile Defs">
                            <Autocomplete
                                multiple
                                value={profileDefList}
                                options={
                                    (profileDefData &&
                                        profileDefData.userProfileDefs &&
                                        profileDefData.userProfileDefs
                                            .slice()
                                            .sort((a, b) => {
                                                if (a.name < b.name) return -1;
                                                if (a.name === b.name) return 0;
                                                return 1;
                                            })
                                            .map(def => ({ id: def.id, name: def.name }))) ||
                                    []
                                }
                                onChange={(_, val) => {
                                    setProfileDefList(val);
                                }}
                                getOptionLabel={def => def.name}
                                renderInput={params => (
                                    <TextField
                                        variant="outlined"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Profile Def"
                                        placeholder="Select a Profile Def to activate interventions"
                                    />
                                )}
                            />
                        </OutlinedSection>
                        {isEditMode && (
                            <Grid item className={classes.addInterventionButtonContainer}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleAddIntervention()}
                                    startIcon={<AddIcon />}
                                >
                                    Add Intervention
                                </Button>
                            </Grid>
                        )}
                        {!updateInterventionLoading &&
                        interventionSortableList &&
                        interventionSortableList.length ? (
                            <Grid item>
                                <OutlinedSection title="Interventions">
                                    <SortableList
                                        list={interventionSortableList}
                                        droppableId="interventionOrder"
                                        onChange={handleChangeInterventions}
                                        onClick={handleEditIntervention}
                                        onDelete={handleDeleteInterventionItemClick}
                                    />
                                </OutlinedSection>
                            </Grid>
                        ) : null}

                        <div style={{ width: '100%', marginTop: '10px', textAlign: 'right' }}>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    setClose(true);
                                    handleSubmit(onSubmit);
                                }}
                            >
                                Save &amp; Close
                            </Button>
                        </div>
                    </Card>
                </form>
            </Grid>
            <Dialog scroll="body" open={showInterventionModal} aria-labelledby="form-dialog-title">
                <InterventionsModal
                    setOpen={setShowInterventionModal}
                    setEditIntervention={setEditInterventionId}
                    id={editInterventionId}
                    tagId={tagId ?? ''}
                    interventionPriority={interventionPriority}
                />
            </Dialog>
        </Grid>
    );
};

export default TagTriggerEditor;
