import * as AmazonCognitoIdentityJS from 'amazon-cognito-identity-js';
import { AccessToken, AuthTokens, AuthUsername, IdToken } from '~/state';
import { CognitoUserPool } from '~/App';
import { AUTH_TOKEN_KEY } from '~/models/auth';
import { client } from '~/ApolloClientWrapper';

const logout = async () => {
    console.log('logout');
    const cogUser = new AmazonCognitoIdentityJS.CognitoUser({
        Username: AuthUsername(),
        Pool: await CognitoUserPool,
    });
    cogUser.signOut();
    AccessToken(null);
    localStorage.setItem(AuthTokens.AccessToken, '');
    IdToken(null);
    localStorage.setItem(AuthTokens.IdToken, '');
    localStorage.removeItem(AUTH_TOKEN_KEY);
    client.clearStore();
    // eslint-disable-next-line no-self-assign
    window.location = window.location;
};

export default logout;
