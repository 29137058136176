import { OrderByDirectionEnum } from '~/schemaTypes';

// some fields are calcualted from Profile Variables
// this enum connects Patient List query field name and Profile Value Definition id together
export enum PatientPVRelatedFields {
    riskScore = '64c741ac4478b8661e3e5f93',
}

export enum PatientSortingFields {
    firstName = 'firstName',
    lastName = 'lastName',
    riskScore = 'riskScore',
    createdAt = 'createdAt',
    birthDate = 'birthDate',
    onboardingCompleteDate = 'onboardingCompleteDate',
    isPregnant = 'isPregnant',
    dueDate = 'dueDate',
    deliveryDate = 'deliveryDate',
    assignedHealthAdvocate = 'assignedHealthAdvocate',
}

export interface IPatientOrderChange {
    field: PatientSortingFields | PatientPVRelatedFields;
    direction: OrderByDirectionEnum;
    fieldIsProfileDefId?: boolean;
    isInit: boolean;
}

export enum VirtualCareViewColumns {
    firstName = 'firstName',
    lastName = 'lastName',
    createdAt = 'createdAt',
    birthDate = 'birthDate',
    practice = 'practice',
    email = 'email',
    affiliates = 'affiliates',
}
