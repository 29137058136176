import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    Dialog,
    Divider,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowBack, Save } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/AddCircle';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import SortableList, {
    ListItemType,
    SortableListOnChangeProps,
} from '~/components/SortableList/SortableList';
import {
    CareTeamByIdForCareTeamBuilderDocument,
    CareTeamByIdForCareTeamBuilderQuery,
    CareTeamInput,
    CareTeamMemberType,
    CareTeamsForCareTeamsPageDocument,
    CareTeamsForCareTeamsPageQuery,
    useCareTeamByIdForCareTeamBuilderLazyQuery,
    useCreateCareTeamForCareTeamBuilderMutation,
    usePreFetchAppsForCareTeamBuilderQuery,
    usePreFetchCareTeamMemberTypesForCareTeamBuilderQuery,
    useUpdateCareTeamForCareTeamBuilderMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import MemberTypeModal from '../MemberTypeModal/MemberTypeModal';
import { IndexedMemberType, MemberType } from '../types';
import { toMemberTypeForm } from './helpers';
import useStyles from './styles';
import CARE_TEAM_SCHEMA from './validationSchema';

const CareTeamBuilder: React.FC = () => {
    const { classes } = useStyles();
    const { id: careTeamId } = useParams<{ id: string }>();
    const isEditMode = careTeamId !== 'new';
    const history = useNavigate();
    const [memberTypes, setMemberTypes] = useState<MemberType[]>([]);
    const [careTeamMemberTypes, setCareTeamMemberTypes] = useState<CareTeamMemberType[]>([]);
    const [selectedType, setSelectedType] = useState<IndexedMemberType | null>(null);
    const [close, setClose] = useState(false);
    const [hasFormError, setHasFormError] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedCareTeamMemberTypeIds, setSelectedCareTeamMemberTypeIds] = useState<string[]>(
        [],
    );

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,

        formState: { errors },
    } = useForm<CareTeamInput>({
        resolver: yupResolver(CARE_TEAM_SCHEMA as any),
    });

    const watchedName = watch('name');

    const { loading: careTeamMemberTypesLoading } =
        usePreFetchCareTeamMemberTypesForCareTeamBuilderQuery({
            onCompleted: ({ careTeamMemberTypesV2 }) => {
                if (careTeamMemberTypesV2) {
                    setCareTeamMemberTypes(careTeamMemberTypesV2.results);
                }
            },
        });

    const { data: preFetchAppsData, loading: preFetchAppsLoading } =
        usePreFetchAppsForCareTeamBuilderQuery();

    const [careTeamByIdForCareTeamBuilder, { loading: careTeamByIdLoading }] =
        useCareTeamByIdForCareTeamBuilderLazyQuery({
            onCompleted: ({ careTeam }) => {
                if (careTeam) {
                    const { appBundleId, name, memberTypes: careTeamMemberTypes } = careTeam;
                    reset({
                        name,
                        appBundleId,
                    });
                    setMemberTypes(toMemberTypeForm(careTeamMemberTypes));
                    const memberTypeIds = careTeamMemberTypes.map(({ id }) => id);
                    setSelectedCareTeamMemberTypeIds(memberTypeIds);
                }
            },
        });

    const [createCareTeam, { loading: createCareTeamLoading }] =
        useCreateCareTeamForCareTeamBuilderMutation({
            onCompleted: ({ createCareTeam }) => {
                if (close) {
                    history('/app-config/care-teams/');
                } else {
                    history(`/app-config/care-teams/${createCareTeam?.resourceCreated?.id}`);
                }
            },
            update: (cache, response) => {
                const createdCareTeam = response.data?.createCareTeam?.resourceCreated;
                if (response.data?.createCareTeam?.success && createdCareTeam) {
                    const currentCareTeams = cache.readQuery<CareTeamsForCareTeamsPageQuery>({
                        query: CareTeamsForCareTeamsPageDocument,
                    });
                    if (currentCareTeams?.careTeamsV2) {
                        cache.writeQuery<CareTeamsForCareTeamsPageQuery>({
                            query: CareTeamsForCareTeamsPageDocument,
                            data: {
                                careTeamsV2: {
                                    __typename: currentCareTeams.careTeamsV2.__typename,
                                    results: [
                                        ...currentCareTeams.careTeamsV2.results,
                                        createdCareTeam,
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        });

    const [updateCareTeam, { loading: updateCareTeamLoading }] =
        useUpdateCareTeamForCareTeamBuilderMutation({
            onCompleted: ({ updateCareTeam }) => {
                if (close) {
                    history('/app-config/care-teams/');
                } else {
                    history(`/app-config/care-teams/${updateCareTeam?.resourceUpdated?.id}`);
                }
            },
            update: (cache, response) => {
                const updatedCareTeam = response.data?.updateCareTeam?.resourceUpdated;
                if (response.data?.updateCareTeam?.success && updatedCareTeam) {
                    const currentCareTeams = cache.readQuery<CareTeamsForCareTeamsPageQuery>({
                        query: CareTeamsForCareTeamsPageDocument,
                    });
                    if (currentCareTeams?.careTeamsV2) {
                        cache.writeQuery<CareTeamsForCareTeamsPageQuery>({
                            query: CareTeamsForCareTeamsPageDocument,
                            data: {
                                careTeamsV2: {
                                    __typename: currentCareTeams.careTeamsV2.__typename,
                                    results: [
                                        ...currentCareTeams.careTeamsV2.results.filter(
                                            item => item.id !== updatedCareTeam.id,
                                        ),
                                        updatedCareTeam,
                                    ],
                                },
                            },
                        });
                    }
                    const currentCareTeamById =
                        cache.readQuery<CareTeamByIdForCareTeamBuilderQuery>({
                            query: CareTeamByIdForCareTeamBuilderDocument,
                            variables: { input: { id: careTeamId } },
                        });
                    if (currentCareTeamById?.careTeam) {
                        cache.writeQuery<CareTeamByIdForCareTeamBuilderQuery>({
                            query: CareTeamByIdForCareTeamBuilderDocument,
                            data: {
                                careTeam: {
                                    __typename: currentCareTeamById.careTeam.__typename,
                                    ...updatedCareTeam,
                                },
                            },
                            variables: { input: { id: careTeamId } },
                        });
                    }
                }
            },
        });

    useEffect(() => {
        if (isEditMode) {
            careTeamByIdForCareTeamBuilder({
                variables: { input: { id: careTeamId } },
            });
        }
    }, [careTeamByIdForCareTeamBuilder, careTeamId, isEditMode]);

    if (
        preFetchAppsLoading ||
        careTeamByIdLoading ||
        createCareTeamLoading ||
        updateCareTeamLoading ||
        careTeamMemberTypesLoading
    ) {
        return <Loading />;
    }

    const handleMemberTypesChange = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        const checklistClone = [...memberTypes];
        const element = checklistClone[startIndex];
        checklistClone.splice(startIndex, 1);
        checklistClone.splice(endIndex, 0, element);
        setMemberTypes(checklistClone);
    };

    const handleMemberTypeClick = (checklistItem: ListItemType, index: number) => {
        const selectedMemberTypeItem = checklistItem as MemberType;
        setSelectedType([{ ...selectedMemberTypeItem }, index]);
        setIsModalOpen(true);
    };

    const handleAddMemberTypeClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseMemberTypeModal = () => {
        setIsModalOpen(false);
        setSelectedType(null);
    };

    const handleMemberTypeDelete = (indexToDelete: number) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Member Type?`,
            callback: () => {
                setMemberTypes(state => state.filter((_, index) => index !== indexToDelete));
            },
        });
    };

    const handleModalSubmit = ([memberType, index]: IndexedMemberType) => {
        const indexToUpdate = memberTypes.findIndex((_, typeIndex) => typeIndex === index);
        if (indexToUpdate === -1) {
            setMemberTypes(memberTypes => [...memberTypes, memberType]);
        } else {
            const updatedMemberTypeList = [...memberTypes];
            updatedMemberTypeList[indexToUpdate] = memberType;
            setMemberTypes(updatedMemberTypeList);
        }
        setIsModalOpen(false);
        setSelectedType(null);
    };

    const onSubmit = (data: CareTeamInput) => {
        const { name, appBundleId } = data;

        if (memberTypes.length === 0) {
            setHasFormError(true);
            return;
        }
        setHasFormError(false);

        const newCareTeam = {
            name,
            appBundleId,
            memberTypes,
        };

        if (isEditMode && careTeamId) {
            updateCareTeam({
                variables: {
                    updateCareTeamInput: {
                        id: careTeamId,
                        data: newCareTeam,
                    },
                },
            });
        } else {
            createCareTeam({
                variables: {
                    createCareTeamInput: {
                        ...newCareTeam,
                    },
                },
            });
        }
    };

    return (
        <>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Button component={Link} to="/app-config/care-teams" startIcon={<ArrowBack />}>
                        Back to Care Teams
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Typography variant="h6" paragraph>
                            {isEditMode ? 'Edit' : 'Add'} Care Team
                        </Typography>
                        <Divider className={classes.divider} />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                variant="outlined"
                                {...register('name', { required: true })}
                                label="Name *"
                                fullWidth
                                margin="dense"
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                                InputLabelProps={{ shrink: !!watchedName }}
                            />
                            <ReactHookFormSelect
                                control={control}
                                name="appBundleId"
                                variant="outlined"
                                defaultValue=""
                                label="App Bundle ID *"
                                fullWidth
                                margin="dense"
                                error={Boolean(errors.appBundleId)}
                            >
                                {preFetchAppsData?.amsApps.map(({ bundleId }) => (
                                    <MenuItem key={bundleId} value={bundleId}>
                                        {bundleId}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {errors?.appBundleId && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.appBundleId?.message}
                                </FormHelperText>
                            )}
                            {memberTypes.length > 0 && (
                                <OutlinedSection
                                    className={classes.sortableList}
                                    title="Member Types"
                                >
                                    <SortableList
                                        list={memberTypes.filter(
                                            memberTypeItem =>
                                                memberTypeItem.activeMembersCount !== 0,
                                        )}
                                        droppableId="memberTypes"
                                        onChange={handleMemberTypesChange}
                                        onClick={handleMemberTypeClick}
                                        onDelete={handleMemberTypeDelete}
                                    />
                                </OutlinedSection>
                            )}
                            <Button
                                className={classes.addTypeButton}
                                color="primary"
                                variant="contained"
                                onClick={handleAddMemberTypeClick}
                                startIcon={<AddIcon />}
                            >
                                Add Member Type
                            </Button>
                            {memberTypes.length === 0 && hasFormError && (
                                <FormHelperText error className={classes.memberTypeListError}>
                                    Must select at least one Member Type
                                </FormHelperText>
                            )}
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        onClick={handleSubmit(onSubmit)}
                                        startIcon={<Save />}
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            setClose(true);
                                            handleSubmit(onSubmit);
                                        }}
                                        startIcon={<Save />}
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                    >
                                        Save &amp; Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </Grid>
            </Grid>
            <Dialog
                scroll="paper"
                open={Boolean(isModalOpen)}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <MemberTypeModal
                    onSubmit={handleModalSubmit}
                    data={selectedType}
                    closeModal={handleCloseMemberTypeModal}
                    isEditMode={Boolean(selectedType)}
                    careTeamMemberTypes={careTeamMemberTypes || []}
                    selectedMemberTypeIds={selectedCareTeamMemberTypeIds}
                    setSelectedMemberTypeIds={setSelectedCareTeamMemberTypeIds}
                />
            </Dialog>
        </>
    );
};

export default CareTeamBuilder;
