import React, { useState } from 'react';
import { Typography } from '@mui/material';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import tableIcons from '~/helpers/tableIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import {
    ApplicationsV2ForMaintenanceAppListDocument,
    useApplicationsV2ForMaintenanceAppListQuery,
    ApplicationsV2ForMaintenanceAppListQuery,
    useUpdateApplicationForMaintenanceAppListMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useUserPermissions } from '~/hooks';
import MaintenanceAppEditModal from './MaintenanceAppEditModal';
import { AppItem, EditModalData } from './types';

const fetchAppsVariables = {
    input: {
        filter: {
            fields: {
                isActive: true,
            },
        },
    },
};

const MaintenanceAppList = () => {
    const [editedApp, setEditedApp] = useState<AppItem | null>(null);
    const { pagePermissions } = useUserPermissions();
    const { data, loading: applicationsLoading } = useApplicationsV2ForMaintenanceAppListQuery({
        variables: fetchAppsVariables,
    });

    const [updateApplication] = useUpdateApplicationForMaintenanceAppListMutation({
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => setEditedApp(null),
                message: `There was a problem saving the application maintenance: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const updatedApp = response.data?.updateApplication?.resourceUpdated;
            if (response.data?.updateApplication?.success && updatedApp) {
                const currentApps = cache.readQuery<ApplicationsV2ForMaintenanceAppListQuery>({
                    query: ApplicationsV2ForMaintenanceAppListDocument,
                    variables: fetchAppsVariables,
                });
                if (currentApps?.applicationsV2) {
                    cache.writeQuery<ApplicationsV2ForMaintenanceAppListQuery>({
                        query: ApplicationsV2ForMaintenanceAppListDocument,
                        variables: fetchAppsVariables,
                        data: {
                            applicationsV2: {
                                ...currentApps.applicationsV2,
                                results: currentApps.applicationsV2.results.map(app =>
                                    app.id === updatedApp.id ? updatedApp : app,
                                ),
                            },
                        },
                    });
                }
            }
        },
        onCompleted: () => setEditedApp(null),
    });

    const handleSubmitAppEditModal = (data: EditModalData) => {
        setEditedApp(null);
        updateApplication({
            variables: {
                input: {
                    id: data.id,
                    data: {
                        isMaintenance: data.isMaintenance,
                    },
                },
            },
        });
    };

    return (
        <>
            <MaterialTable<AppItem>
                icons={tableIcons as MaterialTableProps<any>['icons']}
                columns={[
                    {
                        title: 'AppBundleId',
                        field: 'appBundleId',
                        render: ({ appBundleId }) => <Typography>{appBundleId}</Typography>,
                    },
                    {
                        title: 'Name',
                        field: 'name',
                        render: ({ name }) => <Typography>{name}</Typography>,
                    },
                    {
                        title: 'Maintenance Mode',
                        field: 'isMaintenance',
                        render: app =>
                            app.isMaintenance ? <CheckBox /> : <CheckBoxOutlineBlank />,
                        customSort: (a, b) => {
                            if (a.isMaintenance === b.isMaintenance) return 0;
                            return a.isMaintenance ? -1 : 1;
                        },
                    },
                ]}
                actions={[
                    {
                        onClick: (_, app: AppItem) => setEditedApp(app),
                        hidden: !pagePermissions?.Maintenance.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit App Maintenance',
                    },
                ]}
                title="Maintenance App List"
                data={data?.applicationsV2.results ?? []}
                options={{
                    search: true,
                    paginationPosition: 'bottom',
                    pageSize: 50,
                    pageSizeOptions: [25, 50, 100],
                    emptyRowsWhenPaging: false,
                }}
                isLoading={applicationsLoading}
                localization={{ header: { actions: '' } }}
            />
            {editedApp && (
                <MaintenanceAppEditModal
                    app={editedApp}
                    onSubmit={handleSubmitAppEditModal}
                    onClose={() => setEditedApp(null)}
                />
            )}
        </>
    );
};

export default MaintenanceAppList;
