import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    root: {
        ...theme.wildflowerBackgroundContainer,
    },
    loginCard: {
        padding: 0,
        width: 400,
        '& .title': {
            padding: 20,
            borderBottom: '1px solid lightgray',
        },
        '& .inputCont': {
            padding: 20,
            minHeight: 250,
        },
        '& .bottomControl': {
            borderTop: '1px solid lightgray',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: 20,
            '& > div': {
                display: 'flex',
                flexDirection: 'row',
                width: '70%',
                '&:last-child': {
                    justifyContent: 'flex-end',
                },
                '& > button': {
                    '&:not(:last-child)': {
                        marginRight: 10,
                    },
                },
            },
            '& .back': {
                width: '30%',
            },
        },
        ' & .credit': {
            position: 'absolute',
            bottom: 5,
            left: 5,
            fontSize: '75%',
            fontWeight: 'bold',
            color: 'lightGrey',
        },
    },
    helpLink: {
        textAlign: 'center',
        verticalAlign: 'top',
        paddingLeft: 20,
        paddingRight: 20,
    },
    underlined: {
        textDecoration: 'underline',
    },
}));
