import { useMemo } from 'react';
import { Organization } from '~/schemaTypes';
import { VC_PARENT_ORG_ID } from '~/views/ConfigDashboard/Organizations/OrganizationCard/OrganizationVCEligibility/constants';

type Org = Pick<Organization, 'parentId' | 'id' | 'ancestors'>;

const isVirtualCareOrg = (currentOrg?: Org): boolean => {
    if (!currentOrg) {
        return false;
    }
    const { parentId, ancestors } = currentOrg;
    if (parentId === VC_PARENT_ORG_ID || currentOrg.id === VC_PARENT_ORG_ID) {
        return true;
    }
    if (ancestors && ancestors.length) {
        return ancestors.includes(VC_PARENT_ORG_ID);
    }
    return false;
};

export default (currentOrg?: Org) => {
    const isVirtualCareView = useMemo(() => isVirtualCareOrg(currentOrg), [currentOrg]);
    return { isVirtualCareView };
};
