import { atomWithStorage } from 'jotai/utils';

export const PatientsHiddenColumnsAtom = atomWithStorage('patientHiddenColumns', {
    invitationCode: false,
    externalId: false,
    firstName: false,
    lastName: false,
    riskScore: false,
    createdAt: false,
    assignedHealthAdvocate: false,
    openAdvocateTaskCount: false,
    openCriticalAdvocateTaskCount: false,
    appRegistrationCompleted: false,
    patientCallStatus: false,
    birthDate: false,
    practice: false,
    onboardingCompleteDate: false,
    isPregnant: false,
    dueDate: false,
    deliveryDate: false,
    email: false,
    phoneNumber: false,
    id: false,
    affiliates: false,
});
