import { useMemo } from 'react';
import { VC_PARENT_ORG_ID } from './constants';

type VCEligibilityProps = {
    ancestors?: any[] | null;
};
const useVCEligibility = (props: VCEligibilityProps): { isVCOrg: boolean } => {
    const { ancestors } = props;
    const isVCOrg = useMemo<boolean>(() => !!ancestors?.includes(VC_PARENT_ORG_ID), [ancestors]);
    return { isVCOrg };
};

export default useVCEligibility;
