import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    CareTeamContactInput,
    CareTeamMember,
    useCareTeamContactsForCareTeamMemberEditModalQuery,
    useCareTeamMembersForCareTeammemberModalQuery,
    useCareTeamMemberTypesForCareTeamMemberEditModalQuery,
    usePreFetchAppsQuery,
    UsState,
} from '~/schemaTypes';
import { ELIGIBILITY_CHECK_VARIANTS } from '~/views/ConfigDashboard/CareTeamMembers/CareTeamMembersModal/CareTeamMembersModal';
import useStyles from './styles';

type PatientCareTeamMemberModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    usStateList: UsState[];
    careTeamMember: CareTeamMember;
};

const PatientCareTeamMemberModal: React.FC<PatientCareTeamMemberModalProps> = ({
    setOpen,
    usStateList,
    careTeamMember,
}) => {
    const { classes } = useStyles();
    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsQuery();
    const [overriddenContact, setOverriddenContact] = useState<CareTeamContactInput | undefined>(
        careTeamMember.overridenCareTeamMemberContact || undefined,
    );
    const { register, control } = useForm<CareTeamMember>({
        defaultValues: careTeamMember,
    });

    const { data: careTeamMembers, loading: careTeamMembersLoading } =
        useCareTeamMembersForCareTeammemberModalQuery();

    // Load CareTeamMemberTypes
    const { data: memberTypes, loading: memberTypesLoading } =
        useCareTeamMemberTypesForCareTeamMemberEditModalQuery();

    // Load CareTeamContacts
    const { data: contacts, loading: contactsLoading } =
        useCareTeamContactsForCareTeamMemberEditModalQuery();

    const handleDeleteOverride = () => {
        setOverriddenContact(undefined);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    if (memberTypesLoading) return <Loading subtitle="Loading Care Team Member Types..." />;
    if (contactsLoading) return <Loading subtitle="Loading Care Team Contacts..." />;
    if (careTeamMembersLoading) return <Loading subtitle="Loading Care Team Members..." />;
    if (preFetchLoading) return <Loading subtitle="Loading AMS Apps" />;

    return (
        <form noValidate>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                View Care Team Member
            </DialogTitleWithClose>
            <DialogContent className="formCont">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <OutlinedSection title="Label">
                            <TextField
                                variant="outlined"
                                label="English"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('label.en')}
                                disabled
                            />
                            <TextField
                                variant="outlined"
                                label="Spanish"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('label.es')}
                                disabled
                            />
                        </OutlinedSection>
                    </Grid>
                    <Grid item xs={12}>
                        <ReactHookFormSelect
                            control={control}
                            name="appBundleId"
                            variant="outlined"
                            defaultValue=""
                            label="App"
                            fullWidth
                            margin="dense"
                            disabled
                        >
                            {preFetchData?.amsApps.map(({ id, bundleId }) => (
                                <MenuItem key={id} value={bundleId}>
                                    {bundleId}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <ReactHookFormSelect
                            control={control}
                            name="careTeamMemberTypeId"
                            variant="outlined"
                            defaultValue=""
                            label="Care Team Member Type"
                            fullWidth
                            margin="dense"
                            disabled
                        >
                            {memberTypes?.careTeamMemberTypesV2.results.map(({ name, id }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    </Grid>
                    <Grid item xs={6}>
                        <OutlinedSection title="Care Team Contact">
                            <ReactHookFormSelect
                                control={control}
                                name="careTeamMemberContactId"
                                variant="outlined"
                                defaultValue=""
                                label="Care Team Contact"
                                fullWidth
                                margin="dense"
                                disabled
                            >
                                {contacts?.careTeamContactsV2.results.map(({ name, id }) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            <Button
                                color="secondary"
                                variant="contained"
                                disabled
                                className={classes.overrideContactButton}
                            >
                                Override contact (optional)
                            </Button>
                        </OutlinedSection>
                    </Grid>
                    {overriddenContact && (
                        <Grid item xs={6}>
                            <OutlinedSection title="Overriden Contact">
                                <Grid item xs={12} className={classes.overriddenContactWrapper}>
                                    <Typography>Name: {overriddenContact?.name}</Typography>
                                    <Typography>
                                        Type:{' '}
                                        {
                                            memberTypes?.careTeamMemberTypesV2.results.find(
                                                ({ id }) =>
                                                    id === overriddenContact.careTeamMemberTypeId,
                                            )?.name
                                        }
                                    </Typography>
                                    <Typography>Phone: {overriddenContact?.phoneNumber}</Typography>
                                    <Typography>
                                        Email: {overriddenContact?.emailAddress}
                                    </Typography>
                                    <Typography>Url: {overriddenContact?.url}</Typography>
                                    <Typography>
                                        Adress: {overriddenContact?.address.state},{' '}
                                        {overriddenContact?.address.city},{' '}
                                        {overriddenContact?.address.code},{' '}
                                        {overriddenContact?.address.street1}
                                    </Typography>
                                </Grid>
                                <Button
                                    startIcon={<Edit />}
                                    color="secondary"
                                    variant="contained"
                                    disabled
                                    className={classes.overrideContactButton}
                                >
                                    Edit Overridden Contact
                                </Button>
                                <Button
                                    startIcon={<Delete />}
                                    onClick={() => handleDeleteOverride()}
                                    color="primary"
                                    variant="contained"
                                    disabled
                                    className={classes.overrideContactButton}
                                >
                                    Delete Override
                                </Button>
                            </OutlinedSection>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <ReactHookFormSelect
                            control={control}
                            name="usState"
                            variant="outlined"
                            defaultValue=""
                            label="US State"
                            fullWidth
                            margin="dense"
                            disabled
                        >
                            {usStateList.map(i => (
                                <MenuItem key={i.abbreviation} value={i.abbreviation || ''}>
                                    {i.name}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        <Button
                            startIcon={<ClearIcon />}
                            color="primary"
                            variant="contained"
                            className={classes.indent}
                            disabled
                        >
                            Clear US State
                        </Button>
                        <TextField
                            variant="outlined"
                            label="Zip Code"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('zipCode')}
                            disabled
                        />
                        <AutocompleteWithRecordOptions
                            options={careTeamMembers?.careTeamMembersV2.results ?? []}
                            valueKey="id"
                            labelKey="nameWithType"
                            name="prerequisiteTeamMembers"
                            label="Prerequisite Team Members"
                            placeholder="Select Members..."
                            control={control}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedSection title="Eligibility Check">
                            <ReactHookFormSelect
                                control={control}
                                name="eligibilityCheck"
                                variant="outlined"
                                defaultValue=""
                                label="Eligibility Check"
                                fullWidth
                                margin="dense"
                                disabled
                            >
                                {Array.from(ELIGIBILITY_CHECK_VARIANTS.keys()).map(key => (
                                    <MenuItem key={key} value={ELIGIBILITY_CHECK_VARIANTS.get(key)}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            <Typography className={classes.indent}>Recheck (optional)</Typography>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Typography className={classes.indent}>Every</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        margin="dense"
                                        {...register('eligibilityRecheckDays')}
                                        className={classes.eligibilityRecheckDaysWidth}
                                        defaultValue={0}
                                        disabled
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.indent}>Days</Typography>
                                </Grid>
                            </Grid>
                        </OutlinedSection>
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedSection title="Consent">
                            <Controller
                                name="consentRequired"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <FormControlLabel
                                        label="Consent Required"
                                        control={
                                            <Checkbox
                                                checked={value ?? false}
                                                onChange={e => onChange(e.target.checked)}
                                                disabled
                                            />
                                        }
                                    />
                                )}
                            />
                            <TextField
                                variant="outlined"
                                label="Consent Agreement"
                                type="text"
                                margin="dense"
                                multiline
                                minRows={10}
                                fullWidth
                                {...register('consentAgreement')}
                                disabled
                            />
                        </OutlinedSection>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default PatientCareTeamMemberModal;
